import { connect } from "react-redux"
import PatternDetectionAlerts from './PatternDetectionAlerts'
import {
  clearSpecificQueryAlert,
  searchRiskAlertsRequest,
  getAlertGraphDataRequest,
  createCaseRequest,
} from '../../../redux/actions/admin'

const mapStateToProps = state => ({
  websocket: state.login.websocket,
  riskAlerts: state.admin.riskAlerts,
  alertGraphData: state.admin.alertGraphData,
  riskAlertsMaxPageSize: 10
})

const mapDispatchToProps = {
  clearSpecificQueryAlert,
  searchRiskAlertsRequest,
  getAlertGraphDataRequest,
  createCaseRequest,
}

export default connect(mapStateToProps, mapDispatchToProps)(PatternDetectionAlerts)
