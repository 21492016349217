import { connect } from "react-redux"
import HistoryAudit from './HistoryAudit'
import {
  clearHistoryQuery,
} from '../../../redux/actions/admin'

const mapStateToProps = state => ({
  websocket: state.login.websocket,
  history_query: state.admin.history_query
})

const mapDispatchToProps = {
  clearHistoryQuery
}

export default connect(mapStateToProps, mapDispatchToProps)(HistoryAudit)
