import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Table,
  Spinner,
  FormControl,
  Pagination,
  Tabs,
  Tab,
  Button,
} from "react-bootstrap";
import { AiOutlineRedo, AiOutlineEye } from "react-icons/ai";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ResponsiveLine } from "@nivo/line";
import OrderDetailsModal from "../../../components/modals/OrderDetailsModal";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const scrollHere = document.getElementsByClassName(
  "scroll-here-for-pagination"
);

class MarketDataReplay extends Component {
  constructor(props) {
    super(props);

    const yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
    const today = new Date();

    this.state = {
      marketSymbols: this.props.marketSymbols,
      marketDataReplay: this.props.marketDataReplay,
      allAlerts: this.props.allAlerts,
      params: {
        symbol: "",
        start: yesterday,
        end: today,
      },
      currentPage: 1,
      limit: 20,
      startingSymbol: "",
      showOrderDetailsModal: false,
      refnoToInvestigate: "",
      revealedOrderBookItems: [],
    };

    this.chartOptions = {};
  }

  componentDidMount() {
    this.props.clearMarketSymbols();
    this.handleParseUrl();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      this.setState({
        marketSymbols: nextProps.marketSymbols,
        marketDataReplay: nextProps.marketDataReplay,
        allAlerts: nextProps.allAlerts,
      });
    }

    if (nextProps.marketSymbols.loaded && !this.state.params.symbol) {
      this.setState({
        params: {
          ...this.state.params,
          symbol: nextProps.marketSymbols.data[0],
        },
        startingSymbol: nextProps.marketSymbols.data[0],
      });
    }
  }

  handleModifyTime = (time) => {
    let newTime = new Date(time);

    newTime.setHours(newTime.getHours() + 6);
    return newTime;
  };

  handleParseUrl = () => {
    // parses the url
    // if a search is found, set state and send the params with getMarketSymbolsRequest
    // otherwise, getMarketSymbolsRequest as normal
    const { search } = this.props.history.location;

    if (search) {
      // parse the url
      const splitA = search.split("?");
      const splitB = splitA[1].split("&");
      const splitSymbol = splitB[0].split("=");
      const splitStart = splitB[1].split("=");
      const splitType = splitB[2].split("=");

      const symbol = splitSymbol[1];
      const start = new Date(splitStart[1].replace(/%20/g, " "));
      const end = this.handleModifyTime(start);
      const type = splitType[1];

      this.setState(
        {
          params: {
            ...this.state.params,
            symbol: symbol == "No%20Symbol" ? "" : symbol,
            start: start,
            end: end,
            type: type,
          },
        },
        () => {
          this.props.getMarketSymbolsRequest(this.state.params);
          this.props.getAllAlertsRequest(this.state.params);
        }
      );
    } else {
      this.props.getMarketSymbolsRequest(this.state.params);
      this.props.getAllAlertsRequest(this.state.params);
    }
  };

  handleChangeParams = (e) => {
    this.setState(
      {
        currentPage: 1,
        params: {
          ...this.state.params,
          [e.target.name]: e.target.value,
        },
      },
      () => {
        this.props.getMarketDataReplayRequest(this.state.params);
        this.props.getAllAlertsRequest(this.state.params);
      }
    );
  };

  handleRefreshData = () => {
    const today = new Date();
    let yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    this.setState(
      {
        currentPage: 1,
        params: {
          ...this.state.params,
          // symbol: this.state.startingSymbol,
          // start: yesterday,
          end: today,
          // type: 'Best Bid/Offer'
        },
      },
      () => {
        this.props.getMarketDataReplayRequest(this.state.params);
        this.props.getAllAlertsRequest(this.state.params);
      }
    );
  };

  handleChangeDate = (date, name) => {
    this.setState(
      {
        currentPage: 1,
        params: {
          ...this.state.params,
          [name]: date,
        },
      },
      () => {
        this.props.getMarketDataReplayRequest(this.state.params);
        this.props.getAllAlertsRequest(this.state.params);
      }
    );
  };

  handleConvertTimeToDate = (time) => {
    const humanReadableDate = new Date(0);
    humanReadableDate.setUTCMilliseconds(time);

    return humanReadableDate.toLocaleString();
  };

  formatDate = (dateInput) => {
    let date = new Date(dateInput);
    const month = date.toLocaleString("en-us", { month: "short" });
    const day = date.getDate();
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();

    return `${month} ${day}, ${year}; ${hours}:${minutes}`;
  };

  formatDateWithTime = (dateInput) => {
    return dateInput.toLocaleString();
  };

  formatDateFromStartEnd = (dateInput) => {
    return dateInput.toLocaleDateString();
  };

  handleClickPage = (number) => {
    const scrollHere = document.getElementById("scroll-here");
    const topPos = scrollHere.offsetTop;
    const test = document.getElementsByClassName("admin-content-pane");
    test[0].scrollTop = topPos;
    this.setState({
      currentPage: number,
    });
  };

  toggleModal = (fieldName, refno) => {
    this.setState({
      [`show${fieldName}Modal`]: !this.state[`show${fieldName}Modal`],
      refnoToInvestigate: refno,
    });
  };

  handleToggleOrderBookItemRefno = (itemCost) => {
    const { revealedOrderBookItems } = this.state;

    if (revealedOrderBookItems.includes(itemCost)) {
      for (let i = 0; i < revealedOrderBookItems.length; i++) {
        if (revealedOrderBookItems[i] == itemCost) {
          revealedOrderBookItems.splice(i, 1);
        }
      }
    } else {
      revealedOrderBookItems.push(itemCost);
    }

    this.setState({
      revealedOrderBookItems: revealedOrderBookItems,
    });
  };

  //************************************************************************
  //************************************************************************
  //************************************************************************

  renderSearchBar = () => {
    const { data, loaded, loading } = this.state.marketSymbols;
    const { symbol, start, end, type } = this.state.params;
    let marketSymbols;
    if (loaded) {
      marketSymbols = data.map((symbol) => this.renderMarketSymbol(symbol));
    } else if (loading) {
      marketSymbols = <option value="">Loading Symbols...</option>;
    }
    return (
      <Row noGutters>
        {this.renderRefreshDataButton()}
        <div className="search-bar">
          <label className="search-label">Symbol:</label>
          <FormControl
            name="symbol"
            value={symbol}
            onChange={this.handleChangeParams}
            className="search-form-control"
            as="select"
          >
            {marketSymbols}
          </FormControl>
        </div>

        <div className="search-bar">
          <label className="search-label">Start:</label>
          <DatePicker
            dateFormat="MM/dd/yyyy h:mm aa"
            selected={new Date(start)}
            onChange={(date) => this.handleChangeDate(date, "start")}
            className="admin-datepicker"
            showTimeInput
          />
        </div>

        <div className="search-bar">
          <label className="search-label">End:</label>
          <DatePicker
            dateFormat="MM/dd/yyyy h:mm aa"
            selected={new Date(end)}
            onChange={(date) => this.handleChangeDate(date, "end")}
            className="admin-datepicker"
            showTimeInput
          />
        </div>
      </Row>
    );
  };

  renderRefreshDataButton = () => {
    return (
      <AiOutlineRedo
        onClick={this.handleRefreshData}
        className="individual-form-icon refresh-button"
      />
    );
  };

  renderMarketSymbol = (symbol) => (
    <option key={`marketSymbol: ${symbol}`}>{symbol}</option>
  );

  removeDuplicateKeys = (array) => {
    if (array && array.length > 0) {
      const spliceValue = Object.keys(array[array.length - 1]).length;
      const filteredArray = Object.values(
        array.reduce((unique, o) => {
          if (!unique[o.key] || +o.time > +unique[o.key].time) {
            unique[o.key] = o;
          }
          return unique;
        })
      );

      return filteredArray.splice(spliceValue, filteredArray.length - 1);
    } else {
      return [];
    }
  };

  mergeAndSortData = (arr1, arr2, type) => {
    // merge the two arrays together via concat; if only one array, keep it
    const mergedArray = arr2 ? arr1.concat(arr2) : arr1;
    // filter mergedArray based on if items are objects and have a refno ((this will remove odd or corrupted items))
    const filteredArray = mergedArray.filter(
      (item) => typeof item === "object" && (item.refno || item.key)
    );

    // sort the array based on time ((trdtime if type == sale, time if anything else))
    filteredArray.sort((a, b) => {
      const timeA = a.type == "sale" ? a.trdtime : a.time;
      const timeB = b.type == "sale" ? b.trdtime : b.time;

      // sorted from oldest to newest
      return timeA - timeB;
    });

    return filteredArray;
  };

  indexOnPrice = (ordersDataset) => {
    let results = {};
    for (let order of ordersDataset) {
      if (results[order.price]) {
        if (order.side == "S") {
          results[order.price].asks.push(order);
          results[order.price].askVolume += parseInt(order.qty);
        }
        if (order.side == "B") {
          results[order.price].bids.push(order);
          results[order.price].bidVolume += parseInt(order.qty);
        }
      } else {
        if (order.side == "S") {
          results[order.price] = {
            bids: [],
            bidVolume: 0,
            asks: [order],
            askVolume: order.qty,
          };
        }
        if (order.side == "B") {
          results[order.price] = {
            bids: [order],
            bidVolume: order.qty,
            asks: [],
            askVolume: 0,
          };
        }
      }
    }

    return results;
  };

  renderTable = () => {
    const { marketDataReplay, allAlerts } = this.state;

    let marketDataBody, alertsBody;
    if (marketDataReplay.loaded) {
      const filteredData = marketDataReplay.data["Book"]
        ? this.removeDuplicateKeys(marketDataReplay.data["Book"].data)
        : [];
      if (filteredData.length > 0) {
        const mergedData = this.mergeAndSortData(
          filteredData,
          marketDataReplay.data["trades"]?.data
        );

        marketDataBody = mergedData.map((item) => this.renderTableItem(item));
      } else {
        marketDataBody = (
          <tr>
            <td colSpan="7" className="centered-td">
              No data found for this symbol in this time range
            </td>
          </tr>
        );
      }
    } else if (marketDataReplay.loading) {
      marketDataBody = (
        <tr>
          <td colSpan="7" className="centered-td">
            {this.renderLoading()}
          </td>
        </tr>
      );
    }

    if (allAlerts.loaded) {
      if (allAlerts.data.length > 0) {
        alertsBody = allAlerts.data.map((item) =>
          this.renderAlertsTableItem(item)
        );
      } else {
        alertsBody = (
          <tr>
            <td colSpan="4" className="centered-td">
              No alerts found in this time range
            </td>
          </tr>
        );
      }
    } else if (allAlerts.loading) {
      alertsBody = (
        <tr>
          <td colSpan="4" className="centered-td">
            {this.renderLoading()}
          </td>
        </tr>
      );
    }

    return (
      <Tabs defaultActiveKey="market-data" className="admin-tabs">
        <Tab
          eventKey="market-data"
          title="Market Data"
          className="admin-tab scrollable-tab"
        >
          <Table responsive bordered className="submissions-table no-margin">
            <thead>
              <tr>
                <th className="cm-table-header">Time</th>
                <th className="cm-table-header">Symbol</th>
                <th className="cm-table-header">Type</th>
                <th className="cm-table-header">Side</th>
                <th className="cm-table-header status-td">Price</th>
                <th className="cm-table-header status-td">Qty</th>
                <th className="cm-table-header">Refno</th>
              </tr>
            </thead>

            <tbody className="scrollable-body">{marketDataBody}</tbody>
          </Table>
        </Tab>

        <Tab
          eventKey="alerts"
          title="Alerts"
          className="admin-tab scrollable-tab"
        >
          <Table responsive bordered className="submissions-table no-margin">
            <thead>
              <tr>
                <th className="cm-table-header">Time</th>
                <th className="cm-table-header">Account</th>
                <th className="cm-table-header">Record No.</th>
                <th className="cm-table-header">Error Details</th>
              </tr>
            </thead>

            <tbody className="scrollable-body">{alertsBody}</tbody>
          </Table>
        </Tab>
      </Tabs>
    );
  };

  renderAlertsTableItem = (item) => {
    return (
      <tr key={`alertsTableItem: ${item.eventid}, ${Math.random()}`}>
        <td>{this.handleConvertTimeToDate(item.time)}</td>
        <td>{item.account}</td>
        <td>{item.rec_no}</td>
        <td>{item.errordetails}</td>
      </tr>
    );
  };

  renderTableItem = (item) => {
    if (item.act == "R") return;
    let time, symbol, type, side, price, qty, refno, colorId;
    if (item.type == "sale") {
      time = item.trdtime && this.handleConvertTimeToDate(item.trdtime);
      symbol = item.security;
      type = "Trade";
      side = item.side == "S" ? "Ask" : "Bid";
      price = item.execprice;
      qty = item.execqty;
      refno = item.refno;
    } else {
      time = item.time && this.handleConvertTimeToDate(item.time);
      symbol = this.state.params.symbol;
      type = "Order";
      side = item.side == "S" ? "Ask" : "Bid";
      price = item.price;
      qty = item.qty;
      refno = item.key;
    }

    if (type == "Trade") {
      colorId = "blue";
    } else if (type == "Order" && side == "Bid") {
      colorId = "green";
    } else if (type == "Order" && side == "Ask") {
      colorId = "red";
    }

    return (
      <tr key={`tableItemId: ${item.id}, ${Math.random()}`}>
        <td>{time}</td>
        <td>{symbol}</td>
        <td className="color-td" id={colorId}>
          {type}
        </td>
        <td className="color-td" id={colorId}>
          {side}
        </td>
        <td className="status-td">{price}</td>
        <td className="status-td">{qty}</td>
        <td>
          <div
            onClick={() =>
              this.toggleModal("OrderDetails", { refno: refno, symbol: symbol })
            }
            className="clickable-td"
          >
            {refno}
          </div>
        </td>
      </tr>
    );
  };

  renderLoading = () => (
    <Row noGutters className="app-spinner full-height">
      <Spinner animation="border" className="common-grey-spinner" />
    </Row>
  );

  renderPagination = () => {
    const { marketDataReplay, currentPage, limit } = this.state;

    if (marketDataReplay.loaded) {
      const { total } = marketDataReplay.data;
      const numberOfPages = Math.ceil(total / limit);
      let pages = [];

      for (let number = 1; number <= numberOfPages; number++) {
        pages.push(
          <Pagination.Item
            onClick={() => this.handleClickPage(number)}
            key={`paginationPageNum: ${number}`}
            active={number === currentPage}
          >
            {number}
          </Pagination.Item>
        );
      }

      let prevEllipsis;
      let nextEllipsis;
      if (numberOfPages > 10 && currentPage >= 10) {
        if (currentPage <= numberOfPages - 10) {
          nextEllipsis = (
            <Pagination.Ellipsis
              onClick={() => this.handleClickPage(currentPage + 10)}
            />
          );
        }
        prevEllipsis = (
          <Pagination.Ellipsis
            onClick={() => this.handleClickPage(currentPage - 10)}
          />
        );
        pages = pages.slice(currentPage - 5, currentPage + 5);
      } else if (currentPage <= numberOfPages - 10) {
        nextEllipsis = (
          <Pagination.Ellipsis
            onClick={() => this.handleClickPage(currentPage + 10)}
          />
        );
        pages = pages.slice(currentPage - 1, currentPage + 10);
      }

      const first =
        Math.abs(currentPage - 1) > 3 ? (
          <Pagination.First onClick={() => this.handleClickPage(1)} />
        ) : (
          ""
        );
      const last =
        Math.abs(currentPage - numberOfPages) > 3 ? (
          <Pagination.Last
            onClick={() => this.handleClickPage(numberOfPages)}
          />
        ) : (
          ""
        );
      const next =
        currentPage !== numberOfPages ? (
          <Pagination.Next
            onClick={() => this.handleClickPage(currentPage + 1)}
          />
        ) : (
          ""
        );
      const prev =
        currentPage !== 1 ? (
          <Pagination.Prev
            onClick={() => this.handleClickPage(currentPage - 1)}
          />
        ) : (
          ""
        );

      return (
        <Row noGutters className="pagination-row">
          <Pagination size="sm" className="common-pagination">
            {first}
            {prev}
            {prevEllipsis}
            {pages}
            {nextEllipsis}
            {next}
            {last}
          </Pagination>
        </Row>
      );
    }
  };

  renderLineGraph = () => {
    const { data, loading, loaded } = this.state.marketDataReplay;
    let content = null;
    if (loaded) {
      function transformBestBid(bestBidData, lastSaleData) {
        if (bestBidData.length <= 0 && lastSaleData.length <= 0)
          return { min: 0, max: 0, data: [] };
        let dateMap = {};
        for (let datapoint of bestBidData) {
          let humanReadableTime = new Date();
          humanReadableTime.setTime(datapoint.time);
          humanReadableTime = humanReadableTime.toISOString();
          dateMap[humanReadableTime] = {
            bid: datapoint.bid,
            ask: datapoint.ask,
          };
        }
        for (let datapoint of lastSaleData) {
          let humanReadableTime = new Date();
          humanReadableTime.setTime(datapoint.time);
          humanReadableTime = humanReadableTime.toISOString();
          if (dateMap[humanReadableTime]) {
            dateMap[humanReadableTime].sale = datapoint.execprice;
          } else dateMap[humanReadableTime] = { sale: datapoint.execprice };
        }
        const sortedKeys = Object.keys(dateMap).sort((a, b) => {
          //return (a.date < b.date) ? -1 : ((a.date > b.date) ? 1 : 0);
          return a.localeCompare(b);
        });
        const bid = {
          data: [],
          name: "Best Bid",
          type: "line",
          color: "hsl(124, 100%, 37%)", //green
          connectNulls: true,
        };
        const offer = {
          data: [],
          name: "Best Offer",
          type: "line",
          color: "hsl(4, 100%, 50%)", //red
          connectNulls: true,
        };
        const sale = {
          data: [],
          name: "Trade",
          type: "line",
          color: "hsl(245, 100%, 50%)", //blue
          connectNulls: true,
        };
        let max = dateMap[sortedKeys[0]].ask ? dateMap[sortedKeys[0]].ask : 0;
        let min = dateMap[sortedKeys[0]].bid
          ? dateMap[sortedKeys[0]].bid
          : 1000000;
        for (let date of sortedKeys) {
          const time = new Date(date).getTime();
          bid.data.push([
            time,
            parseInt(dateMap[date].bid) > 0
              ? parseFloat(dateMap[date].bid)
              : null,
          ]);
          offer.data.push([
            time,
            parseInt(dateMap[date].ask) > 0
              ? parseFloat(dateMap[date].ask)
              : null,
          ]);
          sale.data.push([
            time,
            parseInt(dateMap[date].sale) > 0
              ? parseFloat(dateMap[date].sale)
              : null,
          ]);

          if (
            parseInt(dateMap[date].ask) > 0 &&
            parseInt(dateMap[date].ask) > max
          )
            max = parseInt(dateMap[date].ask);
          if (
            parseInt(dateMap[date].bid) > 0 &&
            parseInt(dateMap[date].bid) > max
          )
            max = parseInt(dateMap[date].bid);
          if (
            parseInt(dateMap[date].ask) > 0 &&
            parseInt(dateMap[date].ask) < min
          )
            min = parseInt(dateMap[date].ask);
          if (
            parseInt(dateMap[date].bid) > 0 &&
            parseInt(dateMap[date].bid) < min
          )
            min = parseInt(dateMap[date].bid);
        }
        return {
          max: max + 5,
          min: min - 5,
          data: [bid, offer, sale],
        };
      }

      if (Object.keys(data).length > 0) {
        let transformedData = {
          min: 0,
          max: 5,
          data: [],
        };
        let bestBidOfferData = [];
        let lastSaleData = [];

        if (data["Best Bid/Offer"] && data["Best Bid/Offer"].data.length > 0) {
          bestBidOfferData = data["Best Bid/Offer"].data;
        }

        if (data["Last Sale"] && data["Last Sale"].data.length > 0) {
          lastSaleData = data["Last Sale"].data;
        }

        transformedData = transformBestBid(bestBidOfferData, lastSaleData);
        const options = {
          tooltip: {
            pointFormat:
              '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
            // formatter: function () {
            //     console.log(this);
            //     return '<span style="color:' + this.series.color + '"> ' + this.series.name + '</span>: <b>' + Highcharts.dateFormat('%d %b %y', this.y) + '</b><br/>'
            // },
            valueDecimals: 2,
            split: true,
          },
          chart: {
            type: "line",
          },
          title: {
            text: "",
          },
          xAxis: {
            type: "datetime",
            labels: {
              formatter: function () {
                return Highcharts.dateFormat("%d %b %y", this.value);
              },
            },
          },
          series: transformedData.data,
          reflow: true,
        };

        const containerProps = {
          style: {
            width: "100%",
          },
        };

        content = (
          <div className="cases-graph-box">
            <HighchartsReact highcharts={Highcharts} options={options} />
          </div>
        );
      }
    } else if (loading) {
      content = <div className="forms-table-box">{this.renderLoading()}</div>;
    }
    return content;
  };

  renderTooltip = (e, paramsType) => {
    const { points } = e.slice;
    const pointsData = points.map((point, index) =>
      this.renderTooltipPoint(point, index)
    );

    return <div className="admin-linegraph-tooltip">{pointsData}</div>;
  };

  renderTooltipPoint = (point, index) => {
    const { id, x, serieId, serieColor, data } = point;

    let date;
    if (index == 0) {
      date = (
        <Row noGutters className="admin-tooltip-date-row">
          Date: <b className="admin-tooltip-date">{this.formatDate(data.x)}</b>
        </Row>
      );
    }
    return (
      <React.Fragment key={`tooltipPoint: ${id}`}>
        {date}
        <Row noGutters className="tooltip-row">
          <div className="tooltip-front-loader">
            <div
              className="tooltip-color-square"
              style={{ backgroundColor: serieColor }}
            />
            {serieId}:
          </div>
          <b>{point.data.yFormatted}</b>
        </Row>
      </React.Fragment>
    );
  };

  renderOverviewBox = () => {
    const { data, loaded, loading } = this.state.marketDataReplay;

    let body;
    if (loaded) {
      const filteredData = data["Book"]
        ? this.removeDuplicateKeys(data["Book"].data)
        : [];
      if (filteredData?.length > 0) {
        function countOfAllOrdersWithSide(data, side) {
          let count = 0;
          for (let order of data) {
            if (order.side == side) count += 1;
          }

          return count;
        }

        function sumOfAllQtyWithSide(data, side) {
          let sum = 0;
          for (let order of data) {
            if (order.side == side) sum += Number(order.qty);
          }

          return sum;
        }

        const tradesData = data["trades"]?.data;

        function sumOfExecQty(data) {
          let sum = 0;
          for (let item of data) {
            sum += parseInt(item.execqty);
          }

          return sum;
        }

        body = (
          <>
            <Row noGutters className="section-row">
              <div className="overview-box-container">
                <Row noGutters className="overview-box-row">
                  <div>Orders:</div>
                  <div>{filteredData?.length}</div>
                </Row>
                <Row noGutters className="overview-box-row">
                  <div>Bids:</div>
                  <div>{countOfAllOrdersWithSide(filteredData, "B")}</div>
                </Row>
                <Row noGutters className="overview-box-row">
                  <div>Bid Volume:</div>
                  <div>{sumOfAllQtyWithSide(filteredData, "B").toFixed(2)}</div>
                </Row>
                <Row noGutters className="overview-box-row">
                  <div>Asks:</div>
                  <div>{countOfAllOrdersWithSide(filteredData, "S")}</div>
                </Row>
                <Row noGutters className="overview-box-row">
                  <div>Ask Volume:</div>
                  <div>{sumOfAllQtyWithSide(filteredData, "S").toFixed(2)}</div>
                </Row>
              </div>
            </Row>
            <Row noGutters className="section-row">
              <div className="overview-box-container">
                <Row noGutters className="overview-box-row">
                  <div>Trades:</div>
                  <div>{tradesData?.length / 2}</div>
                </Row>
                <Row noGutters className="overview-box-row">
                  <div>Volume:</div>
                  <div>
                    {tradesData?.length > 0 ? sumOfExecQty(tradesData) : 0}
                  </div>
                </Row>
              </div>
            </Row>
            <Row noGutters className="section-row">
              Alerts:
            </Row>
          </>
        );
      }
    } else if (loading) {
      body = <div className="overview-loading-box">{this.renderLoading()}</div>;
    }

    return (
      <div className="overview-box">
        <Row noGutters className="section-row">
          <b>Overview</b>
          <div>{`(${this.formatDateFromStartEnd(
            this.state.params.start
          )} - ${this.formatDateFromStartEnd(this.state.params.end)})`}</div>
        </Row>
        {body}
      </div>
    );
  };

  renderOrderBook = () => {
    const { data, loading, loaded } = this.state.marketDataReplay;

    let body;
    if (loaded) {
      const filteredData = data["Book"]
        ? this.removeDuplicateKeys(data["Book"].data)
        : [];
      if (filteredData.length > 0) {
        const indexedData = this.indexOnPrice(filteredData);
        const indexedDataArray = Object.keys(indexedData).map((key) => [
          key,
          indexedData[key],
        ]);
        const sortedArray = indexedDataArray.sort((a, b) =>
          a[0] < b[0] ? 1 : -1
        );
        body = sortedArray.map((item) => this.renderOrderBookItem(item));
      }
    } else if (loading) {
      body = (
        <tr>
          <td colSpan="3">
            <div className="overview-loading-box">{this.renderLoading()}</div>
          </td>
        </tr>
      );
    }

    return (
      <Tabs defaultActiveKey="order-book" className="admin-tabs">
        <Tab
          eventKey="order-book"
          title="Bids/Asks"
          className="admin-tab scrollable-tab"
        >
          <div className="order-box-container">
            <Table size="sm" responsive bordered className="order-book-table">
              <thead>
                <tr>
                  <th>Buy Qty</th>
                  <th>Price</th>
                  <th>Sell Qty</th>
                </tr>
              </thead>
              <tbody className="scrollable-body">{body}</tbody>
            </Table>
          </div>
        </Tab>
      </Tabs>
    );
  };

  renderRefno = (refno) => {
    return (
      <div
        onClick={() =>
          this.toggleModal("OrderDetails", {
            refno: refno,
            symbol: this.state.params.symbol,
          })
        }
        key={`orderBookRefno: ${refno}`}
        className="refno"
      >
        {refno}
      </div>
    );
  };

  renderOrderBookItem = (item) => {
    const cost = item[0];
    const data = item[1];

    let content;
    if (this.state.revealedOrderBookItems.includes(cost)) {
      content = (
        <>
          <td className="buy-volume-td color-td" id="green">
            {data.bids?.length > 0 &&
              data.bids.map((item) => this.renderRefno(item.key))}
          </td>
          <td className="cost-td">
            <AiOutlineEye
              onClick={() => this.handleToggleOrderBookItemRefno(cost)}
              className="reveal-button"
            />
            {cost}
          </td>
          <td className="ask-volume-td color-td" id="red">
            {data.asks?.length > 0 &&
              data.asks.map((item) => this.renderRefno(item.key))}
          </td>
        </>
      );
    } else {
      content = (
        <>
          <td className="buy-volume-td color-td" id="green">
            {data.bidVolume || ""}
          </td>
          <td className="cost-td">
            <AiOutlineEye
              onClick={() => this.handleToggleOrderBookItemRefno(cost)}
              className="reveal-button"
            />
            {cost}
          </td>
          <td className="ask-volume-td color-td" id="red">
            {data.askVolume || ""}
          </td>
        </>
      );
    }

    return <tr key={`orderBookItemCost: ${cost}`}>{content}</tr>;
  };

  renderOrderDetailsModal = () => {
    if (this.state.showOrderDetailsModal) {
      return (
        <OrderDetailsModal
          refno={this.state.refnoToInvestigate}
          onClose={() => this.toggleModal("OrderDetails")}
        />
      );
    }
  };

  render() {
    return (
      <Container fluid className="admin-content-container" id="scroll-here">
        {this.renderOrderDetailsModal()}
        <Row noGutters className="forms-header">
          <h4>Market Data Replay</h4>
        </Row>

        <div className="forms-table-box">
          {this.renderSearchBar()}

          <Row noGutters>
            <Col>
              <Row noGutters className="mdr-content-row">
                <div className="mdr-chart-container">
                  {this.renderOverviewBox()}
                  {this.renderLineGraph()}
                </div>
              </Row>
              <Row noGutters className="mdr-content-row">
                <Col sm={8}>
                  <Row noGutters>
                    <Col>{this.renderTable()}</Col>
                  </Row>
                </Col>
                <Col>{this.renderOrderBook()}</Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Container>
    );
  }
}

export default MarketDataReplay;
