import React, { PureComponent } from "react";
import {
	Nav,
	Row,
	Col,
	Button,
	FormControl,
	Container,
	Spinner,
	Image,
	ListGroup,
	NavDropdown,
	Accordion,
	Dropdown,
} from "react-bootstrap";
import { NavLink } from "react-router-dom";
import {
	AiOutlineDashboard,
	AiOutlineAudit,
	AiOutlineSolution,
	AiOutlineUsergroupAdd,
	AiOutlineCheckSquare,
	AiOutlineSearch,
	AiOutlineUser,
	AiOutlineLogout,
	AiOutlineAlert,
	AiOutlineFileDone,
	AiOutlineEye,
} from "react-icons/ai";
import { BsBriefcase } from "react-icons/bs";
import {
	FiSearch,
	FiChevronDown,
	FiChevronRight,
	FiChevronLeft,
} from "react-icons/fi";
import * as COMPONENT from "./";
import { Switch, Route } from "react-router-dom";
import fiLogo from "../../images/FI_LOGO.png";

const scrollHere = document.getElementsByClassName(
	"scroll-here-for-pagination"
);

class Admin extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			collapseSidebar: false,
			currentSection: "pre-trade-risk",
			account: {
				data: {},
				loading: false,
				loaded: false,
				error: "",
			},
			navbarLinks: {
				data: {},
				loading: false,
				loaded: false,
				error: "",
			},
		};
	}

	componentDidMount() {
		this.handleGetSectionFromUrl();
		this.props.getNavbarLinksRequest();
	}

	componentWillReceiveProps(nextProps) {
		if (this.props !== nextProps) {
			this.setState({
				account: nextProps.account,
				navbarLinks: nextProps.navbarLinks,
			});
		}
	}

	handleGetSectionFromUrl = () => {
		const { pathname } = this.props.history.location;

		const section = pathname.split("/admin/");

		if (section[1]) {
			// if a section is in the url, select that section
			this.handleSelectSection(section[1]);
		} else {
			// otherwise, set the current section to risk-settings
			this.setState({ currentSection: "pre-trade-risk" });
		}
	};

	handleSelectSection = (section) => {
		this.setState({ currentSection: section });
	};

	handleToggleCollapseSidebar = () => {
		this.setState({
			collapseSidebar: !this.state.collapseSidebar,
		});
	};

	/***************************************************************************/

	renderSidebar = () => {
		const { collapseSidebar, currentSection } = this.state;

		let sidebarToggle;
		if (collapseSidebar) {
			sidebarToggle = "collapsed-sidebar";
		}

		return (
			<div className="admin-sidebar" id={sidebarToggle}>
				<Col>
					<Row noGutters className="admin-logo-row">
						<NavLink to="/admin">
							<Image className="admin-logo" src={fiLogo} />
						</NavLink>
					</Row>

					<Row noGutters className="admin-system-row">
						<div className="system-header">SYSTEM</div>
					</Row>

					<ListGroup variant="flush" className="admin-list-group">
						<ListGroup.Item
							onClick={this.handleGetSectionFromUrl}
							className="admin-list-item"
							active={currentSection === "pre-trade-risk"}
						>
							{this.renderPreTradeRiskListItem()}
						</ListGroup.Item>

						<ListGroup.Item
							onClick={this.handleGetSectionFromUrl}
							className="admin-list-item"
							active={currentSection === "market-surveillance"}
						>
							{this.renderMarketSurveillanceListItem()}
						</ListGroup.Item>

						<ListGroup.Item
							onClick={this.handleGetSectionFromUrl}
							className="admin-list-item"
							active={currentSection === "case-management"}
						>
							{this.renderManagementListItem()}
						</ListGroup.Item>
					</ListGroup>

					{this.renderSidebarToggleButton()}
				</Col>
			</div>
		);
	};

	renderSidebarToggleButton = () => {
		const { collapseSidebar } = this.state;

		let icon;
		if (collapseSidebar) {
			icon = (
				<FiChevronRight className="admin-sidebar-collapse-toggle-button" />
			);
		} else {
			icon = (
				<FiChevronLeft className="admin-sidebar-collapse-toggle-button" />
			);
		}
		return (
			<Row noGutters className="admin-sidebar-collapse-toggle-row">
				<div
					onClick={this.handleToggleCollapseSidebar}
					className="admin-sidebar-collapse-toggle-aura"
				>
					{icon}
				</div>
			</Row>
		);
	};

	renderPreTradeRiskListItem = () => {
		const { currentSection } = this.state;

		return (
			<Dropdown className="admin-list-accordion">
				<Dropdown.Toggle>
					<Row noGutters>
						<AiOutlineDashboard className="admin-list-item-icon" />
						Pre-Trade Risk
					</Row>
				</Dropdown.Toggle>

				<Dropdown.Menu className="admin-list-accordion-collapse">
					<ul>
						<NavLink to="/admin" className="admin-list-item-title">
							<li>Risk Control Settings</li>
						</NavLink>

						<NavLink
							to="/admin/current-risk"
							className="admin-list-item-title"
						>
							<li>Risk Monitor</li>
						</NavLink>

						<NavLink
							to="/admin/risk-alerts"
							className="admin-list-item-title"
						>
							<li>Pre-Trade Risk Alerts</li>
						</NavLink>
					</ul>
				</Dropdown.Menu>
			</Dropdown>
		);
	};

	renderMarketSurveillanceListItem = () => {
		const { currentSection } = this.state;

		const defaultActiveKey =
			currentSection == "market-surveillance" ? "0" : "blank";
		return (
			<Dropdown className="admin-list-accordion">
				<Dropdown.Toggle>
					<Row noGutters className="admin-list-accordion-title">
						<AiOutlineEye className="admin-list-item-icon" />
						Surveillance
					</Row>
				</Dropdown.Toggle>

				<Dropdown.Menu className="admin-list-accordion-collapse">
					<ul>
						<NavLink
							to={this.props.history.location.pathname}
							className="admin-list-item-title"
						>
							<li>Overview</li>
						</NavLink>

						<NavLink
							to="/admin/market-data-replay"
							className="admin-list-item-title"
						>
							<li>Market Data Replay</li>
						</NavLink>

						<NavLink
							to="/admin/pattern-detection-alerts"
							className="admin-list-item-title"
						>
							<li>Pattern Detection Alerts</li>
						</NavLink>
					</ul>
				</Dropdown.Menu>
			</Dropdown>
		);
	};

	renderManagementListItem = () => {
		const { currentSection } = this.state;

		const defaultActiveKey =
			currentSection == "case-management" ? "0" : "blank";
		return (
			<Dropdown className="admin-list-accordion">
				<Dropdown.Toggle>
					<Row noGutters className="admin-list-accordion-title">
						<BsBriefcase className="admin-list-item-icon" />
						Management
					</Row>
				</Dropdown.Toggle>

				<Dropdown.Menu className="admin-list-accordion-collapse">
					<ul>
						<NavLink
							to={"/admin/case-management"}
							className="admin-list-item-title"
						>
							<li>Cases</li>
						</NavLink>

						<NavLink
							to="/admin/order-trade-query"
							className="admin-list-item-title"
						>
							<li>Order/Trade Query</li>
						</NavLink>
					</ul>
				</Dropdown.Menu>
			</Dropdown>
		);
	};

	renderLogoutButton = () => {
		return (
			<div className="admin-sidebar-logout">
				<div className="click-to-logout" onClick={this.props.logout}>
					<AiOutlineLogout />
					Logout
				</div>
			</div>
		);
	};

	renderNavbarLink = (link) => {
		return (
			<NavDropdown.Item
				href={link.url}
				className="admin-topbar-navdropitem"
			>
				{link.name}
			</NavDropdown.Item>
		);
	};

	renderTopbar = () => {
		const { account, navbarLinks } = this.state;

		let displayName;
		if (account.loaded) {
			if (account.data.first_name && account.data.last_name) {
				displayName = `${account.data.first_name} ${account.data.last_name}`;
			} else {
				displayName = "My Account";
			}
		}

		let displayNavbarLinks;
		if (navbarLinks.loaded) {
			displayNavbarLinks = (
				<NavDropdown
					className="admin-topbar-navdropdown"
					title="Risk Management"
				>
					{navbarLinks.data.map((link) =>
						this.renderNavbarLink(link)
					)}
				</NavDropdown>
			);
		} else {
			displayNavbarLinks = (
				<NavDropdown
					className="admin-topbar-navdropdown"
					title="Risk Management"
				>
					<Row noGutters className="common-spinner-row">
						<Spinner
							className="common-grey-spinner"
							animation="border"
						/>
					</Row>
				</NavDropdown>
			);
		}

		const title = (
			<React.Fragment>
				{displayName}
				<AiOutlineUser />
			</React.Fragment>
		);

		return (
			<div className="admin-topbar">
				<Row noGutters className="admin-topbar-row">
					<div className="admin-search-container">
						{displayNavbarLinks}
					</div>

					<NavDropdown className="admin-topbar-nav" title={title}>
						<NavDropdown.Item
							className="admin-topbar-login"
							onClick={this.handleLogout}
						>
							<AiOutlineLogout />
							Log out
						</NavDropdown.Item>
					</NavDropdown>
				</Row>
			</div>
		);
	};

	handleLogout = () => {
		this.props.logout();
	};

	render() {
		const { collapseSidebar, currentSection } = this.state;

		let sidebarToggle;
		if (collapseSidebar) {
			sidebarToggle = "collapsed-sidebar";
		}

		return (
			<Container fluid className="admin-container">
				<Row noGutters style={{ flexWrap: "nowrap" }}>
					{this.renderSidebar()}

					<Col className="admin-col">
						{this.renderTopbar()}

						<div className="admin-content-pane" id={sidebarToggle}>
							<Route
								exact
								path="/admin"
								component={COMPONENT.RiskSettings}
							/>
							<Route
								exact
								path="/admin/current-risk"
								component={COMPONENT.CurrentRisk}
							/>
							<Route
								exact
								path="/admin/history-audit"
								component={COMPONENT.HistoryAudit}
							/>
							<Route
								exact
								path="/admin/risk-alerts"
								component={COMPONENT.RiskAlerts}
							/>
							<Route
								exact
								path="/admin/case-management"
								component={COMPONENT.CaseManagement}
							/>
							<Route
								exact
								path="/admin/pattern-detection-alerts"
								component={COMPONENT.PatternDetectionAlerts}
							/>
							<Route
								exact
								path="/admin/market-data-replay"
								component={COMPONENT.MarketDataReplay}
							/>
							<Route
								exact
								path="/admin/order-trade-query"
								component={COMPONENT.OrderTradeQuery}
							/>
						</div>
					</Col>
				</Row>
			</Container>
		);
	}
}

export default Admin;
