export const INJECT_TRADER = 'INJECT_TRADER'

export const INJECT_CURRENT_RISK = 'INJECT_CURRENT_RISK'

export const INJECT_HISTORY_QUERY = 'INJECT_HISTORY_QUERY'
export const CLEAR_HISTORY_QUERY = 'CLEAR_HISTORY_QUERY'

export const SET_DISPLAY_OPTIONS_RISK_SETTINGS = 'SET_DISPLAY_OPTIONS_RISK_SETTINGS'
export const SET_DISPLAY_OPTIONS_CURRENT_RISK = 'SET_DISPLAY_OPTIONS_CURRENT_RISK'

export const INJECT_QUERY_ALERTS = 'INJECT_QUERY_ALERTS'
export const INJECT_SPECIFIC_QUERY_ALERT = 'INJECT_SPECIFIC_QUERY_ALERT'
export const CLEAR_SPECIFIC_QUERY_ALERT = 'CLEAR_SPECIFIC_QUERY_ALERT'
export const INJECT_NEW_COMMENT_INTO_SPECIFIC_QUERY_ALERT = 'INJECT_NEW_COMMENT_INTO_SPECIFIC_QUERY_ALERT'

export const ADMIN_SEARCH_RISK_ALERTS_REQUEST = 'ADMIN_SEARCH_RISK_ALERTS_REQUEST'
export const ADMIN_SEARCH_RISK_ALERTS_SUCCESS = 'ADMIN_SEARCH_RISK_ALERTS_SUCCESS'
export const ADMIN_SEARCH_RISK_ALERTS_ERROR = 'ADMIN_SEARCH_RISK_ALERTS_ERROR'

export const ADMIN_SEARCH_CASES_REQUEST = 'ADMIN_SEARCH_CASES_REQUEST'
export const ADMIN_SEARCH_CASES_SUCCESS = 'ADMIN_SEARCH_CASES_SUCCESS'
export const ADMIN_SEARCH_CASES_ERROR = 'ADMIN_SEARCH_CASES_ERROR'

export const ADMIN_CREATE_CASE_REQUEST = 'ADMIN_CREATE_CASE_REQUEST'
export const ADMIN_CREATE_CASE_SUCCESS = 'ADMIN_CREATE_CASE_SUCCESS'
export const ADMIN_CREATE_CASE_ERROR = 'ADMIN_CREATE_CASE_ERROR'

export const ADMIN_SEE_MORE_SPECIFIC_CASE_REQUEST = 'ADMIN_SEE_MORE_SPECIFIC_CASE_REQUEST'
export const ADMIN_SEE_MORE_SPECIFIC_CASE_SUCCESS = 'ADMIN_SEE_MORE_SPECIFIC_CASE_SUCCESS'
export const ADMIN_SEE_MORE_SPECIFIC_CASE_ERROR = 'ADMIN_SEE_MORE_SPECIFIC_CASE_ERROR'

export const ADMIN_RESET_SPECIFIC_CASE = 'ADMIN_RESET_SPECIFIC_CASE'

export const ADMIN_EDIT_CASE_REQUEST = 'ADMIN_EDIT_CASE_REQUEST'
export const ADMIN_EDIT_CASE_SUCCESS = 'ADMIN_EDIT_CASE_SUCCESS'
export const ADMIN_EDIT_CASE_ERROR = 'ADMIN_EDIT_CASE_ERROR'

export const ADMIN_CHANGE_CASE_STATUS_REQUEST = 'ADMIN_CHANGE_CASE_STATUS_REQUEST'
export const ADMIN_CHANGE_CASE_STATUS_SUCCESS = 'ADMIN_CHANGE_CASE_STATUS_SUCCESS'
export const ADMIN_CHANGE_CASE_STATUS_ERROR = 'ADMIN_CHANGE_CASE_STATUS_ERROR'

export const ADMIN_CHANGE_CASE_SEVERITY_REQUEST = 'ADMIN_CHANGE_CASE_SEVERITY_REQUEST'
export const ADMIN_CHANGE_CASE_SEVERITY_SUCCESS = 'ADMIN_CHANGE_CASE_SEVERITY_SUCCESS'
export const ADMIN_CHANGE_CASE_SEVERITY_ERROR = 'ADMIN_CHANGE_CASE_SEVERITY_ERROR'

export const ADMIN_ADD_ALERT_REQUEST = 'ADMIN_ADD_ALERT_REQUEST'
export const ADMIN_ADD_ALERT_SUCCESS = 'ADMIN_ADD_ALERT_SUCCESS'
export const ADMIN_ADD_ALERT_ERROR = 'ADMIN_ADD_ALERT_ERROR'

export const ADMIN_REMOVE_ALERT_REQUEST = 'ADMIN_REMOVE_ALERT_REQUEST'
export const ADMIN_REMOVE_ALERT_SUCCESS = 'ADMIN_REMOVE_ALERT_SUCCESS'
export const ADMIN_REMOVE_ALERT_ERROR = 'ADMIN_REMOVE_ALERT_ERROR'

export const ADMIN_GET_CASE_ACTIVITY_REQUEST = 'ADMIN_GET_CASE_ACTIVITY_REQUEST'
export const ADMIN_GET_CASE_ACTIVITY_SUCCESS = 'ADMIN_GET_CASE_ACTIVITY_SUCCESS'
export const ADMIN_GET_CASE_ACTIVITY_ERROR = 'ADMIN_GET_CASE_ACTIVITY_ERROR'

export const ADMIN_ADD_COMMENT_TO_CASE_REQUEST = 'ADMIN_ADD_COMMENT_TO_CASE_REQUEST'
export const ADMIN_ADD_COMMENT_TO_CASE_SUCCESS = 'ADMIN_ADD_COMMENT_TO_CASE_SUCCESS'
export const ADMIN_ADD_COMMENT_TO_CASE_ERROR = 'ADMIN_ADD_COMMENT_TO_CASE_ERROR'

export const ADMIN_GET_CASES_TOTALS_REQUEST = 'ADMIN_GET_CASES_TOTALS_REQUEST'
export const ADMIN_GET_CASES_TOTALS_SUCCESS = 'ADMIN_GET_CASES_TOTALS_SUCCESS'
export const ADMIN_GET_CASES_TOTALS_ERROR = 'ADMIN_GET_CASES_TOTALS_ERROR'

export const ADMIN_GET_CASES_GRAPH_DATA_REQUEST = 'ADMIN_GET_CASES_GRAPH_DATA_REQUEST'
export const ADMIN_GET_CASES_GRAPH_DATA_SUCCESS = 'ADMIN_GET_CASES_GRAPH_DATA_SUCCESS'
export const ADMIN_GET_CASES_GRAPH_DATA_ERROR = 'ADMIN_GET_CASES_GRAPH_DATA_ERROR'

export const ADMIN_GET_ALERT_GRAPH_DATA_REQUEST = 'ADMIN_GET_ALERT_GRAPH_DATA_REQUEST'
export const ADMIN_GET_ALERT_GRAPH_DATA_SUCCESS = 'ADMIN_GET_ALERT_GRAPH_DATA_SUCCESS'
export const ADMIN_GET_ALERT_GRAPH_DATA_ERROR = 'ADMIN_GET_ALERT_GRAPH_DATA_ERROR'

export const ADMIN_INVESTIGATE_ORDER_DETAILS_REQUEST = 'ADMIN_INVESTIGATE_ORDER_DETAILS_REQUEST'
export const ADMIN_INVESTIGATE_ORDER_DETAILS_SUCCESS = 'ADMIN_INVESTIGATE_ORDER_DETAILS_SUCCESS'
export const ADMIN_INVESTIGATE_ORDER_DETAILS_ERROR = 'ADMIN_INVESTIGATE_ORDER_DETAILS_ERROR'
export const ADMIN_CLEAR_ORDER_DETAILS = 'ADMIN_CLEAR_ORDER_DETAILS'

export const ADMIN_INVESTIGATE_CASES_REQUEST = 'ADMIN_INVESTIGATE_CASES_REQUEST'
export const ADMIN_INVESTIGATE_CASES_SUCCESS = 'ADMIN_INVESTIGATE_CASES_SUCCESS'
export const ADMIN_INVESTIGATE_CASES_ERROR = 'ADMIN_INVESTIGATE_CASES_ERROR'

export const ADMIN_GET_ORDERS_TABLE_REQUEST = 'ADMIN_GET_ORDERS_TABLE_REQUEST'
export const ADMIN_GET_ORDERS_TABLE_SUCCESS = 'ADMIN_GET_ORDERS_TABLE_SUCCESS'
export const ADMIN_GET_ORDERS_TABLE_ERROR = 'ADMIN_GET_ORDERS_TABLE_ERROR'

export const ADMIN_GET_EXECUTIONS_TABLE_REQUEST = 'ADMIN_GET_EXECUTIONS_TABLE_REQUEST'
export const ADMIN_GET_EXECUTIONS_TABLE_SUCCESS = 'ADMIN_GET_EXECUTIONS_TABLE_SUCCESS'
export const ADMIN_GET_EXECUTIONS_TABLE_ERROR = 'ADMIN_GET_EXECUTIONS_TABLE_ERROR'

export const ADMIN_GET_ALERTS_TABLE_REQUEST = 'ADMIN_GET_ALERTS_TABLE_REQUEST'
export const ADMIN_GET_ALERTS_TABLE_SUCCESS = 'ADMIN_GET_ALERTS_TABLE_SUCCESS'
export const ADMIN_GET_ALERTS_TABLE_ERROR = 'ADMIN_GET_ALERTS_TABLE_ERROR'

export const ADMIN_RESET_CASE_MANAGEMENT_TABLES = 'ADMIN_RESET_CASE_MANAGEMENT_TABLES'

export const ADMIN_GET_NAVBAR_LINKS_REQUEST = 'ADMIN_GET_NAVBAR_LINKS_REQUEST'
export const ADMIN_GET_NAVBAR_LINKS_SUCCESS = 'ADMIN_GET_NAVBAR_LINKS_SUCCESS'
export const ADMIN_GET_NAVBAR_LINKS_ERROR = 'ADMIN_GET_NAVBAR_LINKS_ERROR'

export const ADMIN_USER_SEARCH_REQUEST = 'ADMIN_USER_SEARCH_REQUEST'
export const ADMIN_USER_SEARCH_SUCCESS = 'ADMIN_USER_SEARCH_SUCCESS'
export const ADMIN_USER_SEARCH_ERROR = 'ADMIN_USER_SEARCH_ERROR'

export const ADMIN_CLEAR_USERS = 'ADMIN_CLEAR_USERS'

export const ADMIN_ASSIGN_CASE_REQUEST = 'ADMIN_ASSIGN_CASE_REQUEST'
export const ADMIN_ASSIGN_CASE_SUCCESS = 'ADMIN_ASSIGN_CASE_SUCCESS'
export const ADMIN_ASSIGN_CASE_ERROR = 'ADMIN_ASSIGN_CASE_ERROR'

export const ADMIN_GET_MARKET_SYMBOLS_REQUEST = 'ADMIN_GET_MARKET_SYMBOLS_REQUEST'
export const ADMIN_GET_MARKET_SYMBOLS_SUCCESS = 'ADMIN_GET_MARKET_SYMBOLS_SUCCESS'
export const ADMIN_GET_MARKET_SYMBOLS_ERROR = 'ADMIN_GET_MARKET_SYMBOLS_ERROR'

export const ADMIN_CLEAR_MARKET_SYMBOLS = 'ADMIN_CLEAR_MARKET_SYMBOLS'

export const ADMIN_GET_MARKET_DATA_REPLAY_REQUEST = 'ADMIN_GET_MARKET_DATA_REPLAY_REQUEST'
export const ADMIN_GET_MARKET_DATA_REPLAY_SUCCESS = 'ADMIN_GET_MARKET_DATA_REPLAY_SUCCESS'
export const ADMIN_GET_MARKET_DATA_REPLAY_ERROR = 'ADMIN_GET_MARKET_DATA_REPLAY_ERROR'

export const ADMIN_ADD_ATTACHMENT_REQUEST = 'ADMIN_ADD_ATTACHMENT_REQUEST'
export const ADMIN_ADD_ATTACHMENT_SUCCESS = 'ADMIN_ADD_ATTACHMENT_SUCCESS'
export const ADMIN_ADD_ATTACHMENT_ERROR = 'ADMIN_ADD_ATTACHMENT_ERROR'

export const ADMIN_REMOVE_ATTACHMENT_REQUEST = 'ADMIN_REMOVE_ATTACHMENT_REQUEST'
export const ADMIN_REMOVE_ATTACHMENT_SUCCESS = 'ADMIN_REMOVE_ATTACHMENT_SUCCESS'
export const ADMIN_REMOVE_ATTACHMENT_ERROR = 'ADMIN_REMOVE_ATTACHMENT_ERROR'

export const ADMIN_UPDATE_CASE_DATA_WITHOUT_RELOAD_REQUEST = 'ADMIN_UPDATE_CASE_DATA_WITHOUT_RELOAD_REQUEST'
export const ADMIN_UPDATE_CASE_DATA_WITHOUT_RELOAD_SUCCESS = 'ADMIN_UPDATE_CASE_DATA_WITHOUT_RELOAD_SUCCESS'
export const ADMIN_UPDATE_CASE_DATA_WITHOUT_RELOAD_ERROR = 'ADMIN_UPDATE_CASE_DATA_WITHOUT_RELOAD_ERROR'

export const ADMIN_SEARCH_TRADERS_REQUEST = 'ADMIN_SEARCH_TRADERS_REQUEST'
export const ADMIN_SEARCH_TRADERS_SUCCESS = 'ADMIN_SEARCH_TRADERS_SUCCESS'
export const ADMIN_SEARCH_TRADERS_ERROR = 'ADMIN_SEARCH_TRADERS_ERROR'
export const ADMIN_CLEAR_TRADERS = 'ADMIN_CLEAR_TRADERS'

export const ADMIN_GET_MARKET_DATA_REPLAY_CONTENT_REQUEST = 'ADMIN_GET_MARKET_DATA_REPLAY_CONTENT_REQUEST'
export const ADMIN_GET_MARKET_DATA_REPLAY_CONTENT_SUCCESS = 'ADMIN_GET_MARKET_DATA_REPLAY_CONTENT_SUCCESS'
export const ADMIN_GET_MARKET_DATA_REPLAY_CONTENT_ERROR = 'ADMIN_GET_MARKET_DATA_REPLAY_CONTENT_ERROR'
export const ADMIN_CLEAR_MARKET_QUERY = 'ADMIN_CLEAR_MARKET_QUERY'

export const ADMIN_GET_MARKET_QUERY_REQUEST = 'ADMIN_GET_MARKET_QUERY_REQUEST'
export const ADMIN_GET_MARKET_QUERY_SUCCESS = 'ADMIN_GET_MARKET_QUERY_SUCCESS'
export const ADMIN_GET_MARKET_QUERY_ERROR = 'ADMIN_GET_MARKET_QUERY_ERROR'

export const ADMIN_GET_LAST_SALE_REQUEST = 'ADMIN_GET_LAST_SALE_REQUEST'
export const ADMIN_GET_LAST_SALE_SUCCESS = 'ADMIN_GET_LAST_SALE_SUCCESS'
export const ADMIN_GET_LAST_SALE_ERROR = 'ADMIN_GET_LAST_SALE_ERROR'

export const ADMIN_GET_ORDER_BOOK_REQUEST = 'ADMIN_GET_ORDER_BOOK_REQUEST'
export const ADMIN_GET_ORDER_BOOK_SUCCESS = 'ADMIN_GET_ORDER_BOOK_SUCCESS'
export const ADMIN_GET_ORDER_BOOK_ERROR = 'ADMIN_GET_ORDER_BOOK_ERROR'

export const ADMIN_GET_MARKET_QUERY_TRADES_REQUEST = 'ADMIN_GET_MARKET_QUERY_TRADES_REQUEST'
export const ADMIN_GET_MARKET_QUERY_TRADES_SUCCESS = 'ADMIN_GET_MARKET_QUERY_TRADES_SUCCESS'
export const ADMIN_GET_MARKET_QUERY_TRADES_ERROR = 'ADMIN_GET_MARKET_QUERY_TRADES_ERROR'

export const ADMIN_GET_ALL_ALERTS_REQUEST = 'ADMIN_GET_ALL_ALERTS_REQUEST'
export const ADMIN_GET_ALL_ALERTS_SUCCESS = 'ADMIN_GET_ALL_ALERTS_SUCCESS'
export const ADMIN_GET_ALL_ALERTS_ERROR = 'ADMIN_GET_ALL_ALERTS_ERROR'
