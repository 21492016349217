import { connect } from "react-redux";
import Forgot from "./Forgot";
import { forgotRequest, resetForgot } from "../../redux/actions/forgot";

const mapStateToProps = (state) => ({
	requestSent: state.forgot.requestSent,
});

const mapDispatchToProps = {
	forgotRequest,
	resetForgot,
};

export default connect(mapStateToProps, mapDispatchToProps)(Forgot);
