import { put, takeLatest, call } from "redux-saga/effects";
import { toast } from "react-toastify";
import { history } from "../../../configureStore";
import request from "../../../apiRequest";
import * as ACTIONS from "../../actions/reset";
import * as CONSTANTS from "../../constants/reset";

function* reset(action) {
	try {
		const { email, password, token } = action.data;
		const body = { email, password, token };

		const responseData = yield call(
			request,
			"/account/reset",
			"POST",
			body
		);

		yield put(ACTIONS.resetSuccess());
	} catch (err) {
		toast.error(err.reason);
	}
}

export default function* resetSaga() {
	yield takeLatest(CONSTANTS.RESET_REQUEST, reset);
}
