import { history } from '../../configureStore';
import * as CONSTANTS from '../constants/login';

const initialState = {
  userData: {
    data: {},
    loading: false,
    loaded: false,
    error: ''
  },
  websocket: ''
}

export default (state = initialState, action) => {
  switch (action.type) {
    case CONSTANTS.LOGIN_WITH_TOKEN:
      let currentUsername
      if (action.userData.userid) {
        currentUsername = action.userData.userid
      } else if (state.username) {
        currentUsername = state.username
      }
      return {
        ...state,
        authentication: true,
        userid: action.data,
        username: currentUsername,
        loading: false,
        token: action.token || ''
      }
    case CONSTANTS.RESET_AUTH:
      return {
        ...state,
        userData: {
          data: {},
          loading: false,
          loaded: false,
          error: ''
        }
      }
    case CONSTANTS.SET_WEB_SOCKET:
      return {
        ...state,
        websocket: action.socket
      }
    case CONSTANTS.SET_LOGIN_TOKEN:
      return {
        ...state,
        token: action.token
      }
    case CONSTANTS.CLEAR_LOGIN_TOKEN:
      return {
        ...state,
        token: ''
      }

    case CONSTANTS.LOGIN_REQUEST:
      return {
        ...state,
        userData: {
          ...state.userData,
          loading: true,
          loaded: false,
          error: ''
        }
      }

    case CONSTANTS.LOGIN_SUCCESS:
      return {
        ...state,
        userData: {
          ...state.userData,
          loading: false,
          loaded: true,
          data: action.userData,
          error: ''
        }
      }

    case CONSTANTS.LOGIN_ERROR:
      return {
        ...state,
        userData: {
          ...state.userData,
          loading: false,
          loaded: false,
          error: action.error.reason
        }
      }

    case CONSTANTS.CHECK_LOGIN_STATUS_REQUEST:
      return {
        ...state,
        userData: {
          ...state.userData,
          loading: true,
          loaded: false,
        }
      }

    default:
      return state
  }
}
