import { connect } from "react-redux"
import RiskSettings from './RiskSettings'
import {
  setDisplayOptionsRiskSettings
} from '../../../redux/actions/admin'

const mapStateToProps = state => ({
  risk_setting: state.admin.risk_setting,
  websocket: state.login.websocket,
  displayOptions: state.admin.displayOptionsRiskSettings
})

const mapDispatchToProps = {
  setDisplayOptionsRiskSettings
}

export default connect(mapStateToProps, mapDispatchToProps)(RiskSettings)
