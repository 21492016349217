//export let baseUrl = 'http://fi-risk.us-east-2.elasticbeanstalk.com/api'
export let baseUrl;
if (process.env.REACT_APP_BACKEND) {
  baseUrl = process.env.REACT_APP_BACKEND;
} else if (process.env.NODE_ENV == "development") {
  baseUrl = `https://risk.fi-edition.com/api`;
} else {
  baseUrl = `https://risk.fi-edition.com/api`;
}

export let cookieName;
if (process.env.REACT_APP_COOKIE_NAME) {
  cookieName = process.env.REACT_APP_COOKIE_NAME;
}
