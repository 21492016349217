import { connect } from "react-redux"
import CaseManagement from './CaseManagement'
import {
  searchCasesRequest,
  createCaseRequest,
  seeMoreSpecificCaseRequest,
  resetSpecificCase,
  editCaseRequest,
  changeCaseStatusRequest,
  changeCaseSeverityRequest,
  addAlertRequest,
  removeAlertRequest,
  searchRiskAlertsRequest,
  getCaseActivityRequest,
  addCommentToCaseRequest,
  getCasesTotalsRequest,
  getCasesGraphDataRequest,
  investigateOrderDetailsRequest,
  investigateCasesRequest,
  getOrdersTableRequest,
  getExecutionsTableRequest,
  getAlertsTableRequest,
  resetCaseManagementTables,
  userSearchRequest,
  clearUsers,
  assignCaseRequest,
  addAttachmentRequest,
  removeAttachmentRequest,
} from '../../../redux/actions/admin'

const mapStateToProps = state => ({
  userid: state.login.userData.data.userId,
  cases: state.admin.cases,
  specificCase: state.admin.specificCase,
  riskAlerts: state.admin.riskAlerts,
  caseActivity: state.admin.caseActivity,
  casesTotals: state.admin.casesTotals,
  casesGraphData: state.admin.casesGraphData,
  orderDetails: state.admin.orderDetails,
  investigateCases: state.admin.investigateCases,
  ordersTable: state.admin.ordersTable,
  executionsTable: state.admin.executionsTable,
  alertsTable: state.admin.alertsTable,
  queryOrder: state.admin.queryOrder,
  users: state.admin.users,
  riskAlertsMaxPageSize: 10,
  casesMaxPageSize: 10,
  addAttachment: state.admin.addAttachment,
})

const mapDispatchToProps = {
  searchCasesRequest,
  createCaseRequest,
  seeMoreSpecificCaseRequest,
  resetSpecificCase,
  editCaseRequest,
  changeCaseStatusRequest,
  changeCaseSeverityRequest,
  addAlertRequest,
  removeAlertRequest,
  searchRiskAlertsRequest,
  getCaseActivityRequest,
  addCommentToCaseRequest,
  getCasesTotalsRequest,
  getCasesGraphDataRequest,
  investigateOrderDetailsRequest,
  investigateCasesRequest,
  getOrdersTableRequest,
  getExecutionsTableRequest,
  getAlertsTableRequest,
  resetCaseManagementTables,
  userSearchRequest,
  clearUsers,
  assignCaseRequest,
  addAttachmentRequest,
  removeAttachmentRequest,
}

export default connect(mapStateToProps, mapDispatchToProps)(CaseManagement)
