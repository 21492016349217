import React, { PureComponent } from 'react'
import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import {
  Container,
  Row,
  Spinner,
} from 'react-bootstrap'
import Routes from './routes'
import { connect } from "react-redux"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

const mapStateToProps = state => ({
  authentication: state.login.authentication,
})

const mapDispatchToProps = {  }

class App extends PureComponent {
  constructor(props) {
    super(props)
  }

  render() {
    const { authentication } = this.props

    return (
      <div className='app-container'>
        <ToastContainer hideProgressBar={true} autoClose={3000} />
        <Routes authentication={authentication} />
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
