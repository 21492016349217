import { connect } from "react-redux"
import NoAccess from './NoAccess'
import {
  logout
  } from '../../redux/actions/login'

const mapStateToProps = state => ({
})

const mapDispatchToProps = {
  logout
}

export default connect(mapStateToProps, mapDispatchToProps)(NoAccess)
