import { put, takeLatest, call, delay, all } from "redux-saga/effects";
import { history } from "../../../configureStore";
import request from "../../../apiRequest";
import * as ACTIONS from "../../actions/admin";
import * as CONSTANTS from "../../constants/admin";
import { toast } from "react-toastify";

function* searchRiskAlerts(action) {
  try {
    let url = "/alerts/search?";
    if (action.params.subtype) url += `subtype=${action.params.subtype}&`;
    if (action.params.firm) url += `firm=${action.params.firm}&`;
    if (action.params.userid) url += `userid=${action.params.userid}&`;
    if (action.params.q) url += `q=${action.params.q}&`;
    if (action.params.assigned_to)
      url += `assigned_to=${action.params.assigned_to}&`;
    if (action.params.offset) url += `offset=${action.params.offset}`;

    console.log(action.params);

    const responseData = yield call(
      request,
      "/alerts/search?",
      "GET",
      action.params
    );

    yield put(ACTIONS.searchRiskAlertsSuccess(responseData));
  } catch (err) {
    toast.error(err.reason);
    yield put(ACTIONS.searchRiskAlertsError(err.reason));
  }
}

function* searchCases(action) {
  try {
    const responseData = yield call(
      request,
      "/cases/search?",
      "GET",
      action.params
    );

    yield put(ACTIONS.searchCasesSuccess(responseData));
  } catch (err) {
    toast.error(err.reason);
    yield put(ACTIONS.searchCasesError(err.reason));
  }
}

function* seeMoreSpecificCase(action) {
  try {
    const responseData = yield call(
      request,
      `/cases/search?_id=${action.id}`,
      "GET"
    );

    yield put(ACTIONS.seeMoreSpecificCaseSuccess(responseData));
  } catch (err) {
    toast.error(err.reason);
    yield put(ACTIONS.seeMoreSpecificCaseError(err));
  }
}

function* createCase(action) {
  try {
    let body;
    let responseData;
    if (action.optionalAlerts) {
      body = {
        alerts: JSON.stringify([action.optionalAlerts]),
      };
      responseData = yield call(request, "/cases/create", "POST", body);
    } else {
      responseData = yield call(request, "/cases/create", "POST");
    }

    if (responseData.message == "success") {
      yield put(ACTIONS.seeMoreSpecificCaseRequest(responseData.data._id));
    }

    yield put(ACTIONS.searchCasesRequest(action.params));
  } catch (err) {
    toast.error(err.reason);
    yield put(ACTIONS.createCaseError(err));
  }
}

function* editCase(action) {
  try {
    const responseData = yield call(
      request,
      "/cases/edit",
      "POST",
      action.data
    );
  } catch (err) {
    toast.error(err.reason);
    yield put(ACTIONS.editCaseError(err));
  }
}

function* changeCaseStatus(action) {
  try {
    const responseData = yield call(
      request,
      "/cases/changeStatus",
      "POST",
      action.data
    );
  } catch (err) {
    toast.error(err.reason);
    yield put(ACTIONS.changeCaseStatusError(err));
  }
}

function* changeCaseSeverity(action) {
  try {
    const responseData = yield call(
      request,
      "/cases/changeSeverity",
      "POST",
      action.data
    );
  } catch (err) {
    toast.error(err.reason);
    yield put(ACTIONS.changeCaseSeverityError(err));
  }
}

////////////////////////////////////////////////////////////////////////////////

function* addAlert(action) {
  try {
    const responseData = yield call(
      request,
      "/cases/addAlert",
      "POST",
      action.data
    );
  } catch (err) {
    toast.error(err.reason);
    yield put(ACTIONS.addAlertError(err));
  }
}

////////////////////////////////////////////////////////////////////////////////

function* removeAlert(action) {
  try {
    const responseData = yield call(
      request,
      "/cases/removeAlert",
      "POST",
      action.data
    );
  } catch (err) {
    toast.error(err.reason);
    yield put(ACTIONS.removeAlertError(err));
  }
}

////////////////////////////////////////////////////////////////////////////////

function* getCaseActivity(action) {
  try {
    const responseData = yield call(
      request,
      `/events/search?case_id=${action.id}`,
      "GET"
    );

    yield put(ACTIONS.getCaseActivitySuccess(responseData));
  } catch (err) {
    toast.error(err.reason);
    yield put(ACTIONS.getCaseActivityError(err));
  }
}

function* addCommentToCase(action) {
  try {
    const responseData = yield call(
      request,
      "/cases/addComment",
      "POST",
      action.data
    );

    yield put(ACTIONS.getCaseActivityRequest(action.data.case_id));
  } catch (err) {
    toast.error(err.reason);
    yield put(ACTIONS.addCommentToCaseError(err));
  }
}

function* getCasesTotals(action) {
  try {
    const responseData = yield call(
      request,
      `/cases/totals?userid=${localStorage.userid}`,
      "GET"
    );

    yield put(ACTIONS.getCasesTotalsSuccess(responseData));
  } catch (err) {
    toast.error(err.reason);
    yield put(ACTIONS.getCasesTotalsError(err));
  }
}

function* getCasesGraphData(action) {
  try {
    const responseData = yield call(request, "/cases/graph", "GET");

    yield put(ACTIONS.getCasesGraphDataSuccess(responseData));
  } catch (err) {
    toast.error(err.reason);
    yield put(ACTIONS.getCasesGraphDataError(err));
  }
}

function* getAlertGraphData(action) {
  try {
    const responseData = yield call(
      request,
      `/alerts/linegraph?subtype=${action.subtype}`,
      "GET"
    );

    yield put(ACTIONS.getAlertGraphDataSuccess(responseData));
  } catch (err) {
    toast.error(err.reason);
    yield put(ACTIONS.getAlertGraphDataError(err));
  }
}

function* investigateOrderDetails(action) {
  try {
    const responseData = yield call(
      request,
      "/alerts/queryOrder?",
      "GET",
      action.params
    );

    yield put(ACTIONS.investigateOrderDetailsSuccess(responseData));
  } catch (err) {
    toast.error(err.reason);
    yield put(ACTIONS.investigateOrderDetailsError(err));
  }
}

function* investigateCases(action) {
  try {
    const responseData = yield call(
      request,
      `/cases/investigate?caseid=${action.id}`,
      "GET"
    );

    yield put(ACTIONS.investigateCasesSuccess(responseData));
  } catch (err) {
    toast.error(err.reason);
    yield put(ACTIONS.investigateCasesError(err));
  }
}

function* getOrdersTable(action) {
  try {
    let url = `/market/orders?userid=${action.params.trader}`;
    if (action.params.symbol) url += `&security=${action.params.symbol}`;
    const responseData = yield call(request, url, "GET");

    yield put(ACTIONS.getOrdersTableSuccess(responseData));
  } catch (err) {
    toast.error(err.reason);
    yield put(ACTIONS.getOrdersTableError(err));
  }
}

function* getExecutionsTable(action) {
  try {
    let url = `/market/executions?userid=${action.params.trader}`;
    if (action.params.symbol) url += `&security=${action.params.symbol}`;
    const responseData = yield call(request, url, "GET");

    yield put(ACTIONS.getExecutionsTableSuccess(responseData));
  } catch (err) {
    toast.error(err.reason);
    yield put(ACTIONS.getExecutionsTableError(err));
  }
}

function* getAlertsTable(action) {
  try {
    let url = `/alerts/search?userid=${action.params.trader}`;
    if (action.params.alertType) url += `&subtype=${action.params.alertType}`;
    const responseData = yield call(request, url, "GET");

    yield put(ACTIONS.getAlertsTableSuccess(responseData));
  } catch (err) {
    toast.error(err.reason);
    yield put(ACTIONS.getAlertsTableError(err));
  }
}

function* getNavbarLinks(action) {
  try {
    const responseData = yield call(request, "/links", "GET");

    yield put(ACTIONS.getNavbarLinksSuccess(responseData));
  } catch (err) {
    toast.error(err.reason);
    yield put(ACTIONS.getNavbarLinksError(err));
  }
}

function* userSearch(action) {
  try {
    let url = "/account/search?";
    if (action.params) url += `q=${action.params}`;

    const responseData = yield call(request, url, "GET");

    yield put(ACTIONS.userSearchSuccess(responseData));
  } catch (err) {
    toast.error(err.reason);
    yield put(ACTIONS.userSearchError(err));
  }
}

function* assignCase(action) {
  try {
    const responseData = yield call(
      request,
      "/cases/assign",
      "POST",
      action.params
    );

    yield put(ACTIONS.assignCaseSuccess(responseData));
  } catch (err) {
    toast.error(err.reason);
    yield put(ACTIONS.assignCaseError(err));
  }
}

function* getMarketSymbols(action) {
  try {
    const responseData = yield call(request, "/market/symbols", "GET");

    yield put(ACTIONS.getMarketSymbolsSuccess(responseData));

    // if there is no symbol in the params, set the symbol as the first result from responseData
    if (!action.params?.symbol) action.params.symbol = responseData[0];
    yield put(ACTIONS.getMarketDataReplayRequest(action.params));
  } catch (err) {
    toast.error(err.reason);
    yield put(ACTIONS.getMarketSymbolsError(err));
  }
}

function* getMarketDataReplay(action) {
  try {
    const today = new Date();
    let yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    const url = "/market/history?";

    const [bestBidData, lastSaleData, bookData, marketQueryData] = yield all([
      call(request, url, "GET", { ...action.params, type: "Best Bid/Offer" }),
      call(request, url, "GET", { ...action.params, type: "Last Sale" }),
      call(request, url, "GET", { ...action.params, type: "Book" }),
      call(request, "/market/query?", "GET", {
        ...action.params,
        type: "trades",
      }),
    ]);

    yield all([
      put(ACTIONS.getMarketDataReplaySuccess(bestBidData, "Best Bid/Offer")),
      put(ACTIONS.getMarketDataReplaySuccess(lastSaleData, "Last Sale")),
      put(ACTIONS.getMarketDataReplaySuccess(bookData, "Book")),
      put(ACTIONS.getMarketDataReplaySuccess(marketQueryData, "trades")),
    ]);
  } catch (err) {
    toast.error(err.reason);
    yield put(ACTIONS.getMarketDataReplayError(err));
  }
}

function* addAttachment(action) {
  try {
    let newFileData = new FormData();
    newFileData.append("file", action.data.file);

    const uploadFileData = yield call(
      request,
      "/cases/uploadFile",
      "POST",
      newFileData,
      false,
      true
    );

    const body = {
      case_id: action.data.case_id,
      url: uploadFileData.url,
      description: action.data.description,
    };

    const responseData = yield call(
      request,
      "/cases/addAttachment",
      "POST",
      body
    );

    yield put(ACTIONS.addAttachmentSuccess(responseData));
    yield put(ACTIONS.updateCaseDataWithoutReloadRequest(action.data.case_id));
  } catch (err) {
    toast.error(err.reason);
    yield put(ACTIONS.addAttachmentError(err));
  }
}

function* removeAttachment(action) {
  try {
    const responseData = yield call(
      request,
      "/cases/removeAttachment",
      "POST",
      action.data
    );

    yield put(ACTIONS.removeAttachmentSuccess(responseData));
    yield put(ACTIONS.updateCaseDataWithoutReloadRequest(action.data.case_id));
  } catch (err) {
    toast.error(err.reason);
    yield put(ACTIONS.removeAttachmentError(err));
  }
}

function* searchTraders(action) {
  try {
    const encodedQuery = encodeURIComponent(action.query);
    const responseData = yield call(
      request,
      `/account/traders/search?q=${encodedQuery}`,
      "GET"
    );

    yield put(ACTIONS.searchTradersSuccess(responseData));
  } catch (err) {
    yield put(ACTIONS.searchTradersError(err));
  }
}

function* getMarketQuery(action) {
  try {
    const responseData = yield call(
      request,
      "/market/query?",
      "GET",
      action.params
    );

    yield put(ACTIONS.getMarketQuerySuccess(responseData));
  } catch (err) {
    yield put(ACTIONS.getMarketQueryError(err));
  }
}

function* getAllAlerts(action) {
  try {
    console.log(action.params);
    const responseData = yield call(
      request,
      "/alerts/all?",
      "GET",
      action.params
    );

    console.log(responseData);

    yield put(ACTIONS.getAllAlertsSuccess(responseData));
  } catch (err) {
    yield put(ACTIONS.getAllAlertsError(err));
  }
}

export default function* adminSaga() {
  yield takeLatest(
    CONSTANTS.ADMIN_SEARCH_RISK_ALERTS_REQUEST,
    searchRiskAlerts
  );
  yield takeLatest(CONSTANTS.ADMIN_SEARCH_CASES_REQUEST, searchCases);
  yield takeLatest(CONSTANTS.ADMIN_CREATE_CASE_REQUEST, createCase);
  yield takeLatest(
    CONSTANTS.ADMIN_SEE_MORE_SPECIFIC_CASE_REQUEST,
    seeMoreSpecificCase
  );
  yield takeLatest(CONSTANTS.ADMIN_EDIT_CASE_REQUEST, editCase);
  yield takeLatest(
    CONSTANTS.ADMIN_CHANGE_CASE_STATUS_REQUEST,
    changeCaseStatus
  );
  yield takeLatest(
    CONSTANTS.ADMIN_CHANGE_CASE_SEVERITY_REQUEST,
    changeCaseSeverity
  );
  yield takeLatest(CONSTANTS.ADMIN_ADD_ALERT_REQUEST, addAlert);
  yield takeLatest(CONSTANTS.ADMIN_REMOVE_ALERT_REQUEST, removeAlert);
  yield takeLatest(CONSTANTS.ADMIN_GET_CASE_ACTIVITY_REQUEST, getCaseActivity);
  yield takeLatest(
    CONSTANTS.ADMIN_ADD_COMMENT_TO_CASE_REQUEST,
    addCommentToCase
  );
  yield takeLatest(CONSTANTS.ADMIN_GET_CASES_TOTALS_REQUEST, getCasesTotals);
  yield takeLatest(
    CONSTANTS.ADMIN_GET_CASES_GRAPH_DATA_REQUEST,
    getCasesGraphData
  );
  yield takeLatest(
    CONSTANTS.ADMIN_GET_ALERT_GRAPH_DATA_REQUEST,
    getAlertGraphData
  );
  yield takeLatest(
    CONSTANTS.ADMIN_INVESTIGATE_ORDER_DETAILS_REQUEST,
    investigateOrderDetails
  );
  yield takeLatest(CONSTANTS.ADMIN_INVESTIGATE_CASES_REQUEST, investigateCases);
  yield takeLatest(CONSTANTS.ADMIN_GET_ORDERS_TABLE_REQUEST, getOrdersTable);
  yield takeLatest(
    CONSTANTS.ADMIN_GET_EXECUTIONS_TABLE_REQUEST,
    getExecutionsTable
  );
  yield takeLatest(CONSTANTS.ADMIN_GET_ALERTS_TABLE_REQUEST, getAlertsTable);
  yield takeLatest(CONSTANTS.ADMIN_GET_NAVBAR_LINKS_REQUEST, getNavbarLinks);
  yield takeLatest(CONSTANTS.ADMIN_USER_SEARCH_REQUEST, userSearch);
  yield takeLatest(CONSTANTS.ADMIN_ASSIGN_CASE_REQUEST, assignCase);
  yield takeLatest(
    CONSTANTS.ADMIN_GET_MARKET_SYMBOLS_REQUEST,
    getMarketSymbols
  );
  yield takeLatest(
    CONSTANTS.ADMIN_GET_MARKET_DATA_REPLAY_REQUEST,
    getMarketDataReplay
  );
  yield takeLatest(CONSTANTS.ADMIN_ADD_ATTACHMENT_REQUEST, addAttachment);
  yield takeLatest(CONSTANTS.ADMIN_REMOVE_ATTACHMENT_REQUEST, removeAttachment);
  yield takeLatest(
    CONSTANTS.ADMIN_UPDATE_CASE_DATA_WITHOUT_RELOAD_REQUEST,
    seeMoreSpecificCase
  );
  yield takeLatest(CONSTANTS.ADMIN_SEARCH_TRADERS_REQUEST, searchTraders);
  yield takeLatest(CONSTANTS.ADMIN_GET_MARKET_QUERY_REQUEST, getMarketQuery);
  yield takeLatest(CONSTANTS.ADMIN_GET_ALL_ALERTS_REQUEST, getAllAlerts);
}
