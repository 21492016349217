import { combineReducers } from "redux";
import loginReducer from "./login";
import adminReducer from "./admin";
import forgotReducer from "./forgot";
import resetReducer from "./reset";

//
// const persistConfig = {
//   key: 'root',
//   storage,
//   blacklist: ['admin']
// }
//
// const adminConfig = {
//   key: 'admin',
//   storage,
//   blacklist: ['risk_setting', 'current_risk']
// }

const appReducer = combineReducers({
	login: loginReducer,
	admin: adminReducer,
	forgot: forgotReducer,
	reset: resetReducer,
});

const rootReducer = (state, action) => {
	if (action.type === "LOGOUT" || action.type === "CLEAR_STATE") {
		state = undefined;
	}
	return appReducer(state, action);
};

export default rootReducer;
