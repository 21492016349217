import React, { PureComponent } from 'react'
import {
  Container,
  Row,
  Tabs,
  Tab,
} from 'react-bootstrap'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css';
import ReactHtmlParser from 'react-html-parser'
import {
  AiOutlineAudit,
  AiOutlineSolution,
 } from 'react-icons/ai'
 import {
   GiSettingsKnobs
 } from 'react-icons/gi'
import { BsArrowLeft } from 'react-icons/bs'
import * as COMPONENT from './'

class PreTradeRisk extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
    }
  }

  componentDidMount() {
  }

  renderTabs = () => {
    let titleA = (
      <div>
        <Row noGutters className='tab-title-div'>
          <GiSettingsKnobs className='tab-icon'/>
          Risk Control Settings
        </Row>
      </div>
    )

    let titleB = (
      <div>
        <Row noGutters className='tab-title-div'>
          <AiOutlineSolution className='tab-icon'/>
          Risk Monitor
        </Row>
      </div>
    )

    let titleC = (
      <div>
        <Row noGutters className='tab-title-div'>
          <AiOutlineAudit className='tab-icon'/>
          History Audit
        </Row>
      </div>
    )

    return (
      <Tabs defaultActiveKey='risk-control-settings' className='pre-trade-tabs'>

        <Tab eventKey='risk-control-settings' title={titleA} className='pre-trade-tab'>
          <COMPONENT.RiskSettings />
        </Tab>

        <Tab eventKey='risk-monitor' title={titleB} className='pre-trade-tab'>
          <COMPONENT.CurrentRisk />
        </Tab>

        <Tab eventKey='history-audit' title={titleC} className='pre-trade-tab'>
          <COMPONENT.HistoryAudit />
        </Tab>
      </Tabs>
    )
  }

  render() {

    return (
      <Container fluid className='admin-content-container'>
        {this.renderTabs()}
      </Container>
    )
  }
}

export default PreTradeRisk
