import { connect } from "react-redux"
import Routes from './Routes'
import {
  resetAuth,
  loginRequest,
  checkLoginStatusRequest,
} from '../redux/actions/login'

const mapStateToProps = state => ({
  userData: state.login.userData,
})

const mapDispatchToProps = {
  resetAuth,
  loginRequest,
  checkLoginStatusRequest,
}

export default connect(mapStateToProps, mapDispatchToProps)(Routes)
