import * as CONSTANTS from "../constants/reset";

export const resetRequest = (data) => ({
	type: CONSTANTS.RESET_REQUEST,
	data,
});

export const resetSuccess = (data) => ({
	type: CONSTANTS.RESET_SUCCESS,
	data,
});

export const resetError = (error) => ({
	type: CONSTANTS.RESET_ERROR,
	error,
});

export const resetReset = () => ({
	type: CONSTANTS.RESET_RESET,
});
