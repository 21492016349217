import { createStore, applyMiddleware } from 'redux'
import { routerMiddleware } from 'react-router-redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import createHistory from 'history/createBrowserHistory'
import createSagaMiddleware from 'redux-saga'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import rootReducer from './redux/reducers'
import sagas from './redux/sagas'

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['admin']
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const history = createHistory()

const devToolsOptions = {
    trace: true,
}

const composeEnhancers = composeWithDevTools(devToolsOptions)

const sagaMiddleware = createSagaMiddleware()

const middleware = [routerMiddleware(history), sagaMiddleware]

const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(...middleware)),
)

const persistor = persistStore(store)

sagaMiddleware.run(sagas)

export default { store, persistor }
