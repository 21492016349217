import { history } from "../../configureStore";
import * as CONSTANTS from "../constants/forgot";

const initialState = {
	requestSent: false,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case CONSTANTS.FORGOT_SUCCESS:
			return {
				...state,
				requestSent: true,
			};

		case CONSTANTS.RESET_FORGOT:
			return {
				...state,
				requestSent: false,
			};

		default:
			return state;
	}
};
