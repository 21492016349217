import { put, takeLatest, call, take } from "redux-saga/effects";
import { eventChannel, END } from "redux-saga";
import { toast } from "react-toastify";
import JSEncrypt from "jsencrypt/bin/jsencrypt.min.js";
import { history } from "../../../configureStore";
import * as ACTIONS from "../../actions/login";
import * as CONSTANTS from "../../constants/login";
import host from "../../../websocket.js";
import request from "../../../apiRequest";

function* establishWebsocketConnection(action) {
	try {
		const userData = action.data;
		const endpoint = `wss://${action.data.endpoint}`;
		const mySocket = new WebSocket(endpoint);

		yield put(ACTIONS.setWebsocket(mySocket));
		while (true) {
			const channel = yield take(createEventChannel(mySocket, userData));
			yield put(channel);
		}
	} catch (err) {
		toast.error(err.reason);
	}
}

function createEventChannel(mySocket, userData) {
	return eventChannel((emitter) => {
		mySocket.onopen = () => {
			console.log("opening connection...");
			const challenge = JSON.stringify({ type: "challenge" });
			mySocket.send(challenge);
		};

		mySocket.onmessage = (e) => {
			const message = JSON.parse(e.data) || "";

			console.log(message);

			if (message) {
				if (message.type === "challenge" && message.result === "OK") {
					let loginMessage;
					if (userData) {
						loginMessage = JSON.stringify({
							type: "login",
							token: userData.token,
							data: userData,
						});
						mySocket.send(loginMessage);
					}
				} else if (message.type === "current_risk") {
					return emitter({ type: "INJECT_CURRENT_RISK", message });
				} else if (message.type === "account_update") {
					if (message.result === "OK") {
						return emitter({ type: "INJECT_TRADER", message });
					} else {
						toast.error(message.result);
					}
				} else if (message.type === "risk_setting") {
					return emitter({ type: "INJECT_TRADER", message });
				} else if (message.type == "queryalerts") {
					if (message.result == "OK") {
						console.log("message from websocket: ", message);
						if (
							message.filter.subtype == "general" ||
							message.filter.subtype == "speed" ||
							message.filter.subtype == "washsale" ||
							message.filter.subtype == "duporder"
						) {
							return emitter({
								type: "INJECT_QUERY_ALERTS",
								message,
							});
						} else {
							return emitter({
								type: "INJECT_SPECIFIC_QUERY_ALERT",
								message,
							});
						}
					} else {
						toast.error(message.result);
					}
				} else if (
					message.type === "login" &&
					message.result === "OK"
				) {
					return emitter({ type: "LOGIN_SUCCESS", userData });
				} else if (
					message.type === "login" &&
					message.result !== "OK"
				) {
					return emitter({ type: "LOGIN_ERROR" });
				} else if (message.type == "history_query") {
					if (message.result == "OK") {
						console.log("message from websocket: ", message);
						const { total_rec } = message;
						return emitter({
							type: "INJECT_HISTORY_QUERY",
							message,
						});
					} else {
						toast.error(message.result);
					}
				} else if (message.type == "addalert") {
					if (message.eventType == "comment") {
						return emitter({
							type: "INJECT_NEW_COMMENT_INTO_SPECIFIC_QUERY_ALERT",
							message,
						});
					}
				}
			}
		};

		return () => {
			mySocket.close();
			console.log("socket off");
		};
	});
}

function parseKeysIntoArray(object) {
	const arrayOfKeys = Object.keys(object);
	const indexOfType = arrayOfKeys.indexOf("type");
	if (indexOfType > -1) arrayOfKeys.splice(indexOfType, 1);

	return arrayOfKeys;
}

function getValueFromMessage(key, message) {
	return message[key];
}

function* login(action) {
	try {
		const userData = {
			email: action.data.email,
			password: action.data.password,
		};
		const responseData = yield call(
			request,
			"/account/login",
			"POST",
			userData
		);
		if (responseData.data.hasAccess) {
			yield put(ACTIONS.establishWebsocketConnection(responseData.data));
		}
	} catch (err) {
		if (err.reason !== "User not logged in") {
			toast.error(err.reason);
		}
		yield put(ACTIONS.resetAuth());
		yield put(ACTIONS.loginError(err));
	}
}

function* checkLoginStatus(action) {
	try {
		const responseData = yield call(request, "/account", "GET");
		if (responseData.data.hasAccess) {
			yield put(ACTIONS.establishWebsocketConnection(responseData.data));
		}
	} catch (err) {
		if (err.reason !== "User not logged in") {
			toast.error(err.reason);
		}
		yield put(ACTIONS.resetAuth());
		yield put(ACTIONS.loginError(err));
	}
}

function* logout(action) {
	try {
		const responseData = yield call(request, "/account/logout", "POST");
		history.push("/login");
	} catch (err) {
		toast.error(err.reason);
	}
}

export default function* loginSaga() {
	yield takeLatest(CONSTANTS.LOGIN_REQUEST, login);
	yield takeLatest(CONSTANTS.LOGIN_CREATE_EVENT_CHANNEL, createEventChannel);
	yield takeLatest(CONSTANTS.CHECK_LOGIN_STATUS_REQUEST, checkLoginStatus);
	yield takeLatest(
		CONSTANTS.ESTABLISH_WEBSOCKET_CONNECTION,
		establishWebsocketConnection
	);
	yield takeLatest(CONSTANTS.LOGOUT, logout);
}
