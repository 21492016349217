import React, { PureComponent } from 'react'
import {
  Nav,
  Row,
  Col,
  Button,
  FormControl,
  Container,
  FormCheck,
} from 'react-bootstrap'
import { NavLink } from "react-router-dom"
import {
  AiOutlineLogout
} from 'react-icons/ai'
import logo from '../../images/FI_LOGO.png'

class NoAccess extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
    }
  }

  renderLogo = () => (
    <div className='login-logo-row'>
      <div className='login-logo-box'>
        <img src={logo} className='login-logo' />
      </div>
    </div>
  )

  renderLogoutButton = () => {
    return (
      <div className='no-access-logout'>
        <div className='click-to-logout' onClick={this.props.logout}>
          <AiOutlineLogout />
          Logout
        </div>
      </div>
    )
  }

  render() {

    return (
      <Container fluid className='login-container'>
        {this.renderLogo()}

        <div className='login-box no-access-box'>
          You do not have access
          {this.renderLogoutButton()}
        </div>



      </Container>
    )
  }
}

export default NoAccess
