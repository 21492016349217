import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Table,
  Spinner,
  Button,
  // Pagination,
  Modal,
  FormControl,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import ReactHtmlParser from "react-html-parser";
import {
  AiOutlineRedo,
  AiOutlineEdit,
  AiOutlineSave,
  AiOutlineClose,
  AiOutlineFileSearch,
  AiOutlineAlert,
  AiOutlineLineChart,
  AiOutlineCheckCircle,
  AiOutlineExclamationCircle,
} from "react-icons/ai";
import Pagination from "../../Pagination/Pagination";
import { ResponsiveLine } from "@nivo/line";

class RiskAlerts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      params: {
        subtype: "General",
        firm: "",
        userid: "",
        offset: 0,
      },
      riskAlerts: {
        data: {},
        loading: false,
        loaded: false,
        error: "",
      },
      alertGraphData: {
        data: {},
        loading: false,
        loaded: false,
        error: "",
      },
      currentPage: 0,
      totalPages: 0,
    };
  }

  componentDidMount() {
    this.props.searchRiskAlertsRequest(this.state.params);
    this.props.getAlertGraphDataRequest(this.state.params.subtype);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.riskAlerts !== this.props.riskAlerts) {
      this.setState({
        totalPages: this.props.riskAlerts?.loaded
          ? Math.ceil(
              this.props.riskAlerts.data.total /
                this.props.riskAlertsMaxPageSize
            )
          : 0,
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      this.setState({
        riskAlerts: nextProps.riskAlerts,
        alertGraphData: nextProps.alertGraphData,
      });
    }
  }

  handleChangeParams = (e) => {
    this.setState(
      {
        params: {
          ...this.state.params,
          [e.target.name]: e.target.value,
        },
      },
      () => this.props.searchRiskAlertsRequest(this.state.params)
    );
  };

  handleChangeAlertDropdown = (e) => {
    this.setState(
      {
        params: {
          ...this.state.params,
          [e.target.name]: e.target.value,
        },
      },
      () => this.handleSearchAndGetGraphData()
    );
  };

  handleSearchAndGetGraphData = () => {
    this.props.searchRiskAlertsRequest(this.state.params);
    this.props.getAlertGraphDataRequest(this.state.params.subtype);
  };

  handleConvertTimeToDate = (time) => {
    let humanReadableDate = new Date(0);
    humanReadableDate.setUTCMilliseconds(time);
    return humanReadableDate.toUTCString();
  };

  handleGetRefno = (errordetails) => {
    const firstStep = errordetails.split("refno: ");
    const secondStep = firstStep[1].split(",");
    const refno = secondStep[0];

    return refno;
  };

  formatDate = (dateInput) => {
    let date = new Date(dateInput);
    const month = date.toLocaleString("en-us", { month: "short" });
    const day = date.getDate();
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();

    return `${month} ${day}, ${year}; ${hours}:${minutes}`;
  };

  handleCreateACase = (record) => {
    this.props.createCaseRequest(record);
    this.props.history.push("/admin/case-management");
  };

  handlePageClick = (number) => {
    const scrollHere = document.getElementById("scroll-here");
    const topPos = scrollHere.offsetTop;
    const test = document.getElementsByClassName("admin-content-pane");
    test[0].scrollTop = topPos;
    this.setState(
      {
        currentPage: number.selected,
        params: {
          ...this.state.params,
          offset: number.selected * this.props.riskAlertsMaxPageSize,
        },
      },
      () => this.props.searchRiskAlertsRequest(this.state.params)
    );
  };

  /****************************************************************************/

  renderSearchBar = () => {
    return (
      <Row noGutters className="search-bar-row">
        <div className="search-bar">
          <label className="search-label">Firm:</label>
          <FormControl
            value={this.state.params.firm}
            name="firm"
            onChange={this.handleChangeParams}
            className="search-form-control"
          />
        </div>

        <div className="search-bar">
          <label className="search-label">Account:</label>
          <FormControl
            value={this.state.params.userid}
            name="userid"
            onChange={this.handleChangeParams}
            className="search-form-control"
          />
        </div>
      </Row>
    );
  };

  renderTable = () => {
    const { data, loading, loaded } = this.state.riskAlerts;

    let body;
    if (loaded && data.total > 0) {
      body = data.data.map((record) => this.renderRecord(record));
    } else if (loaded && data.total == 0) {
      body = (
        <tr>
          <td colSpan="4" className="centered-td">
            No Risk Alerts on record
          </td>
        </tr>
      );
    } else if (loading) {
      body = (
        <tr>
          <td colSpan="4" className="centered-td">
            {this.renderLoading()}
          </td>
        </tr>
      );
    }

    return (
      <Table responsive bordered className="submissions-table">
        <thead>
          <tr>
            <th className="table-header-cell">Time</th>
            <th className="table-header-cell">Account</th>
            <th className="table-header-cell">Refno</th>
            <th className="table-header-cell">Error Details</th>
          </tr>
        </thead>

        <tbody>{body}</tbody>
      </Table>
    );
  };

  renderRecord = (record) => {
    const {
      account,
      firm,
      rec_no,
      time,
      eventid,
      errordetails,
      mentionedIn,
    } = record;
    const convertedDate = this.handleConvertTimeToDate(time);
    const formattedDate = this.formatDate(convertedDate);
    const refno = this.handleGetRefno(errordetails);

    let button;
    if (eventid) {
      const tooltip = (
        <Tooltip id="button-tooltip">Create a Case for this Alert</Tooltip>
      );
      button = (
        <OverlayTrigger placement="top" delay={{ show: 250 }} overlay={tooltip}>
          <AiOutlineFileSearch
            onClick={() => this.handleCreateACase(record)}
            className="individual-form-icon"
          />
        </OverlayTrigger>
      );
    }

    let mentionedInIcon;
    if (mentionedIn.length == 0) {
      const tooltip = (
        <Tooltip id="button-tooltip">
          This alert is not mentioned in any cases
        </Tooltip>
      );
      mentionedInIcon = (
        <OverlayTrigger
          placement="left"
          delay={{ show: 250 }}
          overlay={tooltip}
        >
          <AiOutlineCheckCircle className="mentionedIn-icon" />
        </OverlayTrigger>
      );
    } else if (mentionedIn.length > 0) {
      button = "";
      const { _id, name, date_created, status, severity } = mentionedIn[0];
      const tooltip = (
        <Tooltip id="button-tooltip">
          This Alert is mentioned in:
          <Row noGutters>Case Name: {name}</Row>
          <Row noGutters>Status: {status}</Row>
          <Row noGutters>Severity: {severity}</Row>
          <Row noGutters>Created on: {this.formatDate(date_created)}</Row>
          <Row className="mentionedIn-header" noGutters>
            Click the icon to view this case
          </Row>
        </Tooltip>
      );

      let icon;
      if (status == "closed") {
        icon = <AiOutlineCheckCircle className="mentionedIn-icon" id="true" />;
      } else {
        icon = (
          <AiOutlineExclamationCircle
            className="mentionedIn-icon"
            id="ongoing"
          />
        );
      }

      mentionedInIcon = (
        <OverlayTrigger
          placement="left"
          delay={{ show: 250 }}
          overlay={tooltip}
        >
          <a href={`/admin/case-management?${_id}`} target="_blank">
            {icon}
          </a>
        </OverlayTrigger>
      );
    }

    return (
      <tr key={rec_no}>
        <td>{formattedDate}</td>
        <td>{account}</td>
        <td>{refno}</td>
        <td className="error-details-td">
          <Row noGutters>
            <Col>{errordetails}</Col>
            <Col sm={1}>
              <Row noGutters className="alerts-table-icons-row">
                {button}
                {mentionedInIcon}
              </Row>
            </Col>
          </Row>
        </td>
      </tr>
    );
  };

  renderLineGraph = () => {
    const { data, loading, loaded } = this.state.alertGraphData;

    let content;
    if (loaded) {
      content = (
        <div className="pda-graph-box">
          <ResponsiveLine
            data={data.dataArray}
            margin={{ top: 50, right: 50, bottom: 50, left: 50 }}
            xScale={{ type: "point" }}
            yScale={{
              type: "linear",
              min: "auto",
              max: "auto",
              stacked: true,
              reverse: false,
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={null}
            axisLeft={null}
            enableGridX={false}
            colors={{ scheme: "pink_yellowGreen" }}
            pointSize={10}
            pointColor={{ theme: "background" }}
            pointBorderWidth={2}
            pointBorderColor={{ from: "serieColor" }}
            pointLabel="y"
            pointLabelYOffset={-12}
            enableArea={true}
            useMesh={true}
            tooltip={(e) => this.renderTooltip(e)}
          />
        </div>
      );
    } else if (loading) {
      content = <div className="forms-table-box">{this.renderLoading()}</div>;
    }

    return content;
  };

  renderTooltip = (e) => {
    const { x, y } = e.point.data;

    return (
      <div className="admin-linegraph-tooltip pre-trade-alerts-tooltip">
        <b>{x}</b>: <b>{y} Alerts</b>
      </div>
    );
  };

  renderAlertsTotals = () => {
    const { loading, loaded, data } = this.state.alertGraphData;

    let content;
    if (loaded) {
      let oneWeekChange;
      if (data.dataArray[0].data.length > 2) {
        const lastTwoEntries = data.dataArray[0].data.slice(
          data.dataArray[0].data.length - 2,
          data.dataArray[0].data.length
        );
        const secondToLastYValue = lastTwoEntries[0].y;
        const lastYValue = lastTwoEntries[1].y;
        oneWeekChange =
          ((lastYValue - secondToLastYValue) / (secondToLastYValue || 1)) * 100;
        oneWeekChange = oneWeekChange.toFixed(0);
      } else if (data.dataArray[0].data.length == 1) {
        oneWeekChange = 0;
      } else if (data.dataArray[0].data.length == 0) {
        oneWeekChange = 0;
      }

      let sign;
      if (oneWeekChange < 0) {
        sign = "positive";
      } else if (oneWeekChange > 0) {
        sign = "negative";
      } else if (oneWeekChange == 0) {
        sign = "neutral";
      }

      content = (
        <React.Fragment>
          <div className="alert-type-search-container">
            <FormControl
              value={this.state.params.subtype}
              name="subtype"
              onChange={this.handleChangeAlertDropdown}
              className="alert-type-formControl"
              as="select"
            >
              <option value="General">General</option>
              <option value="WashSale">Wash Trade</option>
              <option value="DupOrder">Duplicate Order</option>
              <option value="Speed">Speed Check</option>
              <option value="MaxNotional">Max Order Notional</option>
            </FormControl>
          </div>

          <div className="forms-table-box smaller-padding">
            <Row noGutters className="value-row">
              <div>
                <AiOutlineAlert className="value-icon" />
                Total Alerts:
              </div>
              {data.dataObject.total || 0}
            </Row>
          </div>
          <div className="forms-table-box smaller-padding">
            <Row noGutters className="value-row">
              <div>
                <AiOutlineLineChart className="value-icon" />1 Week Change:
              </div>
              <div id={sign}>{oneWeekChange}%</div>
            </Row>
          </div>
        </React.Fragment>
      );
    } else if (loading) {
      content = <div className="forms-table-box">{this.renderLoading()}</div>;
    }

    return content;
  };

  renderLoading = () => (
    <Row noGutters className="app-spinner full-height">
      <Spinner animation="border" className="common-grey-spinner" />
    </Row>
  );

  render() {
    return (
      <Container fluid className="admin-content-container" id="scroll-here">
        <Row noGutters className="forms-header">
          <h4>Pre-Trade Alerts</h4>
        </Row>

        <Row noGutters className="cases-totals-row">
          <div className="floating-chart-content">
            {this.renderAlertsTotals()}
          </div>
          <div className="cases-totals-container-charts">
            {this.renderLineGraph()}
          </div>
        </Row>

        <div className="forms-table-box">
          {this.renderSearchBar()}
          {this.renderTable()}
          <Pagination
            totalPages={this.state.totalPages}
            handlePageClick={this.handlePageClick}
            dataPageNumber={this.state.currentPage}
          />
        </div>
      </Container>
    );
  }
}

export default RiskAlerts;
