import React, { PureComponent } from "react";
import {
  Container,
  Row,
  Col,
  Table,
  Spinner,
  Button,
  Modal,
  FormControl,
  Accordion,
  Tabs,
  Tab,
  Tooltip,
  OverlayTrigger,
  FormCheck,
  Card,
  Image,
  FormFile,
} from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import ReactHtmlParser from "react-html-parser";
import { history } from "../../../configureStore";
import {
  AiOutlineRedo,
  AiOutlineEdit,
  AiOutlineSave,
  AiOutlineClose,
  AiOutlineFileSearch,
  AiOutlinePlus,
  AiOutlineComment,
  AiOutlineUserAdd,
  AiOutlineSearch,
  AiOutlineFile,
  AiOutlineFilePdf,
  AiOutlineFileWord,
  AiOutlineInfoCircle,
  AiOutlineDelete,
} from "react-icons/ai";
import { BsArrowLeft, BsBriefcase } from "react-icons/bs";
import Pagination from "../../Pagination/Pagination";
import { ResponsiveBar } from "@nivo/bar";

class CaseManagement extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      params: {
        alerts: "",
        status: "",
        severity: "",
        q: "",
        assigned_to: "",
        offset: 0,
      },
      cases: {
        data: {},
        loading: false,
        loaded: false,
        error: "",
      },
      specificCase: {
        data: {},
        loading: false,
        loaded: false,
        error: "",
      },
      editParams: {
        name: false,
        desc: false,
      },
      showAlertsModal: false,
      riskAlerts: {
        data: {},
        loading: false,
        loaded: false,
        error: "",
      },
      riskAlertsParams: {
        assigned_to: "",
        firm: "",
        subtype: "",
      },
      caseActivity: {
        data: {},
        loading: false,
        loaded: false,
        error: "",
      },
      casesTotals: {
        data: {},
        loading: false,
        loaded: false,
        error: "",
      },
      casesGraphData: {
        data: {},
        loading: false,
        loaded: false,
        error: "",
      },
      orderDetails: {
        data: {},
        loading: false,
        loaded: false,
        error: "",
      },
      investigateCases: {
        data: {},
        loading: false,
        loaded: false,
        error: "",
      },
      investigateParams: {
        trader: "",
        symbol: "",
        alertType: "General",
      },
      ordersTable: {
        data: {},
        loading: false,
        loaded: false,
        error: "",
      },
      executionsTable: {
        data: {},
        loading: false,
        loaded: false,
        error: "",
      },
      alertsTable: {
        data: {},
        loading: false,
        loaded: false,
        error: "",
      },
      queryOrder: {
        data: {},
        loading: false,
        loaded: false,
        error: "",
      },
      showOrderDetailsModal: false,
      showCommentBox: false,
      comment: "",
      users: this.props.users,
      userSearchQuery: "",
      userAssignCaseParams: {
        user_id: "",
        case_id: "",
      },
      showAttachmentModal: false,
      showAttachmentDetailsModal: false,
      attachmentDetails: {},
      pleaseConfirmDeleteAttachment: false,
      currentRiskAlertsPage: 0,
      totalRiskAlertsPages: 0,
      currentCasesPage: 0,
      totalCasesPages: 0,
      addAttachment: this.props.addAttachment,
    };
  }

  componentDidMount() {
    this.props.resetSpecificCase();
    this.props.searchCasesRequest(this.state.params);
    this.props.getCasesTotalsRequest();
    this.props.getCasesGraphDataRequest();
    this.handleGetCaseFromUrl();
    this.props.resetCaseManagementTables();
    this.handleClearUsers();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.riskAlerts !== this.props.riskAlerts) {
      this.setState({
        totalRiskAlertsPages: this.props.riskAlerts?.loaded
          ? Math.ceil(
              this.props.riskAlerts.data.total /
                this.props.riskAlertsMaxPageSize
            )
          : 0,
      });
    }

    if (prevProps.cases !== this.props.cases) {
      this.setState({
        totalCasesPages: this.props.cases?.loaded
          ? Math.ceil(this.props.cases.data.total / this.props.casesMaxPageSize)
          : 0,
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      this.setState({
        cases: nextProps.cases,
        specificCase: nextProps.specificCase,
        riskAlerts: nextProps.riskAlerts,
        caseActivity: nextProps.caseActivity,
        casesTotals: nextProps.casesTotals,
        casesGraphData: nextProps.casesGraphData,
        orderDetails: nextProps.orderDetails,
        investigateCases: nextProps.investigateCases,
        ordersTable: nextProps.ordersTable,
        executionsTable: nextProps.executionsTable,
        alertsTable: nextProps.alertsTable,
        queryOrder: nextProps.queryOrder,
        users: nextProps.users,
      });
    }
  }

  handleChangeParams = (e) => {
    if (e.target.name == "assigned_to") {
      if (e.target.value) {
        this.setState(
          {
            params: {
              ...this.state.params,
              assigned_to: e.target.value,
            },
          },
          () => this.props.userSearchRequest(this.state.params.assigned_to)
        );
      } else {
        this.setState(
          {
            params: {
              ...this.state.params,
              assigned_to: "",
            },
          },
          () => this.handleClearUsers()
        );
      }
    } else {
      this.setState(
        {
          params: {
            ...this.state.params,
            [e.target.name]: e.target.value,
          },
        },
        () => this.props.searchCasesRequest(this.state.params)
      );
    }
  };

  handleSelectUser = (userId) => {
    this.setState(
      {
        params: {
          ...this.state.params,
          assigned_to: userId,
        },
      },
      () => this.props.searchCasesRequest(this.state.params)
    );
  };

  handleAssignCase = (user_id) => {
    const obj = {
      target: {
        value: user_id,
      },
    };
    const case_id = this.state.specificCase.data.docs[0]._id;
    this.handleChangeSpecificCaseAssignedTo(obj);
    this.setState(
      {
        userAssignCaseParams: {
          ...this.state.userAssignCaseParams,
          user_id: user_id,
          case_id: case_id,
        },
      },
      () => this.props.assignCaseRequest(this.state.userAssignCaseParams)
    );
  };

  handleConvertTimeToDate = (time) => {
    let humanReadableDate = new Date(0);
    humanReadableDate.setUTCMilliseconds(time);

    return this.formatDate(humanReadableDate.toUTCString());
  };

  handleSubtractTime = (time) => {
    let date = new Date(0);
    date.setUTCMilliseconds(time);
    date.setHours(date.getHours() - 3);
    return date;
  };

  handleGetRefno = (errordetails) => {
    const firstStep = errordetails.split("refno: ");
    const secondStep = firstStep[1].split(",");
    const refno = secondStep[0];

    return refno;
  };

  formatDate = (dateInput) => {
    let date = new Date(dateInput);
    return date.toLocaleString();
  };

  handleResetParams = () => {
    this.handleClearUsers();
    this.setState(
      {
        params: {
          alerts: "General",
          status: "",
          severity: "",
          q: "",
          assigned_to: "",
          offset: 0,
        },
        users: {
          ...this.state.users,
          loaded: false,
          loading: false,
        },
        currentCasesPage: 1,
      },
      () => this.props.searchCasesRequest(this.state.params)
    );
  };

  handleSelectTab = (tab) => {
    if (tab == "assigned") {
      this.setState(
        {
          params: {
            ...this.state.params,
            assigned_to: this.props.userid,
          },
          showCommentBox: false,
        },
        () => this.props.searchCasesRequest(this.state.params)
      );
    } else if (tab == "all-cases") {
      this.setState(
        {
          params: {
            ...this.state.params,
            assigned_to: "",
          },
          showCommentBox: false,
        },
        () => this.props.searchCasesRequest(this.state.params)
      );
    } else if (tab == "investigate") {
      this.setState({
        params: {
          ...this.state.params,
          assigned_to: "",
        },
        showCommentBox: false,
      });
      this.handleResetCaseManagementTables();
      this.handleInvestigateCasesRequest();
    }
  };

  // handleClickCasesPage = number => {
  //   this.setState({
  //     currentCasesPage: number,
  //     params: {
  //       ...this.state.params,
  //       offset: ((number - 1) * 10)
  //     }
  //   }, () => this.props.searchCasesRequest(this.state.params))
  // }

  handleClickCasesPage = (number) => {
    this.setState(
      {
        currentPage: number.selected,
        params: {
          ...this.state.params,
          offset: number.selected * this.props.casesMaxPageSize,
        },
      },
      () => this.props.searchCasesRequest(this.state.params)
    );
  };

  handleClickRiskAlertsPage = (number) => {
    this.setState(
      {
        currentPage: number.selected,
        riskAlertsParams: {
          ...this.state.riskAlertsParams,
          offset: number.selected * this.props.riskAlertsMaxPageSize,
        },
      },
      () => this.props.searchRiskAlertsRequest(this.state.riskAlertsParams)
    );
  };

  handleReturnToCaseList = () => {
    history.push("/admin/case-management");
    this.setState({
      showCommentBox: false,
    });
    this.props.resetSpecificCase();
    this.handleClearUsers();
    this.props.searchCasesRequest(this.state.params);
  };

  handleClearUsers = () => {
    this.props.clearUsers();
    this.setState({
      userSearchQuery: "",
      params: {
        ...this.state.params,
        assigned_to: "",
      },
    });
  };

  toggleEditContent = (target) => {
    this.setState({
      editParams: {
        ...this.state.editParams,
        [target]: !this.state.editParams[target],
      },
    });
  };

  handleEditCase = (e) => {
    const docs = this.state.specificCase.data.docs;
    docs[0][e.target.name] = e.target.value;

    const body = {
      case_id: docs[0]._id,
      name: docs[0].name,
      description: docs[0].description,
    };

    this.setState(
      {
        specificCase: {
          ...this.state.specificCase,
          data: {
            ...this.state.specificCase.data,
            docs: docs,
          },
        },
      },
      () => this.props.editCaseRequest(body)
    );
  };

  handleChangeSpecificCaseStatus = (e) => {
    let docs = this.state.specificCase.data.docs;
    docs[0][e.target.name] = e.target.value;

    const body = {
      case_id: docs[0]._id,
      status: docs[0].status,
    };

    this.setState(
      {
        specificCase: {
          ...this.state.specificCase,
          data: {
            ...this.state.specificCase.data,
            docs: docs,
          },
        },
      },
      () => this.props.changeCaseStatusRequest(body)
    );
  };

  handleChangeSpecificCaseSeverity = (e) => {
    let docs = this.state.specificCase.data.docs;
    docs[0][e.target.name] = e.target.value;

    const body = {
      case_id: docs[0]._id,
      severity: docs[0].severity,
    };

    this.setState(
      {
        specificCase: {
          ...this.state.specificCase,
          data: {
            ...this.state.specificCase.data,
            docs: docs,
          },
        },
      },
      () => this.props.changeCaseSeverityRequest(body)
    );
  };

  handleChangeSpecificCaseAssignedTo = (e) => {
    if (e.target.value) {
      this.setState(
        {
          userSearchQuery: e.target.value,
        },
        () => this.props.userSearchRequest(this.state.userSearchQuery)
      );
    } else {
      this.setState(
        {
          userSearchQuery: e.target.value,
        },
        () => this.handleClearUsers()
      );
    }
  };

  handleInvestigateOffendingOrder = (params) => {
    this.setState(
      {
        showOrderDetailsModal: true,
      },
      () => this.props.investigateOrderDetailsRequest(params)
    );
  };

  handleHideOrderDetailsModal = () => {
    this.setState({
      showOrderDetailsModal: false,
    });
  };

  handleLoadSpecificCase = (id) => {
    history.push(`?${id}`);
    this.handleClearUsers();
    this.props.seeMoreSpecificCaseRequest(id);
    this.props.getCaseActivityRequest(id);
  };

  handleToggleCommentBox = () => {
    this.setState({
      showCommentBox: !this.state.showCommentBox,
      comment: "",
    });
  };

  handlePostComment = () => {
    this.handleToggleCommentBox();
    const body = {
      case_id: this.state.specificCase.data.docs[0]._id,
      data: this.state.comment,
    };
    this.props.addCommentToCaseRequest(body);
  };

  handleChangeComment = (e) => {
    this.setState({
      comment: e.target.value,
    });
  };

  handleShowAlertsModal = () => {
    this.setState({
      showAlertsModal: true,
    });
  };

  handleHideAlertsModal = () => {
    this.setState({
      showAlertsModal: false,
    });
  };

  handleCheckAlert = (alert) => {
    const docs = this.state.specificCase.data.docs;

    let newAlerts = [];
    const found = docs[0].alerts.some((el) => el.eventid == alert.eventid);
    if (found) {
      for (let i = 0; i < docs[0].alerts.length; i++) {
        if (docs[0].alerts[i].eventid == alert.eventid) {
          docs[0].alerts.splice(i, 1);
        }
      }
    } else {
      docs[0].alerts.push(alert);
      newAlerts.push(alert);
    }

    const body = {
      case_id: docs[0]._id,
      alerts: found ? JSON.stringify([alert]) : JSON.stringify(newAlerts),
    };

    this.setState(
      {
        specificCase: {
          ...this.state.specificCase,
          data: {
            ...this.state.specificCase.data,
            docs: JSON.parse(JSON.stringify(docs)),
          },
        },
      },
      () => {
        found
          ? this.props.removeAlertRequest(body)
          : this.props.addAlertRequest(body);
      }
    );
  };

  handleEditCase = (e) => {
    const docs = this.state.specificCase.data.docs;
    docs[0][e.target.name] = e.target.value;

    const body = {
      case_id: docs[0]._id,
      name: docs[0].name,
      description: docs[0].description,
    };

    this.setState(
      {
        specificCase: {
          ...this.state.specificCase,
          data: {
            ...this.state.specificCase.data,
            docs: docs,
          },
        },
      },
      () => this.props.editCaseRequest(body)
    );
  };

  handleSearchAlerts = (e) => {
    this.setState(
      {
        riskAlertsParams: {
          ...this.state.riskAlertsParams,
          [e.target.name]: e.target.value,
        },
      },
      () => this.props.searchRiskAlertsRequest(this.state.riskAlertsParams)
    );
  };

  handleGetCaseFromUrl = () => {
    const { search } = history.location;
    const section = search.split("?");

    if (section[1]) {
      this.handleLoadSpecificCase(section[1]);
    }
  };

  handleReloadCaseActivity = () => {
    const id = this.state.specificCase.data.docs[0]._id;
    this.props.getCaseActivityRequest(id);
  };

  handleInvestigateCasesRequest = () => {
    const id = this.state.specificCase.data.docs[0]._id;
    this.props.investigateCasesRequest(id);
  };

  handleAllTableCalls = () => {
    const { investigateParams } = this.state;
    if (investigateParams.trader) {
      this.props.getOrdersTableRequest(investigateParams);
      this.props.getExecutionsTableRequest(investigateParams);
      this.props.getAlertsTableRequest(investigateParams);
    } else {
      this.props.resetCaseManagementTables();
    }
  };

  handleAlertsCall = () => {
    const { investigateParams } = this.state;
    if (investigateParams.trader) {
      this.props.getAlertsTableRequest(investigateParams);
    } else {
      this.props.resetCaseManagementTables();
    }
  };

  handleOrdersExecutionsCalls = () => {
    const { investigateParams } = this.state;
    if (investigateParams.trader) {
      this.props.getOrdersTableRequest(investigateParams);
      this.props.getExecutionsTableRequest(investigateParams);
    } else {
      this.props.resetCaseManagementTables();
    }
  };

  handleResetCaseManagementTables = () => {
    this.setState(
      {
        investigateParams: {
          trader: "",
          symbol: "",
          alertType: "General",
        },
      },
      () => this.props.resetCaseManagementTables()
    );
  };

  handleGetRefno = (errordetails) => {
    if (errordetails.includes("refno")) {
      const firstStep = errordetails.split("refno: ");
      const secondStep = firstStep[1].split(",");
      const refno = secondStep[0];

      return refno;
    } else {
      return errordetails;
    }
  };

  handleChangeSelectATrader = (e) => {
    this.setState(
      {
        investigateParams: {
          ...this.state.investigateParams,
          [e.target.name]: e.target.value,
        },
      },
      () => this.handleAllTableCalls()
    );
  };

  handleChangeSelectASymbol = (e) => {
    this.setState(
      {
        investigateParams: {
          ...this.state.investigateParams,
          [e.target.name]: e.target.value,
        },
      },
      () => this.handleOrdersExecutionsCalls()
    );
  };

  handleChangeAlertType = (e) => {
    this.setState(
      {
        investigateParams: {
          ...this.state.investigateParams,
          [e.target.name]: e.target.value,
        },
      },
      () => this.handleAlertsCall()
    );
  };

  handleShowAttachmentModal = () => {
    this.setState({
      showAttachmentModal: true,
    });
  };

  handleHideAttachmentModal = () => {
    this.setState({
      showAttachmentModal: false,
    });
  };

  handleShowAttachmentDetailsModal = (attachment) => {
    this.setState({
      showAttachmentDetailsModal: true,
      attachmentDetails: attachment,
    });
  };

  handleHideAttachmentDetailsModal = () => {
    this.setState({
      showAttachmentDetailsModal: false,
      attachmentDetails: {},
    });

    this.handleCancelDeleteAttachment();
  };

  handleRemoveAttachment = (url) => {
    const data = {
      url: url,
      case_id: this.state.specificCase.data.docs[0]._id,
    };

    this.props.removeAttachmentRequest(data);
    this.handleHideAttachmentDetailsModal();
  };

  handleAddAttachment = (file, description) => {
    const data = {
      case_id: this.state.specificCase.data.docs[0]._id,
      file: file,
      description: description,
    };

    this.props.addAttachmentRequest(data);
    this.handleHideAttachmentModal();
  };

  /****************************************************************************/

  renderSearchBar = () => {
    return (
      <React.Fragment>
        <Row noGutters className="search-bar-row">
          {this.renderResetParamsButton()}
          <div className="search-bar">
            <label className="search-label">Alert Type:</label>
            <FormControl
              value={this.state.params.alerts}
              name="alerts"
              onChange={this.handleChangeParams}
              className="search-form-control"
              as="select"
            >
              {this.renderAlertTypeOptions()}
            </FormControl>
          </div>

          <div className="search-bar">
            <label className="search-label">Status:</label>
            <FormControl
              value={this.state.params.status}
              name="status"
              onChange={this.handleChangeParams}
              className="search-form-control"
              as="select"
            >
              <option value="">Select an option...</option>
              <option value="open">Open</option>
              <option value="investigating">Investigating</option>
              <option value="closed">Closed</option>
              <option value="escalated">Escalated</option>
            </FormControl>
          </div>

          <div className="search-bar">
            <label className="search-label">Severity:</label>
            <FormControl
              value={this.state.params.severity}
              name="severity"
              onChange={this.handleChangeParams}
              className="search-form-control"
              as="select"
            >
              <option value="">Select an option...</option>
              <option value="low">Low</option>
              <option value="medium">Medium</option>
              <option value="high">High</option>
            </FormControl>
          </div>

          <div className="search-bar">
            <label className="search-label">Assigned To:</label>
            <FormControl
              value={this.state.params.assigned_to}
              name="assigned_to"
              onChange={this.handleChangeParams}
              className="search-form-control"
            />
          </div>
        </Row>

        {this.renderUsersForCaseManagement()}

        <Row noGutters className="search-bar-row">
          <div className="search-bar">
            <label className="search-label set-length">Search:</label>
            <FormControl
              value={this.state.params.q}
              name="q"
              placeholder="Search..."
              onChange={this.handleChangeParams}
              className="search-form-control case-search"
            />
          </div>
        </Row>
      </React.Fragment>
    );
  };

  renderCreateCaseButton = () => (
    <Button
      onClick={() => this.props.createCaseRequest()}
      className="teal-button create-a-case"
      size="sm"
    >
      Create a case
    </Button>
  );

  renderTabs = () => {
    return (
      <Row noGutters>
        <Col>
          {this.renderCreateCaseButton()}
          <Tabs
            defaultActiveKey="all-cases"
            className="admin-tabs"
            onSelect={this.handleSelectTab}
          >
            <Tab eventKey="all-cases" title="All Cases" className="admin-tab">
              {this.renderTable()}
            </Tab>

            <Tab
              eventKey="assigned"
              title="Assigned to me"
              className="admin-tab"
            >
              {this.renderTable()}
            </Tab>
          </Tabs>
        </Col>
      </Row>
    );
  };

  renderTable = () => {
    const { data, loading, loaded } = this.state.cases;

    let body;
    if (loaded) {
      if (data.total > 0) {
        body = data.docs.map((record) => this.renderRecord(record));
      } else if (data.total == 0) {
        body = (
          <tr>
            <td colSpan="5" className="centered-td">
              No cases found
            </td>
          </tr>
        );
      }
    } else if (loading) {
      body = (
        <tr>
          <td colSpan="5" className="centered-td">
            {this.renderLoading()}
          </td>
        </tr>
      );
    }

    return (
      <Table responsive bordered className="submissions-table">
        <thead>
          <tr>
            <th className="cm-table-header">Name</th>
            <th className="cm-table-header">Assigned to</th>
            <th className="cm-table-header status-td small-td">Severity</th>
            <th className="cm-table-header status-td small-td">Status</th>
            <th className="cm-table-header">Date created</th>
          </tr>
        </thead>

        <tbody>{body}</tbody>
      </Table>
    );
  };

  renderSeeMoreButton = (id) => {
    const tooltip = <Tooltip id="button-tooltip">See more</Tooltip>;
    return (
      <OverlayTrigger placement="top" delay={{ show: 250 }} overlay={tooltip}>
        <AiOutlineEdit
          onClick={() => this.handleLoadSpecificCase(id)}
          className="individual-form-icon cm-see-more-button"
        />
      </OverlayTrigger>
    );
  };

  renderRecord = (record) => {
    const { date_created, severity, status, _id, assigned_to, name } = record;
    const convertedDate = this.formatDate(date_created);

    return (
      <tr key={_id}>
        <td>{name}</td>
        <td>{assigned_to}</td>
        <td className="severity-td" id={severity}>
          {severity}
        </td>
        <td className="status-td">{status}</td>
        <td className="error-details-td">
          <Row noGutters className="cm-date-created-row">
            {convertedDate}
            {this.renderSeeMoreButton(_id)}
          </Row>
        </td>
      </tr>
    );
  };

  renderResetParamsButton = () => (
    <AiOutlineRedo
      onClick={this.handleResetParams}
      className="individual-form-icon case-reset-params-button"
    />
  );

  renderSpecificCaseContent = () => {
    const { data, loaded, loading } = this.state.specificCase;
    const { editParams } = this.state;
    if (loading) {
      return this.renderLoading();
    } else if (loaded) {
      const {
        status,
        alerts,
        alert_types,
        severity,
        _id,
        name,
        date_created,
        description,
      } = data.docs[0];
      const convertedDate = this.formatDate(date_created);

      let nameContent;
      let descContent;
      if (editParams.name) {
        nameContent = (
          <FormControl
            name="name"
            value={name}
            placeholder="Edit case name..."
            onChange={this.handleEditCase}
            className="sc-edit-formControl normal"
          />
        );
      } else {
        nameContent = name || "No name given";
      }
      if (editParams.desc) {
        descContent = (
          <FormControl
            as="textarea"
            name="description"
            value={description}
            placeholder="Edit case description..."
            onChange={this.handleEditCase}
            className="sc-edit-formControl textarea"
          />
        );
      } else {
        descContent = description || "No description given";
      }

      return (
        <React.Fragment>
          <Row noGutters className="forms-header clickable">
            <h4 onClick={this.handleReturnToCaseList}>
              <BsArrowLeft />
              Return to case list
            </h4>
          </Row>

          <div className="forms-table-box">
            <h5 className="sc-header">Case Data</h5>

            <Row noGutters className="specific-case-form-row">
              <Col sm={3} className="form-data-label">
                Name
              </Col>
              <Col className="form-data-col">
                <Row noGutters className="cm-case-data-row">
                  {nameContent}
                  <AiOutlineEdit
                    onClick={() => this.toggleEditContent("name")}
                    className="individual-form-icon"
                  />
                </Row>
              </Col>
            </Row>

            <Row noGutters className="specific-case-form-row">
              <Col sm={3} className="form-data-label">
                Description
              </Col>
              <Col sm={6} className="form-data-col">
                {descContent}
                <AiOutlineEdit
                  onClick={() => this.toggleEditContent("desc")}
                  className="individual-form-icon"
                />
              </Col>
            </Row>

            <Row noGutters className="specific-case-form-row">
              <Col sm={3} className="form-data-label">
                Date Created
              </Col>
              <Col sm={6} className="form-data-col">
                {convertedDate}
              </Col>
            </Row>

            {this.renderSpecificCaseStatusBar()}
            {this.renderUsers()}
            {this.renderSpecificCaseTabs("activity", alerts)}
          </div>
        </React.Fragment>
      );
    }
  };

  renderSpecificCaseAlerts = (alerts) => {
    const { data, loading, loaded } = this.state.specificCase;

    let body;
    if (loaded && data.docs[0].alerts.length > 0) {
      body = data.docs[0].alerts.map((riskAlert) =>
        this.renderRiskAlert(riskAlert)
      );
    } else if (loaded && data.docs[0].alerts.length == 0) {
      body = (
        <tr>
          <td colSpan="4" className="centered-td">
            No Risk Alerts on record
          </td>
        </tr>
      );
    } else if (loading) {
      body = (
        <tr>
          <td colSpan="4" className="centered-td">
            {this.renderLoading()}
          </td>
        </tr>
      );
    }

    return (
      <Table responsive bordered className="submissions-table">
        <thead>
          <tr>
            <th className="table-header-cell">Time</th>
            <th className="table-header-cell">Account</th>
            <th className="table-header-cell">Refno</th>
            <th className="table-header-cell">Error Details</th>
          </tr>
        </thead>

        <tbody>{body}</tbody>
      </Table>
    );
  };

  renderRiskAlert = (riskAlert) => {
    const {
      account,
      firm,
      rec_no,
      time,
      eventid,
      errordetails,
      related_refs,
      security,
    } = riskAlert;
    const convertedDate = this.handleConvertTimeToDate(time);
    const refno = this.handleGetRefno(errordetails);

    function renderRelatedRefs(
      refs,
      security,
      handleInvestigateOffendingOrder
    ) {
      if (!refs) {
        return;
      }

      const splitRefs = refs.split(",");

      return splitRefs.map((ref) => {
        if (!ref) {
          return;
        }

        const params = {
          refno: ref,
          symbol: security,
        };

        return (
          <a
            className="refno-link"
            onClick={() => handleInvestigateOffendingOrder(params)}
          >
            {ref}
          </a>
        );
      });
    }

    const params = {
      details: errordetails,
    };

    let button;
    if (eventid) {
      const tooltip = <Tooltip id="button-tooltip">Create a case</Tooltip>;
      button = (
        <OverlayTrigger placement="top" delay={{ show: 250 }} overlay={tooltip}>
          <AiOutlineFileSearch className="individual-form-icon" />
        </OverlayTrigger>
      );
    }

    let refnoContent;
    if (related_refs) {
      refnoContent = (
        <div className="refno-links-container">
          {renderRelatedRefs(
            related_refs,
            security,
            this.handleInvestigateOffendingOrder
          )}
        </div>
      );
    } else {
      refnoContent = (
        <a
          className="refno-link"
          onClick={() => this.handleInvestigateOffendingOrder(params)}
        >
          {refno}
        </a>
      );
    }

    return (
      <tr key={rec_no}>
        <td>{convertedDate}</td>
        <td>{account}</td>
        <td>{refnoContent}</td>
        <td className="error-details-td">
          <Row noGutters>
            <Col>{errordetails}</Col>
            <Col sm={1}>{this.renderInvestigateButton(riskAlert)}</Col>
          </Row>
        </td>
      </tr>
    );
  };

  renderInvestigateButton = (riskAlert) => {
    const { security, time } = riskAlert;
    let url = `/admin/market-data-replay?`;
    if (security) {
      url += `symbol=${security}&`;
    } else {
      url += `symbol=No Symbol&`;
    }

    let newStart = this.handleSubtractTime(time);
    if (time) url += `start=${newStart}&`;
    url += `type=Best Bid/Offer`;

    const tooltip = (
      <Tooltip id="button-tooltip">
        Market data history around time of alert
      </Tooltip>
    );

    return (
      <OverlayTrigger placement="left" delay={{ show: 250 }} overlay={tooltip}>
        <a href={url} target="_blank">
          <AiOutlineSearch className="individual-form-icon" />
        </a>
      </OverlayTrigger>
    );
  };

  renderOrderDetailsModal = () => {
    const { data, loaded, loading, error } = this.state.orderDetails;

    let content;
    if (loaded) {
      const arrayOfEntries = Object.entries(data);
      content = arrayOfEntries.map((entry) => this.renderEntry(entry));
    } else if (loading) {
      content = this.renderLoading();
    } else if (error) {
      content = <div className="error-reason">{error.reason}</div>;
    }

    return (
      <Modal
        centered
        className="common-modal sc-modal"
        show={this.state.showOrderDetailsModal}
        onHide={this.handleHideOrderDetailsModal}
      >
        <Modal.Header className="common-modal-header" closeButton>
          Order Details
        </Modal.Header>

        <Modal.Body>{content}</Modal.Body>

        <Modal.Footer>
          <Button
            onClick={this.handleHideOrderDetailsModal}
            className="teal-button"
            size="sm"
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  renderEntry = (entry) => {
    const key = entry[0];
    const value = entry[1];
    const id = key == "Side" ? value : "";
    return (
      <Row key={key} noGutters className="order-details-row">
        <div className="key">{key}</div>
        <div id={id} className="value">
          {value}
        </div>
      </Row>
    );
  };

  renderSpecificCaseStatusBar = () => {
    const { userSearchQuery } = this.state;
    const {
      status,
      severity,
      name,
      assigned_to,
    } = this.state.specificCase.data.docs[0];

    return (
      <Row noGutters className="search-bar-row sc-search-bar">
        <div className="search-bar">
          <label className="search-label">Status:</label>
          <FormControl
            value={status}
            name="status"
            onChange={this.handleChangeSpecificCaseStatus}
            className="search-form-control"
            as="select"
          >
            <option value="">Select an option...</option>
            <option value="open">Open</option>
            <option value="investigating">Investigating</option>
            <option value="closed">Closed</option>
            <option value="escalated">Escalated</option>
          </FormControl>
        </div>

        <div className="search-bar">
          <label className="search-label">Severity:</label>
          <FormControl
            value={severity}
            name="severity"
            onChange={this.handleChangeSpecificCaseSeverity}
            className="search-form-control"
            as="select"
          >
            <option value="">Select an option...</option>
            <option value="low">Low</option>
            <option value="medium">Medium</option>
            <option value="high">High</option>
          </FormControl>
        </div>

        <div className="search-bar">
          <label className="search-label">Assigned to:</label>
          <FormControl
            onChange={this.handleChangeSpecificCaseAssignedTo}
            value={userSearchQuery || assigned_to}
            className="search-form-control"
          />
        </div>
      </Row>
    );
  };

  renderSpecificCaseTabs = (activity, alerts) => {
    return (
      <Row noGutters>
        <Col>
          <Tabs
            defaultActiveKey="alerts"
            className="admin-tabs"
            onSelect={this.handleSelectTab}
          >
            <Tab
              eventKey="alerts"
              title="Alerts in this case"
              className="admin-tab"
            >
              {this.renderSpecificCaseAlerts(alerts)}
              <Button
                onClick={this.handleShowAlertsModal}
                size="sm"
                className="teal-button sc-tab-button"
              >
                <AiOutlinePlus /> Add Alert
              </Button>
            </Tab>

            <Tab eventKey="activity" title="Activity" className="admin-tab">
              {this.renderActivityTab()}
              <AiOutlineRedo
                onClick={this.handleReloadCaseActivity}
                className="individual-form-icon sc-tab-reload"
              />
              <Button
                onClick={this.handleToggleCommentBox}
                size="sm"
                className="teal-button sc-tab-button"
              >
                <AiOutlineComment /> Add Comment
              </Button>
            </Tab>

            <Tab
              eventKey="investigate"
              title="Investigate"
              className="admin-tab"
            >
              {this.renderInvestigateCaseTab()}
            </Tab>

            <Tab
              eventKey="attachments"
              title="Attachments"
              className="admin-tab"
            >
              {this.renderAttachmentsTab()}
            </Tab>
          </Tabs>
        </Col>
      </Row>
    );
  };

  renderAttachmentsTab = () => {
    const { loading, loaded, data } = this.state.specificCase;

    let content;
    if (loading) {
      content = this.renderLoading();
    } else if (loaded) {
      if (data.docs[0].attachments) {
        const { attachments } = data.docs[0];

        let renderAttachments;
        if (attachments.length > 0) {
          content = attachments.map((attachment) =>
            this.renderAttachment(attachment)
          );
        } else {
          content = <div className="cm-no-attachments">No attachments</div>;
        }
      } else {
        content = <div className="cm-no-attachments">No attachments</div>;
      }
    }

    let button;
    if (this.props.addAttachment.loading) {
      button = (
        <Button
          disabled
          className="teal-button cm-add-attachment-button"
          size="sm"
        >
          <Spinner animation="border" className="common-grey-spinner" />
        </Button>
      );
    } else {
      button = (
        <Button
          onClick={this.handleShowAttachmentModal}
          className="teal-button"
          size="sm"
        >
          Add Attachment
        </Button>
      );
    }

    return (
      <div className="cm-investigate-cases-row">
        <Row noGutters className="cm-add-attachment-row">
          {button}
        </Row>
        <Row noGutters className="cm-attachments-row">
          {content}
        </Row>
      </div>
    );
  };

  renderAttachmentModal = () => {
    let file;
    let description;
    function changeFile(e) {
      file = e.target.files[0];
    }

    function changeDescription(e) {
      description = e.target.value;
    }

    return (
      <Modal
        centered
        className="common-modal"
        show={this.state.showAttachmentModal}
        onHide={this.handleHideAttachmentModal}
      >
        <Modal.Header className="common-modal-header" closeButton>
          Add attachment
        </Modal.Header>

        <Modal.Body>
          <Row noGutters className="cm-modal-row">
            <label className="search-label">Choose a file:</label>
            <FormFile
              size="sm"
              className="common-formFile"
              value={file}
              onChange={changeFile}
            />
          </Row>

          <Row noGutters className="cm-modal-row">
            <label className="search-label">Description:</label>
            <FormControl
              as="textarea"
              size="sm"
              value={description}
              onChange={changeDescription}
              className="common-admin-form-control"
            />
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Button
            onClick={() => this.handleAddAttachment(file, description)}
            className="teal-button"
            size="sm"
          >
            Add Attachment
          </Button>
          <Button
            onClick={this.handleHideAttachmentModal}
            className="teal-button"
            size="sm"
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  renderAttachmentDetailsModal = () => {
    const { description, url } = this.state.attachmentDetails;

    let footer;
    if (this.state.pleaseConfirmDeleteAttachment) {
      footer = (
        <Modal.Footer>
          Are you sure?
          <Button
            onClick={() => this.handleRemoveAttachment(url)}
            className="delete-button"
            size="sm"
          >
            <Row noGutters className="button-interior-row">
              <AiOutlineDelete />
              Confirm
            </Row>
          </Button>
          <Button
            onClick={this.handleCancelDeleteAttachment}
            className="teal-button"
            size="sm"
          >
            Cancel
          </Button>
        </Modal.Footer>
      );
    } else {
      footer = (
        <Modal.Footer>
          <Button
            onClick={this.handlePleaseConfirmDeleteAttachment}
            className="delete-button"
            size="sm"
          >
            <Row noGutters className="button-interior-row">
              <AiOutlineDelete />
              Delete Attachment
            </Row>
          </Button>
          <Button
            onClick={this.handleHideAttachmentDetailsModal}
            className="teal-button"
            size="sm"
          >
            Close
          </Button>
        </Modal.Footer>
      );
    }
    return (
      <Modal
        centered
        className="common-modal"
        show={this.state.showAttachmentDetailsModal}
        onHide={this.handleHideAttachmentDetailsModal}
      >
        <Modal.Header className="common-modal-header" closeButton>
          Attachment Details
        </Modal.Header>

        <Modal.Body>
          <Row noGutters className="cm-modal-row">
            <label className="search-label">Url:</label>
            <div className="cm-attachment-details-description">
              <a href={url} target="_blank">
                {url}
              </a>
            </div>
          </Row>
          <Row noGutters className="cm-modal-row">
            <label className="search-label">Description:</label>
            <div className="cm-attachment-details-description">
              {description || "No description given"}
            </div>
          </Row>
        </Modal.Body>

        {footer}
      </Modal>
    );
  };

  handlePleaseConfirmDeleteAttachment = () => {
    this.setState({
      pleaseConfirmDeleteAttachment: true,
    });
  };

  handleCancelDeleteAttachment = () => {
    this.setState({
      pleaseConfirmDeleteAttachment: false,
    });
  };

  renderAttachment = (attachment) => {
    const { description, url } = attachment;
    const splitUrl = url.split(".");
    const fileType = splitUrl[splitUrl.length - 1];
    console.log(splitUrl);
    const fixedDesc = description || "";

    let fileIcon;
    if (fileType == "png" || fileType == "jpg" || fileType == "gif") {
      fileIcon = <Image src={url} className="cm-attachment-image" />;
    } else if (fileType == "pdf") {
      fileIcon = (
        <AiOutlineFilePdf className="individual-file-icon attachment-icon" />
      );
    } else if (fileType == "docx" || fileType == "doc") {
      fileIcon = (
        <AiOutlineFileWord className="individual-file-icon attachment-icon" />
      );
    } else {
      fileIcon = (
        <AiOutlineFile className="individual-file-icon attachment-icon" />
      );
    }

    const shortDesc =
      fixedDesc.length > 50 ? fixedDesc.slice(0, 50) + "..." : description;

    let content;
    if (fileType == "png" || fileType == "jpg" || fileType == "gif") {
      content = fileIcon;
    } else {
      content = (
        <div className="cm-attachment-desc">
          <Row noGutters className="cm-attachment-icon-row">
            {fileIcon}
          </Row>
          <Row noGutters className="cm-attachment-short-desc">
            {shortDesc}
          </Row>
        </div>
      );
    }

    const attachmentInfoIcon = (
      <AiOutlineInfoCircle
        className="attachment-info-icon individual-form-icon"
        onClick={() => this.handleShowAttachmentDetailsModal(attachment)}
      />
    );

    return (
      <div className="cm-attachment" key={url}>
        {attachmentInfoIcon}
        <a href={url} target="_blank">
          {content}
        </a>
      </div>
    );
  };

  renderInvestigateCaseTab = () => {
    const { loading, loaded, data } = this.state.investigateCases;

    let content;
    if (loading) {
      content = this.renderLoading();
    } else if (loaded) {
      const { symbols, users } = data;
      const traderOptions =
        users.length > 0
          ? users.map((option) => this.renderSelectOption(option))
          : "";
      const symbolOptions =
        symbols.length > 0
          ? symbols.map((option) => this.renderSelectOption(option))
          : "";
      const disabled = this.state.investigateParams.trader ? false : true;
      content = (
        <div className="cm-investigate-cases-row">
          <AiOutlineRedo
            onClick={this.handleResetCaseManagementTables}
            className="individual-form-icon cm-reload-button"
          />
          <div className="search-bar cm-investigate-top-row">
            <label className="search-label">
              Select a trader to investigate:
            </label>
            <FormControl
              name="trader"
              as="select"
              value={this.state.investigateParams.trader}
              onChange={this.handleChangeSelectATrader}
              className="search-form-control extra-long-fc"
            >
              <option value="">Traders mentioned in this case...</option>
              {traderOptions}
            </FormControl>
          </div>

          <Row noGutters className="cm-investigate-content-row">
            <div className="cm-investigate-container">
              <div className="search-bar">
                <label className="search-label">Symbol:</label>
                <FormControl
                  name="symbol"
                  as="select"
                  disabled={disabled}
                  value={this.state.investigateParams.symbol}
                  onChange={this.handleChangeSelectASymbol}
                  className="search-form-control"
                >
                  <option value="">Select a symbol...</option>
                  {symbolOptions}
                </FormControl>
              </div>
              <div className="cm-table-title">Open Orders</div>
              {this.renderOrdersTable()}
            </div>

            <div className="cm-investigate-container cm-fills-content">
              <div className="cm-table-title">Executions</div>
              {this.renderExecutionsTable()}
            </div>

            <div className="cm-investigate-container">
              <div className="search-bar">
                <label className="search-label">Alert Type:</label>
                <FormControl
                  name="alertType"
                  as="select"
                  disabled={disabled}
                  value={this.state.investigateParams.alertType}
                  onChange={this.handleChangeAlertType}
                  className="search-form-control"
                >
                  {this.renderAlertTypeOptions()}
                </FormControl>
              </div>
              <div className="cm-table-title">Previous Risk Rejects</div>
              {this.renderAlertsTable()}
            </div>
          </Row>
        </div>
      );
    }

    return content;
  };

  renderOrdersTable = () => {
    const { data, loading, loaded } = this.state.ordersTable;

    let body;
    if (loading) {
      body = (
        <td colSpan="5" className="cm-no-content">
          {this.renderLoading()}
        </td>
      );
    } else if (loaded) {
      if (data.total > 0) {
        body = data.data.map((item) => this.renderOrdersTableItem(item));
      } else {
        body = (
          <td colSpan="5" className="cm-no-content">
            No items found
          </td>
        );
      }
    } else {
      body = (
        <td colSpan="5" className="cm-no-content">
          Please select a trader to investigate
        </td>
      );
    }

    return (
      <div className="cm-investigate-content">
        <Table responsive bordered className="submissions-table cm-table">
          <thead>
            <tr>
              <th className="table-header-cell">Symbol</th>
              <th className="table-header-cell">Side</th>
              <th className="table-header-cell">Price</th>
              <th className="table-header-cell">Order Quantity</th>
              <th className="table-header-cell">Time in force</th>
              <th className="table-header-cell status-td">Ref no</th>
              <th className="table-header-cell status-td">Time</th>
            </tr>
          </thead>

          <tbody>{body}</tbody>
        </Table>
      </div>
    );
  };

  renderOrdersTableItem = (item) => {
    const searchableParams = {
      refno: item["Reference Number"],
      symbol: item.Symbol,
    };
    return (
      <tr>
        <td>{item.Symbol}</td>
        <td>{item.Side}</td>
        <td>{item["Time in Force"]}</td>
        <td
          onClick={() => this.handleInvestigateOffendingOrder(searchableParams)}
          className="status-td searchable-td"
        >
          {item["Reference Number"]}
        </td>
        <td className="status-td">
          {this.handleConvertTimeToDate(item["Time Placed"])}
        </td>
      </tr>
    );
  };

  renderExecutionsTable = () => {
    const { data, loading, loaded } = this.state.executionsTable;

    let body;
    if (loading) {
      body = (
        <td colSpan="7" className="cm-no-content">
          {this.renderLoading()}
        </td>
      );
    } else if (loaded) {
      if (data.total > 0) {
        body = data.data.map((item) => this.renderExecutionsTableItem(item));
      } else {
        body = (
          <td colSpan="7" className="cm-no-content">
            No items found
          </td>
        );
      }
    } else {
      body = (
        <td colSpan="7" className="cm-no-content">
          Please select a trader to investigate
        </td>
      );
    }

    return (
      <div className="cm-investigate-content">
        <Table responsive bordered className="submissions-table cm-table">
          <thead>
            <tr>
              <th className="table-header-cell">Symbol</th>
              <th className="table-header-cell">Side</th>
              <th className="table-header-cell">Price</th>
              <th className="table-header-cell">Quantity executed</th>
              <th className="table-header-cell">Quantity Still Live</th>
              <th className="table-header-cell status-td">Ref no</th>
              <th className="table-header-cell status-td">Execution time</th>
            </tr>
          </thead>

          <tbody>{body}</tbody>
        </Table>
      </div>
    );
  };

  renderExecutionsTableItem = (item) => {
    const symbol = item.Symbol;
    const side = item.Side;
    const quantityExecuted = item["Quantity Executed"];
    const refno = item["Reference Number"];
    const executionTime = this.handleConvertTimeToDate(item["Time Executed"]);

    const searchableParams = {
      refno: item["Reference Number"],
      symbol: symbol,
    };

    return (
      <tr>
        <td>{symbol}</td>
        <td>{side}</td>
        <td>{quantityExecuted}</td>
        <td
          onClick={() => this.handleInvestigateOffendingOrder(searchableParams)}
          className="status-td searchable-td"
        >
          {refno}
        </td>
        <td className="status-td">{executionTime}</td>
      </tr>
    );
  };

  renderAlertsTable = () => {
    const { data, loading, loaded } = this.state.alertsTable;

    let body;
    if (loading) {
      body = (
        <td colSpan="5" className="cm-no-content">
          {this.renderLoading()}
        </td>
      );
    } else if (loaded) {
      if (data.total > 0) {
        body = data.data.map((item) => this.renderAlertsTableItem(item));
      } else {
        body = (
          <td colSpan="4" className="cm-no-content">
            No items found
          </td>
        );
      }
    } else {
      body = (
        <td colSpan="4" className="cm-no-content">
          Please select a trader to investigate
        </td>
      );
    }

    return (
      <div className="cm-investigate-content">
        <Table responsive bordered className="submissions-table cm-table">
          <thead>
            <tr>
              <th className="table-header-cell">Account</th>
              <th className="table-header-cell">Time</th>
              <th className="table-header-cell status-td">Ref no</th>
              <th className="table-header-cell">Error details</th>
            </tr>
          </thead>

          <tbody>{body}</tbody>
        </Table>
      </div>
    );
  };

  renderAlertsTableItem = (item) => {
    const refno = this.handleGetRefno(item.errordetails);
    const searchableParams = {
      details: item.errordetails,
    };

    return (
      <tr>
        <td>{item.account}</td>
        <td>{this.handleConvertTimeToDate(item.time)}</td>
        <td
          onClick={() => this.handleInvestigateOffendingOrder(searchableParams)}
          className="status-td searchable-td"
        >
          {refno}
        </td>
        <td>{item.errordetails}</td>
      </tr>
    );
  };

  renderSelectOption = (option) => (
    <option key={option} value={option}>
      {option}
    </option>
  );

  renderCommentBox = () => {
    return (
      <Row noGutters className="sc-comment-box-row">
        <Col>
          <FormControl
            as="textarea"
            value={this.state.comment}
            placeholder="Leave a comment..."
            onChange={this.handleChangeComment}
            className="sc-edit-formControl sc-comment-box"
          />

          <Row noGutters className="right-justified-row">
            <Button
              onClick={this.handlePostComment}
              className="teal-button"
              size="sm"
            >
              Post
            </Button>
          </Row>
        </Col>
      </Row>
    );
  };

  renderActivityTab = () => {
    const { data, loading, loaded } = this.state.caseActivity;
    let commentBox;
    if (this.state.showCommentBox) {
      commentBox = this.renderCommentBox();
    }

    if (loaded) {
      return (
        <React.Fragment>
          {commentBox}
          {data.data.map((activity) => this.renderActivity(activity))}
        </React.Fragment>
      );
    } else if (loading) {
      return this.renderLoading();
    }
  };

  renderActivity = (activity) => {
    const { date, eventid, message, eventType, data, alert, file } = activity;
    const formattedDate = this.formatDate(date);

    let comment;
    if (eventType == "comment") {
      comment = (
        <Accordion className="identity-mind-tag">
          <Card>
            <Card.Header>
              <Accordion.Toggle
                className="identity-mind-toggle"
                as={Button}
                variant="link"
                eventKey={eventid}
              >
                See Comment
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey={eventid}>
              <Card.Body>
                <Row noGutters className="comment">
                  {data}
                </Row>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      );
    } else if (eventType == "alertAdded") {
      comment = (
        <Accordion className="identity-mind-tag">
          <Card>
            <Card.Header>
              <Accordion.Toggle
                className="identity-mind-toggle"
                as={Button}
                variant="link"
                eventKey={eventid}
              >
                See Alert
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey={eventid}>
              <Card.Body>
                <Row noGutters className="comment">
                  {alert}
                </Row>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      );
    } else if (eventType == "attachment") {
      comment = (
        <Accordion className="identity-mind-tag">
          <Card>
            <Card.Header>
              <Accordion.Toggle
                className="identity-mind-toggle"
                as={Button}
                variant="link"
                eventKey={eventid}
              >
                See Attachment
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey={eventid}>
              <Card.Body>
                <Row noGutters className="comment">
                  <a href={file} target="_blank">
                    {file}
                  </a>
                </Row>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      );
    }

    return (
      <Row noGutters className="activity-individual-submission" key={eventid}>
        <Col>
          <label>{formattedDate}</label>
          <div className="message">{message}</div>
          {comment}
        </Col>
      </Row>
    );
  };

  renderAlertTypeOptions = () => (
    <React.Fragment>
      <option value="">Select an option...</option>
      <option value="general">General</option>
      <option value="washsale">Wash Trade</option>
      <option value="duporder">Duplicate Order</option>
      <option value="speed">Speed Check</option>
      <option value="MaxNotional">Max Order Notional</option>
      <option value="SPOOF">Spoofing</option>
      <option value="LAYER">Layering</option>
    </React.Fragment>
  );

  renderAlertsModal = () => {
    return (
      <Modal
        centered
        id="scroll-here-riskAlerts"
        className="common-modal sc-modal max-width-modal"
        show={this.state.showAlertsModal}
        onHide={this.handleHideAlertsModal}
      >
        <Modal.Header className="common-modal-header" closeButton>
          Search for an alert to add
        </Modal.Header>

        <Modal.Body>
          <Row noGutters className="search-bar-row sc-alerts-search-bar">
            <Col>
              <div className="search-bar">
                <label className="search-label">Alert Type:</label>
                <FormControl
                  name="subtype"
                  value={this.state.riskAlertsParams.subtype}
                  onChange={this.handleSearchAlerts}
                  className="search-form-control"
                  as="select"
                >
                  {this.renderAlertTypeOptions()}
                </FormControl>
              </div>

              <div className="search-bar">
                <label className="search-label">Firm:</label>
                <FormControl
                  name="firm"
                  value={this.state.riskAlertsParams.firm}
                  onChange={this.handleSearchAlerts}
                  className="search-form-control"
                />
              </div>

              <div className="search-bar">
                <label className="search-label">Account:</label>
                <FormControl
                  name="userid"
                  value={this.state.riskAlertsParams.userid}
                  onChange={this.handleSearchAlerts}
                  className="search-form-control"
                />
              </div>
            </Col>
          </Row>

          {this.renderAlertsModalTable()}

          <Pagination
            totalPages={this.state.totalRiskAlertsPages}
            handlePageClick={this.handleClickRiskAlertsPage}
            dataPageNumber={this.state.currentRiskAlertsPage}
          />
        </Modal.Body>

        <Modal.Footer>
          <Button
            onClick={this.handleHideAlertsModal}
            className="teal-button"
            size="sm"
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  renderAlertsModalTable = () => {
    const { data, loading, loaded } = this.state.riskAlerts;

    let body;
    if (loaded && data.total > 0) {
      body = data.data.map((alert) => this.renderModalAlert(alert));
    } else if (loaded && data.total == 0) {
      body = (
        <tr>
          <td colSpan="4" className="centered-td">
            No alerts found
          </td>
        </tr>
      );
    } else if (loading) {
      body = (
        <tr>
          <td colSpan="4" className="centered-td">
            {this.renderLoading()}
          </td>
        </tr>
      );
    }

    return (
      <Table responsive bordered className="submissions-table sc-table">
        <thead>
          <tr>
            <th className="table-header-cell">Account</th>
            <th className="table-header-cell">Date</th>
            <th className="table-header-cell">Subtype</th>
            <th className="table-header-cell">Add</th>
          </tr>
        </thead>

        <tbody>{body}</tbody>
      </Table>
    );
  };

  renderModalAlert = (alert) => {
    const { account, eventid, subtype, time } = alert;
    const convertedTime = this.handleConvertTimeToDate(time);
    const { specificCase } = this.state;
    let checked = false;
    if (specificCase.loaded) {
      for (let i = 0; i < specificCase.data.docs[0].alerts.length; i++) {
        if (specificCase.data.docs[0].alerts[i].eventid == alert.eventid) {
          checked = true;
        }
      }
    }

    return (
      <tr key={eventid}>
        <td>{account}</td>
        <td>{convertedTime}</td>
        <td>{subtype}</td>
        <td className="sc-checkbox-td">
          <FormCheck
            onChange={() => this.handleCheckAlert(alert)}
            checked={checked}
            className="sc-checkbox"
          />
        </td>
      </tr>
    );
  };

  renderCaseManagementContent = () => (
    <React.Fragment>
      <Row noGutters className="forms-header">
        <h4>Case Management</h4>
      </Row>

      <Row noGutters className="cases-totals-row">
        <Col sm={2} className="cases-totals-col">
          {this.renderCasesTotals()}
        </Col>
        <Col className="cases-totals-charts-col">{this.renderBarGraph()}</Col>
      </Row>

      <div className="forms-table-box">
        {this.renderSearchBar()}
        {this.renderTabs()}
        <Pagination
          totalPages={this.state.totalCasesPages}
          handlePageClick={this.handleClickCasesPage}
          dataPageNumber={this.state.currentCasesPage}
        />
      </div>
    </React.Fragment>
  );

  renderCasesTotals = () => {
    const { loading, loaded, data } = this.state.casesTotals;

    let content;
    if (loaded) {
      content = (
        <React.Fragment>
          <div className="forms-table-box">
            <Row noGutters className="value-row">
              <BsBriefcase className="value-icon" />
              Total cases: {data.total || 0}
            </Row>
          </div>
          <div className="forms-table-box">
            <Row noGutters className="value-row">
              <AiOutlineUserAdd className="value-icon" />
              Assigned to me: {data.assignedTo || 0}
            </Row>
          </div>
        </React.Fragment>
      );
    } else if (loading) {
      content = <div className="forms-table-box">{this.renderLoading()}</div>;
    }

    return content;
  };

  renderBarGraph = () => {
    const { data, loading, loaded } = this.state.casesGraphData;

    let content;
    if (loaded) {
      content = (
        <div className="pda-graph-box">
          <ResponsiveBar
            data={data.dataArray}
            indexBy="severity"
            colors={(d) => d.data[`${d.id}Color`]}
            keys={["open", "investigating", "closed", "escalated"]}
            margin={{ top: 0, right: 100, bottom: 45, left: 45 }}
            padding={0.6}
            pointBorderColor={{ from: "serieColor" }}
            animate={true}
            motionStiffness={90}
            motionDamping={15}
            labelSkipHeight={18}
            labelTextColor={{ from: "color", modifiers: [["darker", "3"]] }}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "severity",
              legendPosition: "middle",
              legendOffset: 32,
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "cases",
              legendPosition: "middle",
              legendOffset: -40,
            }}
            legends={[
              {
                dataFrom: "keys",
                anchor: "bottom-right",
                direction: "column",
                justify: false,
                translateX: 95,
                translateY: 0,
                itemsSpacing: 2,
                itemWidth: 87,
                itemHeight: 20,
                itemDirection: "left-to-right",
                itemOpacity: 0.85,
                symbolSize: 20,
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
          />
        </div>
      );
    } else if (loading) {
      content = <div className="forms-table-box">{this.renderLoading()}</div>;
    }

    return content;
  };

  renderLoading = () => (
    <Row noGutters className="app-spinner">
      <Spinner animation="border" className="common-grey-spinner" />
    </Row>
  );

  renderUsers = () => {
    const { data, loaded, loading } = this.state.users;
    let content;
    if (loaded) {
      if (data.total > 0) {
        content = data.docs.map((user) => this.renderUser(user));
      } else {
        content = (
          <Row noGutters className="no-users-found-row">
            No users found
          </Row>
        );
      }
    } else if (loading) {
      content = this.renderUsersLoading();
    }
    return (
      <Row noGutters className="view-reports-users-row">
        {content}
      </Row>
    );
  };

  renderUser = (user) => {
    const { email, _id, role } = user;

    let className;
    if (_id === this.state.userSearchQuery) {
      className = "submission-user-selector selected";
    } else {
      className = "submission-user-selector";
    }

    return (
      <div
        key={_id}
        onClick={() => this.handleAssignCase(_id)}
        className={className}
      >
        {_id}
      </div>
    );
  };

  renderUsersForCaseManagement = () => {
    const { data, loaded, loading } = this.state.users;
    let content;
    if (loaded) {
      if (data.total > 0) {
        content = data.docs.map((user) =>
          this.renderUserForCaseManagement(user)
        );
      } else {
        content = (
          <Row noGutters className="no-users-found-row">
            No users found
          </Row>
        );
      }
    } else if (loading) {
      content = this.renderUsersLoading();
    }
    return (
      <Row noGutters className="view-reports-users-row">
        {content}
      </Row>
    );
  };

  renderUserForCaseManagement = (user) => {
    const { email, _id, role } = user;

    let className;
    if (_id === this.state.params.assigned_to) {
      className = "submission-user-selector selected";
    } else {
      className = "submission-user-selector";
    }

    return (
      <div
        key={_id}
        onClick={() => this.handleSelectUser(_id)}
        className={className}
      >
        {_id}
      </div>
    );
  };

  renderUsersLoading = () => (
    <Row noGutters className="no-users-found-row">
      <Spinner animation="border" />
    </Row>
  );

  render() {
    let content;
    if (this.state.specificCase.loading) {
      content = (
        <div className="interior-loading-div">{this.renderLoading()}</div>
      );
    } else if (this.state.specificCase.loaded) {
      content = this.renderSpecificCaseContent();
    } else {
      content = this.renderCaseManagementContent();
    }

    return (
      <Container fluid className="admin-content-container">
        {this.renderAttachmentModal()}
        {this.renderAttachmentDetailsModal()}
        {this.renderAlertsModal()}
        {this.renderOrderDetailsModal()}
        {content}
      </Container>
    );
  }
}

export default CaseManagement;
