import { history } from '../../configureStore';
import * as CONSTANTS from '../constants/admin';
import update from 'immutability-helper'

const defaultOptions = [
  'trader',
  'totalorders',
  'totalpendingorders',
  'currentamount',
  'maxdailyqty',
  'currentcumopenvalue',
  'maxdailyamount',
  'maxmargin',
  'totaltrades',
  'currentmargin'
]

const defaultRiskSettingsOptions = [
  'firm',
  'trader',
  'maxdollaramount',
  'totalallowamount',
  'totalallowqty',
  'DupOrd',
  'SpeedChk',
  'active_account',
  'percentagelimit'
]

const initialState = {
  risk_setting: {},
  current_risk: {},
  history_query: {
    firm: '',
    trader: '',
    total_rec: '',
    data: [],
  },
  query_alerts: {
    total_rec: '',
    data: [],
    firm: '',
    trader: ''
  },
  specific_query_alert: {
    loading: true,
    data: {},
    comments: [],
  },
  riskAlerts: {
    data: {},
    loading: false,
    loaded: false,
    error: ''
  },
  cases: {
    data: {},
    loading: false,
    loaded: false,
    error: ''
  },
  specificCase: {
    data: {},
    loading: false,
    loaded: false,
    error: ''
  },
  caseActivity: {
    data: {},
    loading: false,
    loaded: false,
    error: ''
  },
  casesTotals: {
    data: {},
    loading: false,
    loaded: false,
    error: ''
  },
  casesGraphData: {
    data: {},
    loading: false,
    loaded: false,
    error: ''
  },
  alertGraphData: {
    data: {},
    loading: false,
    loaded: false,
    error: ''
  },
  orderDetails: {
    data: {},
    loading: false,
    loaded: false,
    error: ''
  },
  investigateCases: {
    data: {},
    loading: false,
    loaded: false,
    error: ''
  },
  ordersTable: {
    data: {},
    loading: false,
    loaded: false,
    error: ''
  },
  executionsTable: {
    data: {},
    loading: false,
    loaded: false,
    error: ''
  },
  alertsTable: {
    data: {},
    loading: false,
    loaded: false,
    error: ''
  },
  queryOrder: {
    data: {},
    loading: false,
    loaded: false,
    error: ''
  },
  displayOptionsCurrentRisk: defaultOptions,
  displayOptionsRiskSettings: defaultRiskSettingsOptions,
  navbarLinks: {
    data: {},
    loading: false,
    loaded: false,
    error: ''
  },
  users: {
    data: {},
    loading: false,
    loaded: false,
    error: ''
  },
  marketSymbols: {
    data: {},
    loading: false,
    loaded: false,
    error: ''
  },
  marketDataReplay: {
    data: {},
    loading: false,
    loaded: false,
    error: ''
  },
  traders: {
    data: {},
    loading: false,
    loaded: false,
    error: ''
  },
  marketQuery: {
    data: {},
    loading: false,
    loaded: false,
    error: ''
  },
  allAlerts: {
    data: {},
    loading: false,
    loaded: false,
    error: ''
  },
  addAttachment: {
    loading: false
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case CONSTANTS.INJECT_TRADER:
      const new_risk_setting = update(state.risk_setting, {
        [action.message.trader]: {$set: action.message}
      })
      return {
        ...state,
        risk_setting: new_risk_setting
      }

    case CONSTANTS.INJECT_CURRENT_RISK:
      const new_current_risk = update(state.current_risk, {
        [action.message.trader]: {$set: action.message}
      })
      return {
        ...state,
        current_risk: new_current_risk
      }

    case CONSTANTS.INJECT_HISTORY_QUERY:
      let data = []
      if (action.message.total_rec == 1) {
        data = action.message.data
      } else if (action.message.total_rec > 1) {
        let tempData
        if (state.history_query.data.length > 0) {
          tempData = state.history_query.data
          tempData = tempData.concat(action.message.data)
        } else {
          tempData = action.message.data
        }
        data = JSON.parse(JSON.stringify(tempData))
      }

      return {
        ...state,
        history_query: {
          ...state.history_query,
          firm: action.message.firm,
          trader: action.message.trader,
          data: data,
          total_rec: action.message.total_rec
        }
      }

    case CONSTANTS.CLEAR_HISTORY_QUERY:
      return {
        ...state,
        history_query: {
          firm: '',
          trader: '',
          total_rec: '',
          data: [],
        },
      }

    case CONSTANTS.SET_DISPLAY_OPTIONS_RISK_SETTINGS:
      return {
        ...state,
        displayOptionsRiskSettings: action.data
      }

    case CONSTANTS.SET_DISPLAY_OPTIONS_CURRENT_RISK:
      return {
        ...state,
        displayOptionsCurrentRisk: action.data
      }

    case CONSTANTS.INJECT_QUERY_ALERTS:
      let queryAlertsData = []
      if (action.message.total_rec == 1) {
        queryAlertsData = action.message.data
      } else if (action.message.total_rec > 1) {
        let tempQueryAlertsData
        tempQueryAlertsData = action.message.data
        queryAlertsData = JSON.parse(JSON.stringify(tempQueryAlertsData))
      }

      return {
        ...state,
        query_alerts: {
          ...state.query_alerts,
          data: queryAlertsData,
          total_rec: action.message.total_rec
        }
      }

    case CONSTANTS.INJECT_SPECIFIC_QUERY_ALERT:
      const allQueryAlerts = state.query_alerts.data
      let specificQueryAlert = {}
      for (let i = 0; i < allQueryAlerts.length; i++) {
        if (allQueryAlerts[i].eventid == action.message.filter.subtype) {
          specificQueryAlert = allQueryAlerts[i]
        }
      }
      const newSpecificQueryAlertsData = JSON.parse(JSON.stringify(specificQueryAlert))
      return {
        ...state,
        specific_query_alert: {
          loading: false,
          data: newSpecificQueryAlertsData,
          comments: action.message.data || [],
        }
      }

    case CONSTANTS.CLEAR_SPECIFIC_QUERY_ALERT:
      return {
        ...state,
        specific_query_alert: {
          loading: false,
          data: {},
          comments: [],
        }
      }

    case CONSTANTS.INJECT_NEW_COMMENT_INTO_SPECIFIC_QUERY_ALERT:
      const oldComments = state.specific_query_alert.comments
      const newCommentsData = oldComments
      newCommentsData.push(action.message)
      return {
        ...state,
        specific_query_alert: {
          ...state.specific_query_alert,
          comments: newCommentsData,
        }
      }

    /************************************************************/

    case CONSTANTS.ADMIN_SEARCH_RISK_ALERTS_REQUEST:
      return {
        ...state,
        riskAlerts: {
          ...state.riskAlerts,
          loading: true,
          loaded: false
        }
      }

    case CONSTANTS.ADMIN_SEARCH_RISK_ALERTS_SUCCESS:
      return {
        ...state,
        riskAlerts: {
          ...state.riskAlerts,
          data: action.data,
          loading: false,
          loaded: true
        }
      }

    case CONSTANTS.ADMIN_SEARCH_RISK_ALERTS_ERROR:
      return {
        ...state,
        riskAlerts: {
          ...state.riskAlerts,
          loading: false,
          loaded: false,
          error: action.error,
        }
      }

    /************************************************************/

    case CONSTANTS.ADMIN_SEARCH_CASES_REQUEST:
      return {
        ...state,
        cases: {
          ...state.cases,
          loading: true,
          loaded: false
        }
      }

    case CONSTANTS.ADMIN_SEARCH_CASES_SUCCESS:
      return {
        ...state,
        cases: {
          ...state.cases,
          data: action.data,
          loading: false,
          loaded: true
        }
      }

    case CONSTANTS.ADMIN_SEARCH_CASES_ERROR:
      return {
        ...state,
        cases: {
          ...state.cases,
          loading: false,
          loaded: false,
          error: action.error,
        }
      }

    case CONSTANTS.ADMIN_SEE_MORE_SPECIFIC_CASE_REQUEST:
      return {
        ...state,
        specificCase: {
          ...state.specificCase,
          loading: true,
          loaded: false
        }
      }

    case CONSTANTS.ADMIN_SEE_MORE_SPECIFIC_CASE_SUCCESS:
      return {
        ...state,
        specificCase: {
          ...state.specificCase,
          data: action.data,
          loading: false,
          loaded: true
        }
      }

    case CONSTANTS.ADMIN_SEE_MORE_SPECIFIC_CASE_ERROR:
      return {
        ...state,
        specificCase: {
          ...state.specificCase,
          loading: false,
          loaded: false,
          error: action.error
        }
      }

    case CONSTANTS.ADMIN_RESET_SPECIFIC_CASE:
      return {
        ...state,
        specificCase: {
          data: {},
          loaded: false,
          loading: false,
          error: ''
        }
      }

    case CONSTANTS.ADMIN_GET_CASE_ACTIVITY_REQUEST:
      return {
        ...state,
        caseActivity: {
          ...state.caseActivity,
          loading: true,
          loaded: false
        }
      }

    case CONSTANTS.ADMIN_GET_CASE_ACTIVITY_SUCCESS:
      return {
        ...state,
        caseActivity: {
          ...state.caseActivity,
          data: action.data,
          loading: false,
          loaded: true
        }
      }

    case CONSTANTS.ADMIN_GET_CASE_ACTIVITY_ERROR:
      return {
        ...state,
        caseActivity: {
          ...state.caseActivity,
          error: action.error,
          loading: false,
          loaded: false
        }
      }

    case CONSTANTS.ADMIN_GET_CASES_TOTALS_REQUEST:
      return {
        ...state,
        casesTotals: {
          ...state.casesTotals,
          loading: true,
          loaded: false
        }
      }

    case CONSTANTS.ADMIN_GET_CASES_TOTALS_SUCCESS:
      return {
        ...state,
        casesTotals: {
          ...state.casesTotals,
          data: action.data,
          loading: false,
          loaded: true
        }
      }

    case CONSTANTS.ADMIN_GET_CASES_TOTALS_ERROR:
      return {
        ...state,
        casesTotals: {
          ...state.casesTotals,
          error: action.error,
          loading: false,
          loaded: false
        }
      }

    case CONSTANTS.ADMIN_GET_CASES_GRAPH_DATA_REQUEST:
      return {
        ...state,
        casesGraphData: {
          ...state.casesGraphData,
          loading: true,
          loaded: false
        }
      }

    case CONSTANTS.ADMIN_GET_CASES_GRAPH_DATA_SUCCESS:
      return {
        ...state,
        casesGraphData: {
          ...state.casesGraphData,
          data: action.data,
          loading: false,
          loaded: true
        }
      }

    case CONSTANTS.ADMIN_GET_CASES_GRAPH_DATA_ERROR:
      return {
        ...state,
        casesGraphData: {
          ...state.casesGraphData,
          error: action.error,
          loading: false,
          loaded: false
        }
      }

    case CONSTANTS.ADMIN_GET_ALERT_GRAPH_DATA_REQUEST:
      return {
        ...state,
        alertGraphData: {
          ...state.alertGraphData,
          loading: true,
          loaded: false
        }
      }

    case CONSTANTS.ADMIN_GET_ALERT_GRAPH_DATA_SUCCESS:
      return {
        ...state,
        alertGraphData: {
          ...state.alertGraphData,
          data: action.data,
          loading: false,
          loaded: true
        }
      }

    case CONSTANTS.ADMIN_GET_ALERT_GRAPH_DATA_ERROR:
      return {
        ...state,
        alertGraphData: {
          ...state.alertGraphData,
          error: action.error,
          loading: false,
          loaded: false
        }
      }

    case CONSTANTS.ADMIN_INVESTIGATE_ORDER_DETAILS_REQUEST:
      return {
        ...state,
        orderDetails: {
          ...state.orderDetails,
          loading: true,
          loaded: false
        }
      }

    case CONSTANTS.ADMIN_INVESTIGATE_ORDER_DETAILS_SUCCESS:
      return {
        ...state,
        orderDetails: {
          ...state.orderDetails,
          data: action.data,
          loading: false,
          loaded: true
        }
      }

    case CONSTANTS.ADMIN_INVESTIGATE_ORDER_DETAILS_ERROR:
      return {
        ...state,
        orderDetails: {
          ...state.orderDetails,
          error: action.error,
          loading: false,
          loaded: false
        }
      }

    case CONSTANTS.ADMIN_CLEAR_ORDER_DETAILS:
      return {
        ...state,
        orderDetails: {
          data: {},
          loading: false,
          loaded: false,
          error: ''
        },
      }

    case CONSTANTS.ADMIN_INVESTIGATE_CASES_REQUEST:
      return {
        ...state,
        investigateCases: {
          ...state.investigateCases,
          loading: true,
          loaded: false
        }
      }

    case CONSTANTS.ADMIN_INVESTIGATE_CASES_SUCCESS:
      return {
        ...state,
        investigateCases: {
          ...state.investigateCases,
          data: action.data,
          loading: false,
          loaded: true
        }
      }

    case CONSTANTS.ADMIN_INVESTIGATE_CASES_ERROR:
      return {
        ...state,
        investigateCases: {
          ...state.investigateCases,
          error: action.error,
          loading: false,
          loaded: false
        }
      }

    case CONSTANTS.ADMIN_GET_ORDERS_TABLE_REQUEST:
      return {
        ...state,
        ordersTable: {
          ...state.ordersTable,
          loading: true,
          loaded: false
        }
      }

    case CONSTANTS.ADMIN_GET_ORDERS_TABLE_SUCCESS:
      return {
        ...state,
        ordersTable: {
          ...state.ordersTable,
          data: action.data,
          loading: false,
          loaded: true
        }
      }

    case CONSTANTS.ADMIN_GET_ORDERS_TABLE_ERROR:
      return {
        ...state,
        ordersTable: {
          ...state.ordersTable,
          error: action.error,
          loading: false,
          loaded: false
        }
      }

    case CONSTANTS.ADMIN_GET_EXECUTIONS_TABLE_REQUEST:
      return {
        ...state,
        executionsTable: {
          ...state.executionsTable,
          loading: true,
          loaded: false
        }
      }

    case CONSTANTS.ADMIN_GET_EXECUTIONS_TABLE_SUCCESS:
      return {
        ...state,
        executionsTable: {
          ...state.executionsTable,
          data: action.data,
          loading: false,
          loaded: true
        }
      }

    case CONSTANTS.ADMIN_GET_EXECUTIONS_TABLE_ERROR:
      return {
        ...state,
        executionsTable: {
          ...state.executionsTable,
          error: action.error,
          loading: false,
          loaded: false
        }
      }

    case CONSTANTS.ADMIN_GET_ALERTS_TABLE_REQUEST:
      return {
        ...state,
        alertsTable: {
          ...state.alertsTable,
          loading: true,
          loaded: false
        }
      }

    case CONSTANTS.ADMIN_GET_ALERTS_TABLE_SUCCESS:
      return {
        ...state,
        alertsTable: {
          ...state.alertsTable,
          data: action.data,
          loading: false,
          loaded: true
        }
      }

    case CONSTANTS.ADMIN_GET_ALERTS_TABLE_ERROR:
      return {
        ...state,
        alertsTable: {
          ...state.alertsTable,
          error: action.error,
          loading: false,
          loaded: false
        }
      }

    case CONSTANTS.ADMIN_RESET_CASE_MANAGEMENT_TABLES:
      return {
        ...state,
        ordersTable: {
          data: {},
          loading: false,
          loaded: false,
          error: ''
        },
        executionsTable: {
          data: {},
          loading: false,
          loaded: false,
          error: ''
        },
        alertsTable: {
          data: {},
          loading: false,
          loaded: false,
          error: ''
        }
      }

      /*****************************************************/

  case CONSTANTS.ADMIN_GET_NAVBAR_LINKS_REQUEST:
    return {
      ...state,
      navbarLinks: {
        ...state.navbarLinks,
        loading: true,
        loaded: false
      }
    }

  case CONSTANTS.ADMIN_GET_NAVBAR_LINKS_SUCCESS:
    return {
      ...state,
      navbarLinks: {
        ...state.navbarLinks,
        loading: false,
        loaded: true,
        data: action.data
      }
    }

  case CONSTANTS.ADMIN_GET_NAVBAR_LINKS_ERROR:
    return {
      ...state,
      navbarLinks: {
        ...state.navbarLinks,
        loading: false,
        loaded: false,
        error: action.error,
      }
    }

    // ***************************************************************************

  case CONSTANTS.ADMIN_USER_SEARCH_REQUEST:
    return {
      ...state,
      users: {
        ...state.users,
        loading: true,
        loaded: false
      }
    }

  case CONSTANTS.ADMIN_USER_SEARCH_SUCCESS:
    return {
      ...state,
      users: {
        ...state.users,
        loading: false,
        loaded: true,
        data: action.data
      }
    }

  case CONSTANTS.ADMIN_USER_SEARCH_ERROR:
    return {
      ...state,
      users: {
        ...state.users,
        loading: false,
        loaded: false,
        error: action.error,
      }
    }

  case CONSTANTS.ADMIN_CLEAR_USERS:
    return {
      ...state,
      users: {
        data: {},
        loading: false,
        loaded: false,
        error: ''
      },
    }

    // ***************************************************************************

  case CONSTANTS.ADMIN_GET_MARKET_SYMBOLS_REQUEST:
    return {
      ...state,
      marketSymbols: {
        ...state.marketSymbols,
        loading: true,
        loaded: false
      }
    }

  case CONSTANTS.ADMIN_GET_MARKET_SYMBOLS_SUCCESS:
    return {
      ...state,
      marketSymbols: {
        ...state.marketSymbols,
        loading: false,
        loaded: true,
        data: action.data
      }
    }

  case CONSTANTS.ADMIN_GET_MARKET_SYMBOLS_ERROR:
    return {
      ...state,
      marketSymbols: {
        ...state.marketSymbols,
        loading: false,
        loaded: false,
        error: action.error,
      }
    }

    case CONSTANTS.ADMIN_CLEAR_MARKET_SYMBOLS:
      return {
        ...state,
        marketSymbols: {
          data: {},
          loading: false,
          loaded: false,
          error: ''
        }
      }

    // ***************************************************************************

  case CONSTANTS.ADMIN_GET_MARKET_DATA_REPLAY_REQUEST:
    return {
      ...state,
      marketDataReplay: {
        ...state.marketDataReplay,
        loading: true,
        loaded: false
      }
    }

  case CONSTANTS.ADMIN_GET_MARKET_DATA_REPLAY_SUCCESS:
    return {
      ...state,
      marketDataReplay: {
        ...state.marketDataReplay,
        loading: false,
        loaded: true,
        data: {
          ...state.marketDataReplay.data,
          [action.dataType]: action.data
        }
      }
    }

  case CONSTANTS.ADMIN_GET_MARKET_DATA_REPLAY_ERROR:
    return {
      ...state,
      marketDataReplay: {
        ...state.marketDataReplay,
        loading: false,
        loaded: false,
        error: action.error,
      }
    }

  case CONSTANTS.ADMIN_UPDATE_CASE_DATA_WITHOUT_RELOAD_REQUEST:
    return {
      ...state,
      specificCase: {
        ...state.specificCase,
      }
    }

  case CONSTANTS.ADMIN_UPDATE_CASE_DATA_WITHOUT_RELOAD_SUCCESS:
    return {
      ...state,
      specificCase: {
        ...state.specificCase,
        data: action.data,
      }
    }

  case CONSTANTS.ADMIN_UPDATE_CASE_DATA_WITHOUT_RELOAD_ERROR:
    return {
      ...state,
      specificCase: {
        ...state.specificCase,
        loading: false,
        loaded: false,
        error: action.error
      }
    }

  case CONSTANTS.ADMIN_SEARCH_TRADERS_REQUEST:
    return {
      ...state,
      traders: {
        ...state.traders,
        loading: true,
        loaded: false
      }
    }

  case CONSTANTS.ADMIN_SEARCH_TRADERS_SUCCESS:
    return {
      ...state,
      traders: {
        ...state.traders,
        data: action.data,
        loaded: true,
        loading: false
      }
    }

  case CONSTANTS.ADMIN_SEARCH_TRADERS_ERROR:
    return {
      ...state,
      traders: {
        ...state.traders,
        loading: false,
        loaded: false,
        error: action.error
      }
    }

  case CONSTANTS.ADMIN_GET_MARKET_QUERY_REQUEST:
    return {
      ...state,
      marketQuery: {
        ...state.marketQuery,
        loading: true,
        loaded: false
      }
    }

  case CONSTANTS.ADMIN_GET_MARKET_QUERY_SUCCESS:
    return {
      ...state,
      marketQuery: {
        ...state.marketQuery,
        data: action.data,
        loaded: true,
        loading: false
      }
    }

  case CONSTANTS.ADMIN_GET_MARKET_QUERY_ERROR:
    return {
      ...state,
      marketQuery: {
        ...state.marketQuery,
        loading: false,
        loaded: false,
        error: action.error
      }
    }

  case CONSTANTS.ADMIN_CLEAR_TRADERS:
    return {
      ...state,
      traders: {
        data: {},
        loading: false,
        loaded: false,
        error: ''
      },
      marketQuery: {
        data: {},
        loading: false,
        loaded: false,
        error: ''
      }
    }

  case CONSTANTS.ADMIN_CLEAR_MARKET_QUERY:
    return {
      ...state,
      marketQuery: {
        data: {},
        loading: false,
        loaded: false,
        error: ''
      }
    }

    case CONSTANTS.ADMIN_GET_ALL_ALERTS_REQUEST:
      return {
        ...state,
        allAlerts: {
          loading: true,
          loaded: false,
          error: ''
        }
      }

    case CONSTANTS.ADMIN_GET_ALL_ALERTS_SUCCESS:
      return {
        ...state,
        allAlerts: {
          data: action.data.data,
          loading: false,
          loaded: true,
          error: ''
        }
      }

    case CONSTANTS.ADMIN_GET_ALL_ALERTS_ERROR:
      return {
        ...state,
        allAlerts: {
          loading: false,
          loaded: false,
          error: action.error
        }
      }

    case CONSTANTS.ADMIN_ADD_ATTACHMENT_REQUEST:
      return {
        ...state,
        addAttachment: {
          loading: true
        }
      }

    case CONSTANTS.ADMIN_ADD_ATTACHMENT_SUCCESS:
      return {
        ...state,
        addAttachment: {
          loading: false
        }
      }

    case CONSTANTS.ADMIN_ADD_ATTACHMENT_ERROR:
      return {
        ...state,
        addAttachment: {
          loading: false
        }
      }

    default:
      return state
  }
}
