import { connect } from "react-redux"
import OrderTradeQuery from './OrderTradeQuery'
import {
  getMarketSymbolsRequest,
  searchTradersRequest,
  getMarketQueryRequest,
  investigateOrderDetailsRequest,
  clearTraders,
  clearMarketQuery,
} from '../../../redux/actions/admin'

const mapStateToProps = state => ({
  symbols: state.admin.marketSymbols,
  users: state.admin.traders,
  marketQuery: state.admin.marketQuery,
  marketQueryMaxPageSize: 10,
  orderDetails: state.admin.orderDetails,
})

const mapDispatchToProps = {
  getMarketSymbolsRequest,
  searchTradersRequest,
  getMarketQueryRequest,
  investigateOrderDetailsRequest,
  clearTraders,
  clearMarketQuery,
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderTradeQuery)
