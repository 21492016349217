import { connect } from "react-redux"
import MarketDataReplay from './MarketDataReplay'
import {
  getMarketSymbolsRequest,
  getMarketDataReplayRequest,
  clearMarketSymbols,
  getAllAlertsRequest,
} from '../../../redux/actions/admin'

const mapStateToProps = state => ({
  marketSymbols: state.admin.marketSymbols,
  marketDataReplay: state.admin.marketDataReplay,
  allAlerts: state.admin.allAlerts,
})

const mapDispatchToProps = {
  getMarketSymbolsRequest,
  getMarketDataReplayRequest,
  clearMarketSymbols,
  getAllAlertsRequest,
}

export default connect(mapStateToProps, mapDispatchToProps)(MarketDataReplay)
