import React, { Fragment, PureComponent } from "react";
import { Switch, Route, withRouter, Router, Redirect } from "react-router-dom";
import { history } from "../configureStore";
import { ToastContainer } from "react-toastify";
import { Container, Row, Spinner } from "react-bootstrap";
import * as COMPONENT from "../containers";

class Routes extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			userData: {
				data: {},
				loading: false,
				loaded: false,
				error: "",
			},
		};
	}

	componentDidMount() {
		this.props.resetAuth();
		this.handleAutoLogin();
	}

	componentWillReceiveProps(nextProps) {
		if (this.props !== nextProps) {
			this.setState({
				userData: nextProps.userData,
			});
		}
	}

	handleAutoLogin = () => {
		this.props.checkLoginStatusRequest();
	};

	handleScrollToTop = () => window.scrollTo(0, 0);

	handleDetermineRoutes = () => {
		const { data, loading, loaded, error } = this.state.userData;
		console.log(data);
		let routes;
		if (loading) {
			routes = this.renderLoading();
		} else if (loaded) {
			if (data.hasAccess) {
				const pathname = history.location.pathname;
				if (!pathname.includes("/admin")) {
					history.push("/admin");
				}
				routes = this.renderAuthenticatedRoutes();
			} else {
				history.push("/");
				routes = this.renderNoAccess();
			}
		} else if (error) {
			// if (error === "User not logged in") history.push("/login");
			routes = this.renderPublicRoutes();
		} else {
			routes = this.renderPublicRoutes();
		}

		return routes;
	};

	renderNoAccess = () => (
		<Switch onUpdate={this.handleScrollToTop}>
			<Route exact path="/login" component={COMPONENT.Login} />
			<Route exact path="/" component={COMPONENT.NoAccess} />
			<Route component={COMPONENT.Login} />
		</Switch>
	);

	renderPublicRoutes = () => {
		return (
			<Switch onUpdate={this.handleScrollToTop}>
				<Route exact path="/login" component={COMPONENT.Login} />
				<Route exact path="/forgot" component={COMPONENT.Forgot} />
				<Route exact path="/reset" component={COMPONENT.Reset} />
				<Route component={COMPONENT.Login} />
			</Switch>
		);
	};

	renderAuthenticatedRoutes = () => {
		return (
			<Switch onUpdate={this.handleScrollToTop}>
				<Route exact path="/admin" component={COMPONENT.Admin} />
				<Route component={COMPONENT.Admin} />
			</Switch>
		);
	};

	renderLoading = () => (
		<Row noGutters className="app-spinner">
			<Spinner animation="border" variant="secondary" />
		</Row>
	);

	render() {
		const { currentRoute } = this.state;
		return (
			<Fragment>
				<div className="app-container">
					<ToastContainer hideProgressBar={true} autoClose={3000} />
					{this.handleDetermineRoutes()}
				</div>
			</Fragment>
		);
	}
}

export default Routes;
