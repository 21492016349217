import * as CONSTANTS from '../constants/admin'

export const injectTrader = data => ({
  type: CONSTANTS.INJECT_TRADER,
  data
})

export const injectCurrentRisk = data => ({
  type: CONSTANTS.INJECT_CURRENT_RISK,
  data
})

export const clearHistoryQuery = () => ({
  type: CONSTANTS.CLEAR_HISTORY_QUERY
})

export const setDisplayOptionsRiskSettings = data => ({
  type: CONSTANTS.SET_DISPLAY_OPTIONS_RISK_SETTINGS,
  data
})

export const setDisplayOptionsCurrentRisk = data => ({
  type: CONSTANTS.SET_DISPLAY_OPTIONS_CURRENT_RISK,
  data
})

export const clearSpecificQueryAlert = () => ({
  type: CONSTANTS.CLEAR_SPECIFIC_QUERY_ALERT
})

/***********************************************************/

export const searchRiskAlertsRequest = params => ({
  type: CONSTANTS.ADMIN_SEARCH_RISK_ALERTS_REQUEST,
  params
})

export const searchRiskAlertsSuccess = data => ({
  type: CONSTANTS.ADMIN_SEARCH_RISK_ALERTS_SUCCESS,
  data
})

export const searchRiskAlertsError = error => ({
  type: CONSTANTS.ADMIN_SEARCH_RISK_ALERTS_ERROR,
  error
})

/***********************************************************/

export const searchCasesRequest = params => ({
  type: CONSTANTS.ADMIN_SEARCH_CASES_REQUEST,
  params
})

export const searchCasesSuccess = data => ({
  type: CONSTANTS.ADMIN_SEARCH_CASES_SUCCESS,
  data
})

export const searchCasesError = error => ({
  type: CONSTANTS.ADMIN_SEARCH_CASES_ERROR,
  error
})

export const createCaseRequest = optionalAlerts => ({
  type: CONSTANTS.ADMIN_CREATE_CASE_REQUEST,
  optionalAlerts
})

export const createCaseSuccess = data => ({
  type: CONSTANTS.ADMIN_CREATE_CASE_SUCCESS,
  data
})

export const createCaseError = error => ({
  type: CONSTANTS.ADMIN_CREATE_CASE_ERROR,
  error
})

export const seeMoreSpecificCaseRequest = id => ({
  type: CONSTANTS.ADMIN_SEE_MORE_SPECIFIC_CASE_REQUEST,
  id
})

export const seeMoreSpecificCaseSuccess = data => ({
  type: CONSTANTS.ADMIN_SEE_MORE_SPECIFIC_CASE_SUCCESS,
  data
})

export const seeMoreSpecificCaseError = error => ({
  type: CONSTANTS.ADMIN_SEE_MORE_SPECIFIC_CASE_ERROR,
  error
})

export const resetSpecificCase = () => ({
  type: CONSTANTS.ADMIN_RESET_SPECIFIC_CASE
})

export const editCaseRequest = data => ({
  type: CONSTANTS.ADMIN_EDIT_CASE_REQUEST,
  data
})

export const editCaseSuccess = data => ({
  type: CONSTANTS.ADMIN_EDIT_CASE_SUCCESS,
  data
})

export const editCaseError = error => ({
  type: CONSTANTS.ADMIN_EDIT_CASE_ERROR,
  error
})

export const changeCaseStatusRequest = data => ({
  type: CONSTANTS.ADMIN_CHANGE_CASE_STATUS_REQUEST,
  data
})

export const changeCaseStatusSuccess = data => ({
  type: CONSTANTS.ADMIN_CHANGE_CASE_STATUS_SUCCESS,
  data
})

export const changeCaseStatusError = error => ({
  type: CONSTANTS.ADMIN_CHANGE_CASE_STATUS_ERROR,
  error
})

export const changeCaseSeverityRequest = data => ({
  type: CONSTANTS.ADMIN_CHANGE_CASE_SEVERITY_REQUEST,
  data
})

export const changeCaseSeveritySuccess = data => ({
  type: CONSTANTS.ADMIN_CHANGE_CASE_SEVERITY_SUCCESS,
  data
})

export const changeCaseSeverityError = error => ({
  type: CONSTANTS.ADMIN_CHANGE_CASE_SEVERITY_ERROR,
  error
})

///////////////////////////////////////////////////////////////////////////////

export const addAlertRequest = data => ({
  type: CONSTANTS.ADMIN_ADD_ALERT_REQUEST,
  data
})

export const addAlertSuccess = data => ({
  type: CONSTANTS.ADMIN_ADD_ALERT_SUCCESS,
  data
})

export const addAlertError = error => ({
  type: CONSTANTS.ADMIN_ADD_ALERT_ERROR,
  error
})

///////////////////////////////////////////////////////////////////////////////

export const removeAlertRequest = data => ({
  type: CONSTANTS.ADMIN_REMOVE_ALERT_REQUEST,
  data
})

export const removeAlertSuccess = data => ({
  type: CONSTANTS.ADMIN_REMOVE_ALERT_SUCCESS,
  data
})

export const removeAlertError = error => ({
  type: CONSTANTS.ADMIN_REMOVE_ALERT_ERROR,
  error
})

///////////////////////////////////////////////////////////////////////////////

export const getCaseActivityRequest = id => ({
  type: CONSTANTS.ADMIN_GET_CASE_ACTIVITY_REQUEST,
  id
})

export const getCaseActivitySuccess = data => ({
  type: CONSTANTS.ADMIN_GET_CASE_ACTIVITY_SUCCESS,
  data
})

export const getCaseActivityError = error => ({
  type: CONSTANTS.ADMIN_GET_CASE_ACTIVITY_ERROR,
  error
})

export const addCommentToCaseRequest = data => ({
  type: CONSTANTS.ADMIN_ADD_COMMENT_TO_CASE_REQUEST,
  data
})

export const addCommentToCaseSuccess = data => ({
  type: CONSTANTS.ADMIN_ADD_COMMENT_TO_CASE_SUCCESS,
  data
})

export const addCommentToCaseError = error => ({
  type: CONSTANTS.ADMIN_ADD_COMMENT_TO_CASE_ERROR,
  error
})

export const getCasesTotalsRequest = userid => ({
  type: CONSTANTS.ADMIN_GET_CASES_TOTALS_REQUEST,
  userid
})

export const getCasesTotalsSuccess = data => ({
  type: CONSTANTS.ADMIN_GET_CASES_TOTALS_SUCCESS,
  data
})

export const getCasesTotalsError = error => ({
  type: CONSTANTS.ADMIN_GET_CASES_TOTALS_ERROR,
  error
})

export const getCasesGraphDataRequest = userid => ({
  type: CONSTANTS.ADMIN_GET_CASES_GRAPH_DATA_REQUEST,
  userid
})

export const getCasesGraphDataSuccess = data => ({
  type: CONSTANTS.ADMIN_GET_CASES_GRAPH_DATA_SUCCESS,
  data
})

export const getCasesGraphDataError = error => ({
  type: CONSTANTS.ADMIN_GET_CASES_GRAPH_DATA_ERROR,
  error
})

export const getAlertGraphDataRequest = subtype => ({
  type: CONSTANTS.ADMIN_GET_ALERT_GRAPH_DATA_REQUEST,
  subtype
})

export const getAlertGraphDataSuccess = data => ({
  type: CONSTANTS.ADMIN_GET_ALERT_GRAPH_DATA_SUCCESS,
  data
})

export const getAlertGraphDataError = error => ({
  type: CONSTANTS.ADMIN_GET_ALERT_GRAPH_DATA_ERROR,
  error
})

export const investigateOrderDetailsRequest = params => ({
  type: CONSTANTS.ADMIN_INVESTIGATE_ORDER_DETAILS_REQUEST,
  params
})

export const investigateOrderDetailsSuccess = data => ({
  type: CONSTANTS.ADMIN_INVESTIGATE_ORDER_DETAILS_SUCCESS,
  data
})

export const investigateOrderDetailsError = error => ({
  type: CONSTANTS.ADMIN_INVESTIGATE_ORDER_DETAILS_ERROR,
  error
})

export const clearOrderDetails = () => ({
  type: CONSTANTS.ADMIN_CLEAR_ORDER_DETAILS
})

export const investigateCasesRequest = id => ({
  type: CONSTANTS.ADMIN_INVESTIGATE_CASES_REQUEST,
  id
})

export const investigateCasesSuccess = data => ({
  type: CONSTANTS.ADMIN_INVESTIGATE_CASES_SUCCESS,
  data
})

export const investigateCasesError = error => ({
  type: CONSTANTS.ADMIN_INVESTIGATE_CASES_ERROR,
  error
})

export const getOrdersTableRequest = params => ({
  type: CONSTANTS.ADMIN_GET_ORDERS_TABLE_REQUEST,
  params
})

export const getOrdersTableSuccess = data => ({
  type: CONSTANTS.ADMIN_GET_ORDERS_TABLE_SUCCESS,
  data
})

export const getOrdersTableError = error => ({
  type: CONSTANTS.ADMIN_GET_ORDERS_TABLE_ERROR,
  error
})

export const getExecutionsTableRequest = params => ({
  type: CONSTANTS.ADMIN_GET_EXECUTIONS_TABLE_REQUEST,
  params
})

export const getExecutionsTableSuccess = data => ({
  type: CONSTANTS.ADMIN_GET_EXECUTIONS_TABLE_SUCCESS,
  data
})

export const getExecutionsTableError = error => ({
  type: CONSTANTS.ADMIN_GET_EXECUTIONS_TABLE_ERROR,
  error
})

export const getAlertsTableRequest = params => ({
  type: CONSTANTS.ADMIN_GET_ALERTS_TABLE_REQUEST,
  params
})

export const getAlertsTableSuccess = data => ({
  type: CONSTANTS.ADMIN_GET_ALERTS_TABLE_SUCCESS,
  data
})

export const getAlertsTableError = error => ({
  type: CONSTANTS.ADMIN_GET_ALERTS_TABLE_ERROR,
  error
})

export const resetCaseManagementTables = () => ({
  type: CONSTANTS.ADMIN_RESET_CASE_MANAGEMENT_TABLES
})

export const getNavbarLinksRequest = () => ({
  type: CONSTANTS.ADMIN_GET_NAVBAR_LINKS_REQUEST
})

export const getNavbarLinksSuccess = data => ({
  type: CONSTANTS.ADMIN_GET_NAVBAR_LINKS_SUCCESS,
  data
})

export const getNavbarLinksError = () => ({
  type: CONSTANTS.ADMIN_GET_NAVBAR_LINKS_ERROR
})

// *******************************************************************

export const userSearchRequest = params => ({
  type: CONSTANTS.ADMIN_USER_SEARCH_REQUEST,
  params
})

export const userSearchSuccess = data => ({
  type: CONSTANTS.ADMIN_USER_SEARCH_SUCCESS,
  data
})

export const userSearchError = error => ({
  type: CONSTANTS.ADMIN_USER_SEARCH_ERROR,
  error
})

export const clearUsers = () => ({
  type: CONSTANTS.ADMIN_CLEAR_USERS
})

// *********************************************************************

export const assignCaseRequest = params => ({
  type: CONSTANTS.ADMIN_ASSIGN_CASE_REQUEST,
  params
})

export const assignCaseSuccess = data => ({
  type: CONSTANTS.ADMIN_ASSIGN_CASE_SUCCESS,
  data
})

export const assignCaseError = error => ({
  type: CONSTANTS.ADMIN_ASSIGN_CASE_ERROR,
  error
})

// *********************************************************************

export const getMarketSymbolsRequest = params => ({
  type: CONSTANTS.ADMIN_GET_MARKET_SYMBOLS_REQUEST,
  params
})

export const getMarketSymbolsSuccess = data => ({
  type: CONSTANTS.ADMIN_GET_MARKET_SYMBOLS_SUCCESS,
  data
})

export const getMarketSymbolsError = error => ({
  type: CONSTANTS.ADMIN_GET_MARKET_SYMBOLS_ERROR,
  error
})

export const clearMarketSymbols = () => ({
  type: CONSTANTS.ADMIN_CLEAR_MARKET_SYMBOLS
})

// *********************************************************************

export const getMarketDataReplayRequest = params => ({
  type: CONSTANTS.ADMIN_GET_MARKET_DATA_REPLAY_REQUEST,
  params
})

export const getMarketDataReplaySuccess = (data, dataType) => ({
  type: CONSTANTS.ADMIN_GET_MARKET_DATA_REPLAY_SUCCESS,
  data,
  dataType
})

export const getMarketDataReplayError = error => ({
  type: CONSTANTS.ADMIN_GET_MARKET_DATA_REPLAY_ERROR,
  error
})

// *********************************************************************

export const addAttachmentRequest = data => ({
  type: CONSTANTS.ADMIN_ADD_ATTACHMENT_REQUEST,
  data
})

export const addAttachmentSuccess = data => ({
  type: CONSTANTS.ADMIN_ADD_ATTACHMENT_SUCCESS,
  data
})

export const addAttachmentError = error => ({
  type: CONSTANTS.ADMIN_ADD_ATTACHMENT_ERROR,
  error
})

export const removeAttachmentRequest = data => ({
  type: CONSTANTS.ADMIN_REMOVE_ATTACHMENT_REQUEST,
  data
})

export const removeAttachmentSuccess = data => ({
  type: CONSTANTS.ADMIN_REMOVE_ATTACHMENT_SUCCESS,
  data
})

export const removeAttachmentError = error => ({
  type: CONSTANTS.ADMIN_REMOVE_ATTACHMENT_ERROR,
  error
})

export const updateCaseDataWithoutReloadRequest = id => ({
  type: CONSTANTS.ADMIN_UPDATE_CASE_DATA_WITHOUT_RELOAD_REQUEST,
  id
})

export const updateCaseDataWithoutReloadSuccess = data => ({
  type: CONSTANTS.ADMIN_UPDATE_CASE_DATA_WITHOUT_RELOAD_SUCCESS,
  data
})

export const updateCaseDataWithoutReloadError = error => ({
  type: CONSTANTS.ADMIN_UPDATE_CASE_DATA_WITHOUT_RELOAD_ERROR,
  error
})

export const searchTradersRequest = query => ({
  type: CONSTANTS.ADMIN_SEARCH_TRADERS_REQUEST,
  query
})

export const searchTradersSuccess = data => ({
  type: CONSTANTS.ADMIN_SEARCH_TRADERS_SUCCESS,
  data
})

export const searchTradersError = error => ({
  type: CONSTANTS.ADMIN_SEARCH_TRADERS_ERROR,
  error
})

export const clearTraders = () => ({
  type: CONSTANTS.ADMIN_CLEAR_TRADERS
})

export const getMarketQueryRequest = params => ({
  type: CONSTANTS.ADMIN_GET_MARKET_QUERY_REQUEST,
  params
})

export const getMarketQuerySuccess = data => ({
  type: CONSTANTS.ADMIN_GET_MARKET_QUERY_SUCCESS,
  data
})

export const getMarketQueryError = error => ({
  type: CONSTANTS.ADMIN_GET_MARKET_QUERY_ERROR,
  error
})

export const clearMarketQuery = () => ({
  type: CONSTANTS.ADMIN_CLEAR_MARKET_QUERY
})

export const getLastSaleRequest = params => ({
  type: CONSTANTS.ADMIN_GET_LAST_SALE_REQUEST,
  params
})

export const getLastSaleSuccess = data => ({
  type: CONSTANTS.ADMIN_GET_LAST_SALE_SUCCESS,
  data
})

export const getLastSaleError = error => ({
  type: CONSTANTS.ADMIN_GET_LAST_SALE_ERROR,
  error
})

export const getOrderBookRequest = params => ({
  type: CONSTANTS.ADMIN_GET_ORDER_BOOK_REQUEST,
  params
})

export const getOrderBookSuccess = data => ({
  type: CONSTANTS.ADMIN_GET_ORDER_BOOK_SUCCESS,
  data
})

export const getOrderBookError = error => ({
  type: CONSTANTS.ADMIN_GET_ORDER_BOOK_ERROR,
  error
})

export const getMarketQueryTradesRequest = params => ({
  type: CONSTANTS.ADMIN_GET_MARKET_QUERY_TRADES_REQUEST,
  params
})

export const getMarketQueryTradesSuccess = data => ({
  type: CONSTANTS.ADMIN_GET_MARKET_QUERY_TRADES_SUCCESS,
  data
})

export const getMarketQueryTradesError = error => ({
  type: CONSTANTS.ADMIN_GET_MARKET_QUERY_TRADES_ERROR,
  error
})

export const getAllAlertsRequest = params => ({
  type: CONSTANTS.ADMIN_GET_ALL_ALERTS_REQUEST,
  params
})

export const getAllAlertsSuccess = data => ({
  type: CONSTANTS.ADMIN_GET_ALL_ALERTS_SUCCESS,
  data
})

export const getAllAlertsError = error => ({
  type: CONSTANTS.ADMIN_GET_ALL_ALERTS_ERROR,
  error
})
