import React, { Component } from 'react'
import {
  Container,
  Row,
  Col,
  Table,
  Spinner,
  Button,
  Modal,
  Form,
  FormControl,
} from 'react-bootstrap'
import { AiOutlineRedo, AiOutlineEdit, AiOutlineSave, AiOutlineClose } from 'react-icons/ai'

class HistoryAudit extends Component {
  constructor(props) {
    super(props)

    this.state = {
      firm: this.props.historyAuditData.firm || '',
      trader: this.props.historyAuditData.trader || '',
      history_query: {
        firm: '',
        trader: '',
        total_rec: '',
        data: [],
      },
    }
  }

  componentDidMount() {
    this.props.clearHistoryQuery()
    this.handleSearch()
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      this.setState({
        history_query: nextProps.history_query
      })
    }
  }

  handleChangeQuery = e => {
    this.setState({ [e.target.name]: e.target.value.trim() })
  }

  handleSearch = () => {
    const message = {
      firm: this.state.firm,
      trader: this.state.trader,
      type: 'history_query'
    }

    this.props.websocket.send(JSON.stringify(message))
  }

  renderSearchBar = () => {
    const { firm, trader } = this.state

    const disabled = (firm && trader) ? false : true
    return (
      <Row noGutters className='history-audit-search-bar-row'>

        <div>
          <div className='history-audit-search-bar'>
            <label className='history-audit-search-label'>Firm:</label>
            <FormControl
              value={firm}
              name='firm'
              placeholder='Search by firm...'
              className='search-form-control'
              onChange={this.handleChangeQuery}
            />
          </div>

          <div className='history-audit-search-bar'>
            <label className='history-audit-search-label'>Trader:</label>
            <FormControl
              value={trader}
              name='trader'
              placeholder='Search by trader...'
              className='search-form-control'
              onChange={this.handleChangeQuery}
            />
          </div>
        </div>

        <Button
          onClick={this.handleSearch}
          disabled={disabled} className='teal-button' size='sm'>
          Search
        </Button>

      </Row>
    )
  }

  renderHistoryQuery = () => {
    const { firm, trader, total_rec, data } = this.state.history_query

    let content
    if (firm) {
      content = (
        <React.Fragment>
          <div className='history-query-header'>
            Viewing Risk Settings Changes for
            <b>{trader}</b>
            from firm <b>{firm}</b>
          </div>

          {this.renderTable()}
        </React.Fragment>
      )
    }

    return content
  }

  renderTable = props => {
    const { firm, trader, total_rec, data } = this.state.history_query

    let body
    if (data.length > 0) {
      body = data.map(record => this.renderRecord(record))
      // const arrayOfValues = Object.values(risk_setting)
      // body = arrayOfValues.map((item, index) => this.renderTableItem(item, index))
    } else {
      body = (
        <tr>
          <td colSpan='2' className='centered-td'>
            No changes to Risk Settings on record for this trader
          </td>
        </tr>
      )
    }

    return (
      <Table responsive bordered className='submissions-table'>
        <thead>
          <tr>
            <th className='table-header-cell'>Time</th>
            <th className='table-header-cell'>Data</th>
          </tr>
        </thead>

        <tbody>
          {body}
        </tbody>
      </Table>
    )
  }

  renderRecord = record => {
    const { data, rec_no, time } = record
    const convertedDate = this.handleConvertTimeToDate(time)
    return (
      <tr key={rec_no}>
        <td>
          {convertedDate}
        </td>
        <td>
          {data}
        </td>
      </tr>
    )
  }

  handleConvertTimeToDate = time => {
    let humanReadableDate = new Date(0)
    humanReadableDate.setUTCMilliseconds(time)
    return humanReadableDate.toUTCString()
  }

  render() {
    return (
      <div className='forms-table-box'>
        {this.renderSearchBar()}
        {this.renderHistoryQuery()}
      </div>
    )
  }
}

export default HistoryAudit
