import { connect } from "react-redux";
import Reset from "./Reset";
import { resetRequest, resetReset } from "../../redux/actions/reset";

const mapStateToProps = (state) => ({
	account: state.login.userData,
	passwordSuccessfullyReset: state.reset.passwordSuccessfullyReset,
});

const mapDispatchToProps = {
	resetRequest,
	resetReset,
};

export default connect(mapStateToProps, mapDispatchToProps)(Reset);
