import { connect } from "react-redux"
import Login from './Login'
import {
  loginRequest,
 } from '../../redux/actions/login'

const mapStateToProps = state => ({
  token: state.login.token,
})

const mapDispatchToProps = {
  loginRequest,
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
