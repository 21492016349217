export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_ERROR = 'LOGIN_ERROR'

export const LOGIN_WITH_TOKEN ='LOGIN_WITH_TOKEN'

export const LOGOUT = 'LOGOUT'

export const RESET_AUTH = 'RESET_AUTH'

export const LOGIN_CREATE_EVENT_CHANNEL = 'LOGIN_CREATE_EVENT_CHANNEL'

export const UPDATE_RISK_SETTINGS_FIELD = 'UPDATE_RISK_SETTINGS_FIELD'

export const SET_WEB_SOCKET = 'SET_WEB_SOCKET'

export const SET_LOGIN_TOKEN = 'SET_LOGIN_TOKEN'
export const CLEAR_LOGIN_TOKEN = 'CLEAR_LOGIN_TOKEN'

export const ESTABLISH_WEBSOCKET_CONNECTION = 'ESTABLISH_WEBSOCKET_CONNECTION'

export const CHECK_LOGIN_STATUS_REQUEST = 'CHECK_LOGIN_STATUS_REQUEST'
export const CHECK_LOGIN_STATUS_SUCCESS = 'CHECK_LOGIN_STATUS_SUCCESS'
export const CHECK_LOGIN_STATUS_ERROR = 'CHECK_LOGIN_STATUS_ERROR'
