import React, { PureComponent } from 'react'
import { Button, FormControl, Modal, Row, Spinner } from 'react-bootstrap'

export default class CreateAccountModal extends PureComponent {
  state = {
  }

  componentDidMount() {
    this.props.investigateOrderDetailsRequest(this.props.refno)
  }

  componentWillUnmount() {
    this.props.clearOrderDetails()
  }

  renderEntry = entry => {
    const key = entry[0]
    const value = entry[1]
    const id = key == 'Side' ? value : ''
    return (
      <Row key={key} noGutters className='order-details-row'>
        <div className='key'>{key}</div>
        <div id={id} className='value'>{value}</div>
      </Row>
    )
  }

  render() {
    let content
    if (this.props.orderDetails?.loaded) {
      const arrayOfEntries = Object.entries(this.props.orderDetails.data)
      content = arrayOfEntries.map(entry => this.renderEntry(entry))
    } else if (this.props.orderDetails?.loading) {
      content = (
        <Row noGutters className='app-spinner'>
          <Spinner animation='border' className='common-grey-spinner' />
        </Row>
      )
    } else if (this.props.orderDetails?.error) {
      content = (
        <div className='error-reason'>
          {this.props.orderDetails.error.reason}
        </div>
      )
    }
    return (
      <Modal
        centered
        className='common-modal order-details-modal'
        show
        onHide={this.props.onClose}>

        <Modal.Header className='common-modal-header' closeButton>
          Order Details
        </Modal.Header>

        <Modal.Body>
          {content}
        </Modal.Body>

        <Modal.Footer>
          <Button
            onClick={this.props.onClose}
            className='teal-button' size='sm'>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}
