import { connect } from "react-redux"
import OrderDetailsModal from './OrderDetailsModal';
import {
  investigateOrderDetailsRequest,
  clearOrderDetails,
} from '../../../redux/actions/admin'

const mapStateToProps = state => ({
  orderDetails: state.admin.orderDetails,
})

const mapDispatchToProps = {
  investigateOrderDetailsRequest,
  clearOrderDetails,
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetailsModal)
