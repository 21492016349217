import { connect } from "react-redux"
import CurrentRisk from './CurrentRisk'
import {
  setDisplayOptionsCurrentRisk
} from '../../../redux/actions/admin'

const mapStateToProps = state => ({
  websocket: state.login.websocket,
  current_risk: state.admin.current_risk,
  displayOptions: state.admin.displayOptionsCurrentRisk
})

const mapDispatchToProps = {
  setDisplayOptionsCurrentRisk
}

export default connect(mapStateToProps, mapDispatchToProps)(CurrentRisk)
