import * as CONSTANTS from '../constants/login'

export const loginRequest = data => ({
  type: CONSTANTS.LOGIN_REQUEST,
  data
})

export const loginSuccess = data => ({
  type: CONSTANTS.LOGIN_SUCCESS,
  data
})

export const loginError = error => ({
  type: CONSTANTS.LOGIN_ERROR,
  error
})

export const loginWithToken = token => ({
  type: CONSTANTS.LOGIN_WITH_TOKEN,
  token
})

export const logout = () => ({
  type: CONSTANTS.LOGOUT
})

export const establishWebsocketConnection = data => ({
  type: CONSTANTS.ESTABLISH_WEBSOCKET_CONNECTION,
  data
})

export const resetAuth = () => ({
  type: CONSTANTS.RESET_AUTH
})

export const setWebsocket = socket => ({
  type: CONSTANTS.SET_WEB_SOCKET,
  socket
})

export const updateRiskSettingsField = data => ({
  type: CONSTANTS.UPDATE_RISK_SETTINGS_FIELD,
  data
})

export const setLoginToken = token => ({
  type: CONSTANTS.SET_LOGIN_TOKEN,
  token
})

export const clearLoginToken = () => ({
  type: CONSTANTS.CLEAR_LOGIN_TOKEN
})

export const checkLoginStatusRequest = () => ({
  type: CONSTANTS.CHECK_LOGIN_STATUS_REQUEST
})

export const checkLoginStatusSuccess = data => ({
  type: CONSTANTS.CHECK_LOGIN_STATUS_SUCCESS,
  data
})

export const checkLoginStatusError = error => ({
  type: CONSTANTS.CHECK_LOGIN_STATUS_ERROR,
  error
})
/**************************************/
