import { connect } from "react-redux"
import PreTradeRisk from './PreTradeRisk'
import RiskSettings from '../RiskSettings'
import CurrentRisk from '../CurrentRisk'
import HistoryAudit from '../HistoryAudit'

const mapStateToProps = state => ({
})

const mapDispatchToProps = {
}

export {
  RiskSettings,
  CurrentRisk,
  HistoryAudit,
}

export default connect(mapStateToProps, mapDispatchToProps)(PreTradeRisk)
