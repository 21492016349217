import React, { PureComponent } from 'react'
import {
  Container,
  Row,
  Col,
  Table,
  FormControl,
  Spinner,
} from 'react-bootstrap'
import { AiOutlineRedo } from 'react-icons/ai'
import Pagination from '../../Pagination/Pagination'
import { history } from '../../../configureStore'
import OrderDetailsModal from '../../../components/modals/OrderDetailsModal'

const typeOptions = [ 'orders', 'trades' ]

const tableHeaders = {
  orders: [ 'Symbol', 'Side', 'Price', 'Order Quantity', 'Time in Force', 'Reference Number', 'Time Placed'],
  trades: [ 'Symbol', 'Side', 'Price', 'Quantity Executed', 'Quantity Still Live', 'Reference Number', 'Time Executed']
}

class OrderTradeQuery extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      params: {
        symbol: '',
        type: 'orders',
        userid: ''
      },
      userQuery: '',
      currentPage: 0,
      totalPages: 0,
      showOrderDetailsModal: false,
      refnoToInvestigate: '',
    }
  }

  componentDidMount() {
    this.handleResetParams()
    this.props.getMarketSymbolsRequest(this.state.params)
    this.props.searchTradersRequest()
    this.props.getMarketQueryRequest({type: 'orders'})
  }

  componentDidUpdate(prevProps) {
    if (prevProps.marketQuery !== this.props.marketQuery) {
      this.setState({
        currentPage: 0,
        totalPages: this.props.marketQuery?.loaded
          ? Math.ceil(this.props.marketQuery.data.total / this.props.marketQueryMaxPageSize)
          : 0
      })
    }
  }

  generateTableHeaders = () => {
    return tableHeaders[this.state.params.type].map(header => {
      let className = 'table-header-cell'
      if (
          header == 'Reference Number'
          || header == 'Time Executed'
          || header == 'Time Placed'
        ) {
        className += ' status-td'
      }

      return <th key={Math.random()} className={className}>{header}</th>
    })
  }

  handleChangeParams = e => {
    this.setState({
      currentPage: 0,
      params: {
        ...this.state.params,
        [e.target.name]: e.target.value
      }
    }, () => {
      if (this.state.params.userid) {
        this.props.getMarketQueryRequest(this.state.params)
      }
    })
  }

  handleSearchUsers = e => {
    this.setState({
      userQuery: e.target.value
    }, () => this.props.searchTradersRequest(this.state.userQuery))
  }

  handleSelectUser = userid => {
    this.setState({
      params: {
        ...this.state.params,
        userid: userid,
      },
      currentPage: 1,
    }, () => this.props.getMarketQueryRequest(this.state.params))
  }

  handlePageClick = data => {
    this.setState({
      currentPage: data.selected
    })
  }

  toggleModal = (fieldName, refno) => {
    this.setState({
      [`show${fieldName}Modal`]: !this.state[`show${fieldName}Modal`],
      refnoToInvestigate: refno
    })
  }

  handleResetParams = () => {
    this.setState({
      params: {
        symbol: '',
        type: 'orders',
        userid: ''
      },
      userQuery: '',
      currentPage: 0,
      totalPages: 0,
      showOrderDetailsModal: false,
      refnoToInvestigate: '',
    }, () => {
      this.props.clearTraders()
      this.props.clearMarketQuery()
    })
  }

  handleConvertTimeToDate = time => {
    let humanReadableDate = new Date(0)
    humanReadableDate.setUTCMilliseconds(time)

    return this.formatDate(humanReadableDate.toUTCString())
  }

  formatDate = dateInput => {
    return dateInput.toLocaleString('en-GB', { timeZone: 'UTC' })
  }

  //////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////

  renderSearchBar = () => {
    return (
      <Row noGutters>
        <AiOutlineRedo
          onClick={this.handleResetParams}
          className='individual-form-icon case-reset-params-button' />
        <div className='search-bar'>
          <label className='search-label'>Symbol:</label>
            <FormControl
              name='symbol'
              value={this.state.params.symbol}
              onChange={this.handleChangeParams}
              className='search-form-control' as='select'>
              <option value=''>
                {
                  this.props.symbols?.loading
                    ? 'Loading...'
                    : 'Select a symbol...'
                }
              </option>
              {
                this.props.symbols?.loaded
                  && this.props.symbols.data.map(symbol => <option key={symbol}>{symbol}</option>)
              }
            </FormControl>
        </div>

        <div className='search-bar'>
          <label className='search-label'>User:</label>
            <FormControl
              value={this.state.userQuery}
              onChange={this.handleSearchUsers}
              className='search-form-control' />
        </div>

        <div className='search-bar'>
          <label className='search-label'>Type:</label>
            <FormControl
              name='type'
              value={this.state.params.type}
              onChange={this.handleChangeParams}
              className='search-form-control' as='select'>
              {typeOptions.map(option => (
                <option key={option} value={option}>
                  {option.charAt(0).toUpperCase() + option.slice(1)}
                </option>
              ))}
            </FormControl>
        </div>
      </Row>
    )
  }

  renderUserSearchBar = () => {
    let content
    if (this.props.users?.loaded) {
      content = this.props.users.data.data.map(user => {
        let className
        if (user.userid === this.state.params.userid) {
          className = 'submission-user-selector selected'
        } else {
          className = 'submission-user-selector'
        }

        return (
          <div
            key={user.userid}
            onClick={() => this.handleSelectUser(user.userid)}
            className={className}>
            {user.userid}
          </div>
        )
      })
    } else if (this.props.users?.loading) {
      content = (
        <Row noGutters className='submission-loading-users order-trade-user-search-bar'>
          <Spinner animation='border' className='common-grey-spinner' />
        </Row>
      )
    }

    return (
      <Row noGutters>
        {content}
      </Row>
    )
  }

  renderTable = () => {
    let content
    if (this.props.marketQuery?.loaded) {
      const startIndex = this.state.currentPage * this.props.marketQueryMaxPageSize
      const pagedData = this.props.marketQuery.data.data.slice(
        startIndex,
        startIndex +  this.props.marketQueryMaxPageSize
      )

      content = (
        pagedData.length > 0
        ? pagedData.map((item, i) => this.renderTableItem(item, i))
        : (
          <tr>
            <td colSpan={tableHeaders[this.state.params.type].length} className='centered-td'>
              No data found for this user with these parameters
            </td>
          </tr>
        )
      )
    } else if (this.props.marketQuery?.loading) {
      content = (
        <tr>
          <td colSpan={tableHeaders[this.state.params.type].length} className='centered-td'>
            <Row noGutters className='app-spinner full-height'>
              <Spinner animation='border' className='common-grey-spinner' />
            </Row>
          </td>
        </tr>
      )
    }

    return (
      <Table responsive bordered className='submissions-table'>
        <thead>
          <tr>
            {this.generateTableHeaders()}
          </tr>
        </thead>

        <tbody>
          {content}
        </tbody>
      </Table>
    )
  }

  renderTableItem = (item, i) => {
    let content
    if (this.state.params.type == 'orders') {
      content = (
        <tr key={`order-refno: ${item.refno}; index: ${i}`}>
          <td>{item.security}</td>
          <td>{item.side}</td>
          <td>{item.price}</td>
          <td>{item.qty}</td>
          <td>{item.tif}</td>
          <td>
            <div
              onClick={() => this.toggleModal('OrderDetails', {refno: item.refno, symbol: item.security})}
              className='clickable-td'>
              {item.refno}
            </div>
          </td>
          <td>{this.handleConvertTimeToDate(item.updtime)}</td>
        </tr>
      )
    } else if (this.state.params.type == 'trades') {
      content = (
        <tr key={`trade-refno: ${item.refno}; index: ${i}`}>
          <td>{item.security}</td>
          <td>{item.side}</td>
          <td>{item.price}</td>
          <td>{item.execqty}</td>
          <td>{item.liveqty}</td>
            <td>
              <div
                onClick={() => this.toggleModal('OrderDetails', {refno: item.refno, symbol: item.security})}
                className='clickable-td'>
                {item.refno}
              </div>
            </td>
          <td>{this.handleConvertTimeToDate(item.trdtime)}</td>
        </tr>
      )
    }

    return content
  }

  renderOrderDetailsModal = () => {
    if (this.state.showOrderDetailsModal) {
      return (
        <OrderDetailsModal
          refno={this.state.refnoToInvestigate}
          onClose={() => this.toggleModal('OrderDetails')}
        />
      )
    }
  }

  render() {
    return (
      <Container fluid className='admin-content-container'>
        {this.renderOrderDetailsModal()}

        <Row noGutters className='forms-header'>
          <h4>Order/Trade Query</h4>
        </Row>

        <div className='forms-table-box'>
          {this.renderSearchBar()}
          {this.renderUserSearchBar()}
          {this.renderTable()}

          <Pagination
            totalPages={this.state.totalPages}
            handlePageClick={this.handlePageClick}
            dataPageNumber={this.state.currentPage}
          />
        </div>

      </Container>
    )
  }
}

export default OrderTradeQuery
