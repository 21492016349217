import { put, takeLatest, call } from "redux-saga/effects";
import { toast } from "react-toastify";
import { history } from "../../../configureStore";
import request from "../../../apiRequest";
import * as ACTIONS from "../../actions/forgot";
import * as CONSTANTS from "../../constants/forgot";

function* forgot(action) {
	try {
		const { email } = action.data;
		const body = { email };

		const responseData = yield call(
			request,
			"/account/forgot",
			"POST",
			body
		);

		yield put(ACTIONS.forgotSuccess(responseData));
	} catch (err) {
		toast.error(err.reason);
	}
}

export default function* forgotSaga() {
	yield takeLatest(CONSTANTS.FORGOT_REQUEST, forgot);
}
